import * as React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {Form, Input, Button, notification, Spin, Col, Row} from 'antd';
import {v4 as uuid} from 'uuid';
import {Store} from 'rc-field-form/es/interface';
import {useRef, useState} from "react";
import {FormInstance} from "antd/lib/form";

/** Presentational */
import FormWrapper from '../../../Components/Styled/FormWrapper';

/** App theme */
import {colors} from '../../../Themes/Colors';
import {UserOutlined, LockOutlined, LoadingOutlined} from '@ant-design/icons';

const passwordValidator = require('password-validator');

// create a password schema
const schema = new passwordValidator();

schema
    .is()
    .min(8);

function SignUpContainer() {

    const navigate = useNavigate()
    const formRef = useRef<FormInstance>()
    const [confirmDirty, setConfirmDirty] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    //const [email, setEmail] = useState<string>('')
    // let handleOpenNotification = (type: string, title: string, message: string): void => {
    //     switch (type) {
    //         case 'success':
    //             notification['success']({
    //                 message: title,
    //                 description: message,
    //                 placement: 'topRight',
    //                 duration: 1.5,
    //                 onClose: () => {
    //                     navigate(`/verify-code?email=${email}`)
    //                 }
    //             });
    //             break;
    //
    //         case 'error':
    //             notification['error']({
    //                 message: title,
    //                 description: message,
    //                 placement: 'topRight',
    //                 duration: 1.5
    //             });
    //             break;
    //     }
    // };

    let handleFinish = (values: Store) => {
        console.log(`registering user`)
        let {fname, lname, password, email, registrationCode} = values;

        // show loader
        setLoading(true)

        let organizationId = null;
        if (registrationCode) {
            organizationId = registrationCode;
        } else {
            organizationId = uuid();
        }

        Auth.signUp({
            username: email,
            password,
            attributes: {
                email,
                name: `${fname} ${lname}`,
                'custom:organizationId': organizationId,
            }
        })
            .then(() => {
                notification.success({
                    message: 'Succesfully registered!',
                    description: 'Account created successfully, Redirecting you in a few!',
                    placement: 'topRight',
                    duration: 1.5,
                    onClose: () => {
                        navigate(`/verify-code?email=${email}`)
                    }
                });

                //setEmail(email)
                console.log('user successfully signed up! email:', email);
            })
            .catch(() => {
                notification.error({
                    message: 'Error',
                    description: 'Error signing up user',
                    placement: 'topRight',
                    duration: 1.5
                });

                setLoading(false)
            });
    };

    let handleConfirmBlur = (event: React.FormEvent<HTMLInputElement>) => {
        const {value} = event.currentTarget;
        setConfirmDirty(confirmDirty || !!value)
    };

    async function compareToFirstPassword(rule: object, value: string): Promise<void> {
        if (value && value !== formRef.current?.getFieldValue('password')) {
            throw new Error('The passwords that you entered do not match!');
        }
    }

    async function validateToNextPassword(rule: object, value: string): Promise<void> {
        const validationRulesErrors = schema.validate(value, {list: true});
        if (validationRulesErrors.length > 0) {
            throw new Error(formatPasswordValidateError(validationRulesErrors));
        }
    }

    let formatPasswordValidateError = (errors: Array<string>) => {
        for (let i = 0; i < errors.length; i++) {
            if (errors[i] === 'min') {
                return 'password length should be a at least 8 characters';
            } else if (errors[i] === 'lowercase') {
                return 'password should contain lowercase letters';
            } else if (errors[i] === 'uppercase') {
                return 'password should contain uppercase letters';
            } else if (errors[i] === 'digits') {
                return 'password should contain digits';
            } else if (errors[i] === 'symbols') {
                return 'password should contain symbols';
            }
        }
    };

    return (
        <React.Fragment>
            <FormWrapper
                onFinish={handleFinish}
                ref={formRef}
                scrollToFirstError
            >
                <Form.Item name="fname" rules={[{required: true, message: 'Please input your first name!'}]}>
                    <Input
                        prefix={<UserOutlined style={{color: colors.transparentBlack}}/>}
                        placeholder="First Name"
                    />
                </Form.Item>
                <Form.Item name="lname" rules={[{required: true, message: 'Please input your last name!'}]}>
                    <Input prefix={<UserOutlined style={{color: colors.transparentBlack}}/>} placeholder="Last Name"/>
                </Form.Item>
                <Form.Item name="email" rules={[{required: true, message: 'Please input your email!'}]}>
                    <Input prefix={<UserOutlined style={{color: colors.transparentBlack}}/>} placeholder="Email"/>
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{
                        required: true,
                        message: 'Please input your Password!'
                    }, {validator: validateToNextPassword}]}
                    hasFeedback
                >
                    <Input.Password
                        prefix={<LockOutlined style={{color: colors.transparentBlack}}/>}
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    rules={[{
                        required: true,
                        message: 'Please confirm your password!'
                    }, {validator: compareToFirstPassword}]}
                    dependencies={['password']}
                    hasFeedback
                >
                    <Input.Password
                        prefix={<LockOutlined style={{color: colors.transparentBlack}}/>}
                        placeholder="Confirm Password"
                        onBlur={handleConfirmBlur}
                    />
                </Form.Item>
                <Form.Item name="registrationCode" rules={[{required: false}]}>
                    <Input
                        placeholder="Registration Code"
                    />
                </Form.Item>

                <Form.Item className="text-center">
                    <Row>
                        <Col lg={24}>
                            <Button style={{width: '100%'}} type="primary" disabled={loading} htmlType="submit">
                                {loading ?
                                    <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/> : 'Register'}
                            </Button>
                        </Col>
                        <Col lg={24}>
                            Or <Link to="/login">login to your account!</Link>
                        </Col>
                    </Row>
                </Form.Item>
            </FormWrapper>
        </React.Fragment>
    );
}

export default SignUpContainer;