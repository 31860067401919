
import * as React from 'react';
import { Select, Tooltip } from 'antd';
  
export interface GradeSelectProps {
    gradesList: any[];
    enrollmentPeriod: string;
    value?: string;
    onChange?: (value: string) => void;
    id: string;
  }
  
export const GradeSelect: React.FC<GradeSelectProps> = ({ gradesList, enrollmentPeriod, value = {}, onChange, id }) => {
    const [grade, setGrade] = React.useState();
  
    const triggerChange = changedValue => {
      if (onChange) {
        onChange(changedValue);
      }
    };
  
    const onGradeChange = newGrade => {
        setGrade(newGrade);
      triggerChange(newGrade);
    };
  
    return (
      <span>
        <Tooltip title={`Grade for ${enrollmentPeriod} School Year`}>
          <Select
            value={value || grade}
            style={{ width: '100%' }}
            onChange={onGradeChange}
            id={id}
          >
            { gradesList }
          </Select>
        </Tooltip>
      </span>
    );
  };