import React from "react";
import WaitlistGuardianComponent from "./WaitlistGuardianComponent";

interface Props {
    formRef: any;
}

function WaitlistEnrollmentGuardianStep(props: Props) {
    console.log("WaitlistEnrollmentGuardianStep - render")

    return (
        <React.Fragment>
            <WaitlistGuardianComponent isPrimary={true} formRef={props.formRef}/>
            <WaitlistGuardianComponent isPrimary={false} formRef={props.formRef}/>
        </React.Fragment>
    )
}

export default WaitlistEnrollmentGuardianStep;