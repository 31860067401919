import dayjs, {Dayjs} from "dayjs";

export function nowTimestamp(): Dayjs {
    return dayjs();
}

export function nowString(): string {
    return formatTimestamp(nowTimestamp());
}

export function parseTimestamp(formattedTimestamp: string): Dayjs {
    try {
        return dayjs(formattedTimestamp);
    } catch (error) {
        return null;
    }
}

export function formatTimestamp(timestamp: Dayjs): string {
    return timestamp.format();
}