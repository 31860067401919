import {DatePicker, Form, Space} from "antd";
import * as React from "react";
import {FormInstance} from "antd/lib/form";

interface Props {
    formRef: React.MutableRefObject<FormInstance<any>>
}

function ClientConfigEnrollmentSettings(props: Props) {

    return (
        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <Form.Item
                name={["enrollmentApplicationConfig","minimumBirthdate"]}
                label="Minimum Birth Date"
                rules={[{required: false}]}>
                <DatePicker
                    format="YYYY-MM-DD"
                />
            </Form.Item>
        </Space>)
}

export default ClientConfigEnrollmentSettings