import {Button, Card} from "antd";
import * as React from "react";

interface Props {
    addEnrollmentPeriod: () => void
}

function EnrollmentCreationReoccuringConfigComponent(props: Props) {

    return (<Card>
        <Button
            onClick={props.addEnrollmentPeriod}
        >Add Enrollment Period</Button>
    </Card>)
}

export default EnrollmentCreationReoccuringConfigComponent;