import * as React from 'react';
import {Layout} from 'antd';
import MainHeader from "../../../Components/MainHeader";
import MainFooter from "../../../Components/MainFooter";
import { Content } from 'antd/lib/layout/layout';


function HomePageContainer() {
  return (
    <React.Fragment>
      <Layout style={{ height: '100vh' }}>
        <MainHeader/>
        <Content>
        </Content>
        <MainFooter/>
      </Layout>
    </React.Fragment>
  );
}

export default HomePageContainer;