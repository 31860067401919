import {Checkbox, Form, Input} from "antd";
import * as React from "react";
import {useUser} from "../../../Store/UserStore";


function ClientConfigGeneralSettings() {
    const user = useUser();

    return (
        <React.Fragment>
            <Form.Item
                name="clientFriendlyId"
                label="Unique School ID"
                rules={[{
                    required: true,
                    message: 'Please enter an unique ID for your school\'s waitlist.'
                }]}>
                <Input disabled={!user.isAdmin}/>
            </Form.Item>
            <Form.Item
                name="schoolName"
                label="School Name"
                rules={[{required: false}]}>
                <Input/>
            </Form.Item>
            <Form.Item
                name="schoolHomePageUrl"
                label="School Home Page URL"
                rules={[{required: false}]}>
                <Input/>
            </Form.Item>
            <Form.Item
                name="grades"
                label="Grades"
                rules={[{required: false}]}>
                <Checkbox.Group style={{width: '100%'}}>
                    <Checkbox value="K">K</Checkbox>
                    <Checkbox value="1">1</Checkbox>
                    <Checkbox value="2">2</Checkbox>
                    <Checkbox value="3">3</Checkbox>
                    <Checkbox value="4">4</Checkbox>
                    <Checkbox value="5">5</Checkbox>
                    <Checkbox value="6">6</Checkbox>
                    <Checkbox value="7">7</Checkbox>
                    <Checkbox value="8">8</Checkbox>
                    <Checkbox value="9">9</Checkbox>
                    <Checkbox value="10">10</Checkbox>
                    <Checkbox value="11">11</Checkbox>
                    <Checkbox value="12">12</Checkbox>
                </Checkbox.Group>
            </Form.Item>
        </React.Fragment>)
}

export default ClientConfigGeneralSettings