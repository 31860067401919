import {AUTH_USER_TOKEN_KEY} from "../Utils/constants";
import authManager from "./authManager";

export class AuthListener {
    loadUser: (authenticated: boolean, eventData: any) => void;

    constructor(loadUser) {
        this.loadUser = loadUser;
    }

    listener = (data) => {
        console.log(`authListener: data:${JSON.stringify(data)}`);
        switch (data.payload.event) {
            case 'signIn':
                localStorage.setItem(AUTH_USER_TOKEN_KEY, data.payload.data.signInUserSession.accessToken.jwtToken);
                this.loadUser(true, data.payload.data);
                break;
            case 'signUp':
                console.log('authEventListener: user signed up');
                break;
            case 'signOut':
                console.log('authEventListener: user signed out');
                this.loadUser(false, data.payload.data);
                break;
            case 'signIn_failure':
                console.log('authEventListener: user sign in failed');
                break;
            case 'tokenRefresh':
                console.log('authEventListener: token refresh succeeded');
                this.loadUser(true, data.payload.data);
                break;
            case 'tokenRefresh_failure':
                console.log('authEventListener: token refresh failed');
                this.loadUser(false, data.payload.data);
                break;
            case 'configured':
                console.log('authEventListener: the Auth module is configured');
                this.loadUser(authManager.isAuthenticated(), data.payload.data);
        }
    }
}