import styled from 'styled-components';
import { Form } from 'antd';

/** App Theme */
import { colors } from '../../Themes/Colors';

const RegistrationFormWrapper = styled(Form)`
  max-width: 700px;
  border: 1px solid ${colors.grey};
  margin: 7% auto !important;
  padding: 42px 24px 50px !important;
  background: ${colors.white};
  h2.text-align: center;
  h3.text-align: center;
`;

export default RegistrationFormWrapper;
