import React from 'react';
import { Drawer, Form, Button, Col, Row, Radio, Divider} from 'antd';
import { Family, ClientConfig, Guardian} from '../../../Model/enrollmentModel';
import { Store } from 'rc-field-form/es/interface';
import { FormInstance } from 'antd/lib/form';
import { getStringValue } from '../../../Utils/helpers';

interface IDistrictLookupDrawerProps {
    family: Family;
    clientConfig: ClientConfig;
    visible: boolean;
    onClose: () => void;
    onSubmit: (districtStatus: string) => void;
}

type State = {
    map: google.maps.Map;
    infowindow: google.maps.InfoWindow;
    service: google.maps.places.PlacesService;
    mapInitialized: boolean;
    curAddressType: string;
    primaryAddress: string;
    secondaryAddress: string;
    districtStatus: string;
}

function getAddressString(guardian: Guardian) {
    if (guardian && guardian.address) {
        let address = guardian.address;
        let addressStr = `${getStringValue(address.addressLine1)} ${getStringValue(address.city)}, ${getStringValue(address.state)} ${getStringValue(address.zipCode)}`;

        if (addressStr.trim().length > 1) {// string always has ','
            return addressStr;
        } else {
            return null;
        }
    } else {
        return null;
    }
}

class DistrictLookupDrawer extends React.Component<IDistrictLookupDrawerProps, State> {
    formRef = React.createRef<FormInstance>();

    constructor(props: IDistrictLookupDrawerProps) {
        super(props);

        //let primaryAddress = getAddressString(props.family.primaryGuardian);
        //let secondaryAddress = getAddressString(props.family.secondaryGuardian);
        //let districtStatus = (this.props.family) ? this.props.family.districtStatus : null;

        this.state = {
            map: null,
            infowindow: null,
            service: null,
            mapInitialized: false,
            curAddressType: "primary",
            primaryAddress: null,
            secondaryAddress: null,
            districtStatus: null,
        }

        this.saveDistrictStatus = this.saveDistrictStatus.bind(this);
        this.initMap = this.initMap.bind(this);
        this.createMarker = this.createMarker.bind(this);
        this.findPlaceCallback = this.findPlaceCallback.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.updateMapAddress = this.updateMapAddress.bind(this);
        this.onDistrictStatusChange = this.onDistrictStatusChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { curAddressType } = this.state;
        console.log("componentDidUpdate");
        this.initMap();
        this.updateMapAddress(curAddressType);
        if (this.propsChanged(prevProps)) {
            console.log("propsChanged");
            let primaryAddress = null;
            let secondaryAddress = null;
            let districtStatus = null;
            if (this.props.family) {
                let family = this.props.family;
                primaryAddress = getAddressString(family.primaryGuardian);
                secondaryAddress = getAddressString(family.secondaryGuardian);
                districtStatus = family ? family.districtStatus : null;

                if (this.formRef && this.formRef.current) {
                    this.formRef.current.setFieldsValue(this.getFormFieldsValues(curAddressType, districtStatus));
                }
            }

            this.setState({
                primaryAddress: primaryAddress,
                secondaryAddress: secondaryAddress,
                districtStatus: districtStatus, 
                mapInitialized: false,
            });
        }
    }

    componentDidMount() {
        console.log("componentDidMount");
    }

    propsChanged(prevProps) {
        if (prevProps.visible !== this.props.visible ||
            prevProps.family !== this.props.family ||
            prevProps.clientConfig !== this.props.clientConfig) {
            return true;
        } else {
            return false;
        }
    }

    createMarker(place: google.maps.places.PlaceResult) {
        const { map, infowindow } = this.state;

        const marker = new google.maps.Marker({
          map,
          position: (place.geometry as google.maps.places.PlaceGeometry).location
        });
      
        google.maps.event.addListener(marker, "click", () => {
          infowindow.setContent(place.name);
          infowindow.open(map);
        });
    }

    initMap() {
        const { mapInitialized, } = this.state;

        if (!mapInitialized && document.getElementById('map')) {
            let infowindow = new google.maps.InfoWindow();
            let map = new google.maps.Map(
                document.getElementById('map'), 
                { zoom: 13 }
            );
        
            var service = new google.maps.places.PlacesService(map);
            this.setState({ map: map, infowindow: infowindow, service: service, mapInitialized: true});
        }
    }

    findPlaceCallback(results, status) {
        const { map } = this.state;

        console.log(`findPlaceCallback results:${JSON.stringify(results)}`)
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            for (var i = 0; i < results.length; i++) {
                this.createMarker(results[i]);
            }
            map.setCenter(results[0].geometry.location);
        }
    }

    saveDistrictStatus(values: Store) {
        let districtStatus = this.state.districtStatus !== 'Unknown' ? this.state.districtStatus : null;
        this.props.onSubmit(districtStatus);
    }

    getFormFieldsValues(addressType, districtStatus) {
        let values: any = {};
        values['addressType'] = addressType;
        values['districtStatus'] = districtStatus ? districtStatus : "Unknown";
        return values;
    }

    updateMapAddress(addressType) {
        console.log("updateMapAddress");
        const { service, primaryAddress, secondaryAddress } = this.state;
        
        let curAddress = addressType === 'primary' ? primaryAddress : secondaryAddress;
        
        if (service && curAddress) {
            console.log(`calling places api with ${curAddress}`);
            var request = {
                query: addressType === 'primary' ? primaryAddress : secondaryAddress,
                fields: ['name', 'geometry'],
            };
            service.findPlaceFromQuery(request, this.findPlaceCallback);
        }
    }

    onAddressChange(e) {
        console.log(`onAddressChange value:${JSON.stringify(e.target.value)}`);
        this.updateMapAddress(e.target.value);
        this.setState({ curAddressType: e.target.value });
    }

    onDistrictStatusChange(e) {
        console.log(`onDistrictStatusChange value:${JSON.stringify(e.target.value)}`);
        this.setState({ districtStatus: e.target.value });
    }

  render() {
    const { curAddressType, primaryAddress, secondaryAddress, districtStatus } = this.state;
    let formInitialValues: any = {
        initialValues: this.getFormFieldsValues(curAddressType, districtStatus),
    };

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    if (this.props.visible && !this.state.mapInitialized) {
        this.initMap();
    }

    var sortedDistrictStatuses = [{code:"Unknown", sortOrder: 0}];
    if (this.props.clientConfig.districtStatuses) {
        sortedDistrictStatuses.push(...this.props.clientConfig.districtStatuses);
    }
    var districtStatusList = sortedDistrictStatuses.map(function(status: any){
        return <Radio.Button value={status.code}>{(status.code) ? status.code : "Unknown"}</Radio.Button>;
    });

    let addressOptions = [];
    addressOptions.push(<Radio style={radioStyle} value="primary" disabled={primaryAddress === null}>{`Primary: ${primaryAddress === null ? "" : primaryAddress}`}</Radio>);
    addressOptions.push(<Radio style={radioStyle} value="secondary" disabled={secondaryAddress === null}>{`Secondary: ${secondaryAddress === null ? "" : secondaryAddress}`}</Radio>);

    return (
        <Drawer
          title={"District Lookup"}
          width={800}
          onClose={this.props.onClose}
          visible={this.props.visible}
          bodyStyle={{ paddingBottom: 80 }}
          destroyOnClose
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button form="districtLookupForm" type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          }
        >
            {this.props.visible && <div id="map" style={{ height: 600}}></div>}
            <Form 
                id="districtLookupForm"
                ref={this.formRef}
                layout="vertical" 
                size="medium"
                requiredMark={false}
                {...formInitialValues}
                onFinish={this.saveDistrictStatus} 
            >
                <Divider/>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="addressType"
                            label="Address"
                        >
                            <Radio.Group
                                onChange={this.onAddressChange}
                                value={curAddressType}
                            >
                                {addressOptions}
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="districtStatus"
                            label="District Status"
                        >
                            <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                                onChange={this.onDistrictStatusChange}
                                value={districtStatus ? districtStatus : "Unknown"}
                            >{districtStatusList}</Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
  }
}

export default DistrictLookupDrawer;