import logoSymbol from "../../assets/images/logo/symbol/1689191778732.png";
import {Button, QRCode} from "antd";
import * as React from "react";
import {useUser} from "../../Store/UserStore";

interface Props {
    downloadable?: boolean;
}

export default function EnrollmentLinkQRCode(props: Props) {
    let user = useUser();
    let enrollmentLink = `https://enrollwithit.com/${user.clientConfig?.clientFriendlyId}/enroll`; //TODO make this datadriven
    let qrcodeElementId = 'enrollment-application-link-qrcode'

    const downloadQRCode = () => {
        const canvas = document.getElementById(qrcodeElementId)?.querySelector<HTMLCanvasElement>('canvas');
        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement('a');
            a.download = `${user.clientConfig?.clientFriendlyId.toUpperCase()} Enrollment App Link QRCode.png`;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    return (<React.Fragment>
            <div id={qrcodeElementId} style={{width: '100%', height: 'auto'}}>
                <QRCode
                    errorLevel="H"
                    value={enrollmentLink}
                    icon={logoSymbol}
                />
            </div>
            {props.downloadable &&
                <Button type="primary" onClick={downloadQRCode}>
                    Download
                </Button>}
        </React.Fragment>
    )
}