import styled from 'styled-components';
import {Card} from 'antd';

const AdminDashboardContentCard = styled(Card)`
  // box-shadow: 4px 6px 18px 4px rgba(210, 210, 210, 0.6) //medium 
  box-shadow: 0px 0px 4px 0px rgba(210, 210, 210, 0.6);
  margin: 10px 10px 10px 10px;
`;

export default AdminDashboardContentCard;
