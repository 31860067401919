import styled from 'styled-components';

const FullWidthWrapper = styled.div<{ align?: string; }>`
  position: relative;
  display: flex;
  width: 100%;
  text-align: ${props => props.align || ''};
`;

export default FullWidthWrapper;
