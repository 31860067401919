import {Button, Checkbox, Col, Form, Input, Row, Select} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import * as React from "react";
import {useUser} from "../../../Store/UserStore";
import {StudentTag} from "../../../Model/enrollmentModel";

const {Option} = Select;

interface Props {
    formRef: any,
    studentTags: StudentTag[]
}

function ClientConfigTagsSettings(props: Props) {
    const user = useUser();

    let onStudentTagPriorityChange = (e: any, index: number) => {
        console.log(`onStudentTagPriorityChange e:${e} index:${index}`)
        const studentTags = props.formRef.current?.getFieldValue('studentTags') as StudentTag[];
        let origPriority = studentTags[index].priority;
        studentTags[index].priority = e;

        //update other element priorities appropriately.
        //if there is another tag with the same priority we don't need to do anything
        let skip = false;
        for (let i1 = 0; i1 < studentTags.length; i1++) {
            if (studentTags[i1].priority === origPriority) {
                skip = true;
                break;
            }
        }
        //If this is the only tag with the same priority then we can reduce all the higher priorities by one.
        if (!skip) {
            for (let i2 = 0; i2 < studentTags.length; i2++) {
                if (studentTags[i2].priority > origPriority) {
                    studentTags[i2].priority = studentTags[i2].priority - 1;
                }
            }
        }


        //sort tags
        studentTags.sort((a, b) => (a.priority > b.priority) ? 1 : (a.priority === b.priority) ? ((a.name > b.name) ? 1 : (a.name === b.name) ? 0 : -1) : -1);

        props.formRef.current?.setFieldsValue({"studentTags": studentTags});
    }

    let addStudentTag = () => {
        let studentTags = props.formRef.current?.getFieldValue("studentTags") as StudentTag[];

        let maxPriority = 1;
        for (var i = 0; i < studentTags.length; i++) {
            if (studentTags[i].priority > maxPriority) {
                maxPriority = studentTags[i].priority;
            }
        }

        let newStudentTag: StudentTag = {
            name: '',
            default: false,
            considerInLottery: false,
            priority: maxPriority + 1,
            userSelectable: true,
        };

        studentTags.push(newStudentTag);

        props.formRef.current?.setFieldsValue({"studentTags": studentTags});
    }

    let removeStudentTag = (index: number) => {
        let studentTags = props.formRef.current?.getFieldValue("studentTags") as StudentTag[];
        let removedTag = studentTags[index];
        studentTags.splice(index, 1);

        //update other element priorities appropriately.
        //if there is another tag with the same priority we don't need to do anything
        let skip = false;
        for (var i3 = 0; i3 < studentTags.length; i3++) {
            if (studentTags[i3].priority === removedTag.priority) {
                skip = true;
                break;
            }
        }
        //If this is the only tag with the same priority then we can reduce all the higher priorities by one.
        if (!skip) {
            for (let i4 = 0; i4 < studentTags.length; i4++) {
                if (studentTags[i4].priority > removedTag.priority) {
                    studentTags[i4].priority = studentTags[i4].priority - 1;
                }
            }
        }

        props.formRef.current?.setFieldsValue({"studentTags": studentTags});
    }

    let maxPriority = 1;
    for (var i5 = 0; i5 < user.clientConfig!.studentTags.length; i5++) {
        if (user.clientConfig!.studentTags[i5].priority > maxPriority) {
            maxPriority = user.clientConfig!.studentTags[i5].priority;
        }
    }
    maxPriority = maxPriority + 1;
    if (maxPriority > user.clientConfig!.studentTags.length) {
        maxPriority = user.clientConfig!.studentTags.length;
    }

    let studentTagPriorityOptions: any[] = [];
    if (user.clientConfig) {
        for (let i6 = 1; i6 <= maxPriority; i6++) {
            studentTagPriorityOptions.push(<Option key={i6} value={i6}>{i6}</Option>);
        }
    }

    return (<React.Fragment>
        <Form.List name="studentTags">
            {(fields, {add, remove}) => {
                return (
                    <div>
                        {fields.map((field, index) => {
                            return (
                                <Row gutter={8}>
                                    <Col span={8}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'name']}
                                            label={index === 0 ? 'Tag' : ''}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please input a tag name",
                                                },
                                            ]}
                                        >
                                            <Input
                                                disabled={(props.studentTags && props.studentTags.length > index) ? props.studentTags[index]['default'] : false}
                                                style={{width: '100%'}}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'priority']}
                                            label={index === 0 ? 'Priority' : ''}
                                            validateTrigger={['onChange', 'onBlur']}
                                        >
                                            <Select
                                                onChange={(e) => onStudentTagPriorityChange(e, index)}
                                                style={{width: '100%'}}
                                            >{studentTagPriorityOptions}</Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'considerInLottery']}
                                            label={index === 0 ? 'Applies to Lottery' : ''}
                                            valuePropName="checked"
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <Checkbox
                                                style={{width: '100%'}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        {!user.clientConfig?.studentTags[index]['default'] ? (
                                            <Form.Item
                                                {...field}
                                                label={index === 0 ? ' ' : ''}
                                                style={{width: '100%'}}
                                                required={false}
                                            >
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    style={{margin: '0 8px'}}
                                                    onClick={() => {
                                                        removeStudentTag(index);
                                                    }}
                                                />
                                            </Form.Item>
                                        ) : null}
                                    </Col>
                                    <Col span={8}>
                                    </Col>
                                </Row>
                            )
                        })}
                        <Row>
                            <Col span={8}>
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            addStudentTag();
                                            //add();
                                        }}
                                        style={{width: '100%'}}
                                    >
                                        <PlusOutlined/> Add Tag
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={8}/>
                        </Row>
                    </div>
                );
            }}
        </Form.List>
    </React.Fragment>)
}

export default ClientConfigTagsSettings