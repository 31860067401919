/*********************************************************************
 * Application Entities
 */
export interface AdminDashboardContext {
    schoolYearId: string;
}

export interface User {
    authorizedOrganizations: Organization[];
    organization: Organization;
    clientConfig: ClientConfig;
    isAdmin: boolean;
    authenticated: boolean;
    cognitoUser: any;
}

/*********************************************************************
 * Configuration Entities
 */
export interface Organization {
    organizationId: string
    name: string
    clients: ClientDesc[]

}

export interface ClientDesc {
    clientId: string;
    schoolName: string;
}

export interface ClientConfig {
    clientId: string;
    organizationId: string;
    clientFriendlyId: string;
    schoolName: string;
    schoolHomePageUrl: string;
    grades: string[];
    studentTags: StudentTag[];
    studentStatuses: StudentStatus[];
    districtStatuses: DistrictStatus[];
    contactInformation: ContactInfo;
    enrollmentPeriodConfig: EnrollmentPeriodConfig;
    lotteryConfig: LotteryConfig;
    waitlistConfig: WaitlistConfig;
    enrollmentApplicationConfig: EnrollmentApplicationConfig;
    districtId: string;

    logStudentIds: string[];
}

export interface EnrollmentPeriodConfig {
    enrollmentPeriodCreationConfig: EnrollmentPeriodCreationConfig
    enrollmentPeriods: EnrollmentPeriod[]
}

export interface EnrollmentPeriodCreationConfig {
    mode: EnrollmentPeriodCreationMode,
    frequency: EnrollmentPeriodCreationFrequency,
    startDate: string
    endDate: string
    lotteryDate: string
    rolloverWaitlist: boolean
}

export enum EnrollmentPeriodCreationMode {
    MANUAL = "MANUAL",
    REOCCURRING = "REOCCURRING",
}


export enum EnrollmentPeriodCreationFrequency {
    YEARLY = "YEARLY",
}

export interface EnrollmentPeriod {
    id: string
    sequenceNumber: number
    name: string
    createDate: string
    startDate: string
    endDate: string
    lotteryDate: string
    events: Event<RolloverEventDetail | LotteryEventDetail>[],
    rolloverWaitlist: boolean
    status: EnrollmentPeriodStatus
    isShadowNextPeriod: boolean
    isCurrentEnrollmentPeriod: boolean
}
export interface RolloverEventDetail {
    clientId: string,
    sourceEnrollmentPeriodId: string,
    destinationEnrollmentPeriodId: string,
}

export interface LotteryEventDetail {
    clientId: string,
    enrollmentPeriodId: string,
}

export enum EnrollmentPeriodStatus {
    CLOSED_ENROLLMENT_PRE = "CLOSED_ENROLLMENT_PRE",
    OPEN_ENROLLMENT_PRE_LOTTERY = "OPEN_ENROLLMENT_PRE_LOTTERY",
    OPEN_ENROLLMENT_POST_LOTTERY = "OPEN_ENROLLMENT_POST_LOTTERY",
    CLOSED_ENROLLMENT_POST = "CLOSED_ENROLLMENT_POST"
}

export interface StudentTag {
    name: string;
    default: boolean;
    priority: number;
    considerInLottery: boolean;
    userSelectable: boolean;
}

export interface StudentStatus {
    code: string;
    sortOrder: number;
    showOnWaitlist: boolean;
}

export interface DistrictStatus {
    code: string;
    sortOrder: number;
}

export interface LotteryConfig {
    defaultLotteryStatusCodes: string[];
}

export interface LotteryRunEvent {
    lotteryTimestamp: string;
}

export interface WaitlistConfig {
    postLotteryAppWaitlistPriority: string;//possible values [BOTTOM, STANDARD]
}

export interface EnrollmentApplicationConfig {
    enabled: boolean;
    enrollmentEnabledOverride: boolean;
    schoolName: string;
    enrollmentPeriod: string;
    enrollmentPeriodStatus: string;
    header: string;
    subHeader: string;
    agreements: Agreement[];
    grades: string[];
    confirmationEmailConfig: ConfirmationEmailConfig;
    minimumBirthdate: string;
}

export interface ConfirmationEmailConfig {
    templateName: string;
    senderEmail: string;
}

export interface Agreement {
    id: string;
    agreementText: string;
}

export interface EnrollmentApplicationStatus {
    schoolDisplayName: string;
    schoolYear: string;
    enrollmentPeriodStatus: string;
    lotteryDate: string;
    students: StudentWaitListStatus[];
}

export interface StudentWaitListStatus {
    firstName: string;
    lastName: string;
    grade: string;
    status: string;
    waitlistPosition: string;
}

/**************************************************************************
 * Student
 */
export interface Student {
    id: string;
    clientId: string;
    birthDate: any;
    familyId: string;
    firstName: string;
    grade: string;
    lastName: string;

    gender: string;
    lotteryRank: number;
    waitlistPosition: number;
    status: string;
    tags: string[];
    notes: string;
    districtStatus: string;
    createdTimestamp: string;
    acceptedDate: string;
    siblingDate: string;
    deletedInd: boolean;
    schoolYearId: string;
    contactInfo: ContactInfo;
}

export interface ContactInfo {
    contactEvents: ContactEvent[];
}

export interface ContactEvent {
    timestamp: string;
}


/*****************************************************************************
 * Family
 */
export interface Family {
    id: string;
    clientId: string;
    clientFriendlyId: string;
    primaryGuardian: Guardian;
    secondaryGuardian: Guardian;
    students: Student[];
    notes: string;
    referenceSource: string;
    referenceSourceComments: string;
    districtStatus: string;
    deletedInd: boolean;
    schoolYearId: string;
    hasEnrolledStudents: boolean;
    enrolledStudentsIds: string;
    confirmationNumber: number;

    //UI only
    guardianSummary: string;
    studentSummary: string;
    tagsSummary: string[];
    statusSummary: string[];
}

export interface Guardian {
    firstName: string;
    lastName: string;
    phoneNumbers: PhoneNumber[];
    address: Address;
    email: string;
}

export interface PhoneNumber {
    number: string;
    type: PhoneNumberType;
    primary: boolean;
}

export interface ContactInfo {
    address: Address;
    phoneNumber: string;
}

export interface Address {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
}

export enum PhoneNumberType {
    Home,
    Mobile,
    Work
}

/*****************************************************************************
 * Waitlist
 */
export interface WaitListApplication {
    familyId;
    clientId;
    clientFriendlyId: string;
    primaryGuardian: Guardian;
    secondaryGuardian: Guardian;
    students: Student[];
    referenceSource: string;
    referenceSourceComments: string;
    hasEnrolledStudents: boolean;
    isStaff: boolean;
    isFounder: boolean;
    staffOrFounderName: string;
    enrolledStudentsIds: string;
    confirmationNumber: number;
}

export interface WaitlistStatistics {

}

export interface Waitlist {
    waitlist: Student[];
}

export interface Waitlists {
    clientId: string;
    schoolYearId: string;
    waitlistStatistics: WaitlistStatistics;
}

export interface Event<T> {
    detail: T;
    type: string;
    timestamp: string;
}

export interface EnrollmentServicesMetaData {
    version: string;
}