import authManager from "../Auth/authManager";
import {Student} from "../Model/enrollmentModel";
import {decorateStudents} from "../Utils/helpers";

export async function getStudents(clientId, schoolYearId): Promise<Student[]> {
    console.log(`getFamilies`);
    const apiName = "enrollmentApi";
    const path = `/students`;
    const myInit = {
        queryStringParameters: {
            clientId: clientId,
            schoolYearId: schoolYearId
        },
    }

    let students = await authManager.apiGet<Student[]>(apiName, path, myInit);

    decorateStudents(students);
    return students;
}