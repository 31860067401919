import { Hub } from '@aws-amplify/core';
const busListeners = {};

export function registerListener(channel, name, callback) {
    console.log(`registerListener: ${channel}:${name}`);
    const previousListener = busListeners[name];
    if (!!previousListener) {
        Hub.remove(channel, previousListener);
    }
    busListeners[name] = callback;
    Hub.listen(channel, busListeners[name]);
}

export function removeListener(channel, name) {
    const listener = busListeners[name];
    if (!!listener) {
        Hub.remove(channel, listener);
    }
}

export function pushMessage(channel, message) {
    Hub.dispatch(channel, message);
}