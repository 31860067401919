import {
    getFamilies,
    recalculateFamilyDistrictStatus
} from "../../../Services/familiesService";
import {Button, notification} from "antd";
import React, {useState} from "react";
import {useSchoolYearId, useUser} from "../../../Store/UserStore";


function DistrictStatusComponent() {
    let [loading, setLoading] = useState<boolean>(false);
    const user = useUser();
    const schoolYearId = useSchoolYearId()

    let handleClick = async () => {
        setLoading(true);
        let families = await getFamilies(user.clientConfig.clientId, schoolYearId)

        for (const family of families) {
            await recalculateFamilyDistrictStatus(user.clientConfig.clientId, schoolYearId, family.id)
        }

        notification.success({
            message: 'Success',
            description: `Recalculated families district status.`,
            placement: 'topRight',
            duration: 1.5
        });
        setLoading(false);
    }

    return (
        <React.Fragment>
            <Button onClick={handleClick} type="primary" disabled={loading}>Recalculate All Families District Status</Button>
        </React.Fragment>
    )
}

export default DistrictStatusComponent;