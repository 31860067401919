import * as React from 'react';
import {Tabs} from "antd";
import {useUser} from "../../../Store/UserStore";
import EnrollmentCreationConfigComponent from "./EnrollmentCreationConfigComponent";
import AdminDashboardContentCard from "../../../Components/Styled/AdminDashboardContentCard";
import EnrollmentPeriodSettingsComponent from "./EnrollmentPeriodSettingsComponent";

function EnrollmentPeriodSettingsContainer() {

    const user = useUser();
    const enrollmentPeriods = user.clientConfig.enrollmentPeriodConfig?.enrollmentPeriods.filter((enrollmentPeriod) => {
        return !enrollmentPeriod.isShadowNextPeriod
    }) ?? [];

    return (<React.Fragment>
            <AdminDashboardContentCard>
                <EnrollmentCreationConfigComponent/>
            </AdminDashboardContentCard>
            <AdminDashboardContentCard title={"Enrollment Periods"}>
                <Tabs
                    tabPosition="left"
                    items={enrollmentPeriods.map((enrollmentPeriod, i) => {
                        let name = enrollmentPeriod.isCurrentEnrollmentPeriod ? `${enrollmentPeriod.name} (Current)` : enrollmentPeriod.name;
                        return {
                            label: name,
                            key: enrollmentPeriod.id,
                            children: <EnrollmentPeriodSettingsComponent enrollmentPeriod={enrollmentPeriod}/>,
                        };
                    })}
                />
            </AdminDashboardContentCard>
        </React.Fragment>
    )
        ;
}

export default EnrollmentPeriodSettingsContainer;