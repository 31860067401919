import React from "react"
import {Card, Col, Form, Input, Row, Select} from "antd";
import {AgreementsConfirm} from "../../../Components/AgreementsConfirm";
import {EnrollmentApplicationConfig} from "../../../Model/enrollmentModel";

const { Option } = Select;

interface Props {
    enrollmentApplicationConfig: EnrollmentApplicationConfig;
}

const checkAgreements = (rule, value) => {
    if (value && value.allAccepted) {
        return Promise.resolve();
    }
    return Promise.reject('Must accept terms and conditions');
    //TODO handle scenario when there are no agreements to accept
}
function WaitlistEnrollmentSubmitStep(props: Props) {

    return (
        <React.Fragment>
            <Card style={{ width: '100%' }}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="referenceSource"
                            label="How did you hear about us?"
                            rules={[{ required: true, message: 'Please select how you heard about us',  }]}>
                            <Select style={{ width: '50%' }}>
                                <Option key="Open House" value="Open House">Open House</Option>
                                <Option key="Field Trip" value="Field Trip">Field Trip</Option>
                                <Option key="Friend" value="Friend">Friend</Option>
                                <Option key="Local Public School" value="Local Public School">Local Public School</Option>
                                <Option key="Facebook" value="Facebook">Facebook</Option>
                                <Option key="Sibling Attending" value="Sibling Attending">Sibling Attending</Option>
                                <Option key="Sign on School Property" value="Sign on School Property">Sign on School Property</Option>
                                <Option key="Website" value="Website">Website</Option>
                                <Option key="Community Event" value="Community Event">Community Event</Option>
                                <Option key="Radio" value="Radio">Radio</Option>
                                <Option key="Print Media (Newspaper, mailer, etc...)" value="Print Media (Newspaper, mailer, etc...)">Print Media (Newspaper, mailer, etc...)</Option>
                                <Option key="Other, leave in comments" value="Other, leave in comments">Other, leave in comments</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="referenceSourceComments"
                            label="Comments">
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                {(props.enrollmentApplicationConfig.agreements && props.enrollmentApplicationConfig.agreements.length > 0) &&
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="Terms and Conditions"
                                valuePropName="checked"
                                rules={[{ validator: checkAgreements }]}//Update verbiage to handle 1 or more agreements
                            >
                                <AgreementsConfirm agreements={props.enrollmentApplicationConfig.agreements} allAccepted={false}/>
                            </Form.Item>
                        </Col>
                    </Row>}
            </Card>
        </React.Fragment>
    )
}

export default WaitlistEnrollmentSubmitStep