import React, {useState} from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Form, Spin, Input, Button, notification, Row, Col } from 'antd';

/** Presentational */
import FormWrapper from '../../../Components/Styled/FormWrapper';

/** App theme */
import { colors } from '../../../Themes/Colors';
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { Store } from 'rc-field-form/es/interface';


function ForgotPasswordContainer() {
  const [username, setUsername ] = useState('');
  const [redirect, setRedirect ] = useState(false);
  const [loading, setLoading ] = useState(false);


  function handleFinish(values: Store) {
    let { username } = values;

    setLoading(true);
    setUsername(username);

    Auth.forgotPassword(username)
      .then(() => {
        notification.success({
          message: 'Redirecting you in a few!',
          description: 'Account confirmed successfully!',
          placement: 'topRight',
          duration: 1.5,
          onClose: () => {
            setLoading(false);
            setRedirect(true);
          }
        });
      })
      .catch(err => {
        notification.error({
          message: 'User confirmation failed',
          description: err.message,
          placement: 'topRight',
          duration: 1.5
        });
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <FormWrapper onFinish={handleFinish} className="login-form">
        <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input prefix={<UserOutlined style={{ color: colors.transparentBlack }} />} placeholder="Username" />
        </Form.Item>
        <Form.Item className="text-center">
          <Row>
            <Col lg={24}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit" className="login-form-button">
                {loading ? (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                ) : (
                  'Confirm username'
                )}
              </Button>
            </Col>
            <Col lg={24}>
              <Link to="/login">Ooh! Wait! I've remembered!</Link>
            </Col>
          </Row>
        </Form.Item>
      </FormWrapper>
      {redirect && (
        <Navigate
          to={{
            pathname: '/reset-password',
            search: `?username=${username}`
          }}
        />
      )}
    </React.Fragment>
  );
}

export default ForgotPasswordContainer;
