import {Family, Student, StudentStatus, StudentTag} from '../Model/enrollmentModel';
import queryString from 'query-string';
import dayjs from "dayjs";

/**
 * Helper method to sort a list of grades.
 * 
 * @param grades the grades list to sort
 */
export function sortGrades(grades: string[]) {
	if (grades && grades.length > 1) {
		let nonnumbers = grades.filter((element: string) => "0123456789".indexOf(element.charAt(0)) < 0);
		let numbers = grades.filter((element: string) => "0123456789".indexOf(element.charAt(0)) >= 0);
		return nonnumbers.sort().concat(numbers.sort());
	} else {
		return grades;
	}
}

export function sortStudentStatuses(statuses: StudentStatus[]) {
	if (statuses && statuses.length > 1) {
    return statuses.sort((a, b) => a.sortOrder - b.sortOrder)
	} else {
		return statuses;
	}
}

export function getStringValue(value, defaultValue='') {
	return value ? value : defaultValue;
	//return value === undefined ? "" : value;
}

export function decorateStudents(students: Student[]) {
	if (students) {
		students.forEach(student => {
			decorateStudent(student);
		});
	}
}

export function decorateStudent(student: Student) {
  if (student) {
    student['displayName'] = `${getStringValue(student.lastName)}, ${getStringValue(student.firstName)}`;

    //student['applicationDate'] = student.createdTimestamp ? dayjs(student.createdTimestamp).local().format('yyyy-MM-DD HH:mm') : null;
    student['applicationDate'] = student.createdTimestamp ? dayjs(student.createdTimestamp).format('MMM D, YYYY h:mm a') : null;
  }
}

export function decorateFamilies(families: Family[]) {
    if (families) {
      families.forEach(family => {
        decorateFamily(family);
      });
    }
  }

export function decorateFamily(family: Family) {
    family.guardianSummary = "";
        family.studentSummary = "";
        family.tagsSummary = [];
        family.statusSummary = [];

        //primary guardian
        if (family.primaryGuardian && (family.primaryGuardian.lastName || family.primaryGuardian.firstName)) {
          family.primaryGuardian['displayName'] = `${getStringValue(family.primaryGuardian.lastName)}, ${getStringValue(family.primaryGuardian.firstName)}`
          family.guardianSummary += `${family.primaryGuardian['displayName']}`
        }

        //secondary guardian
        if (family.secondaryGuardian && (family.secondaryGuardian.lastName || family.secondaryGuardian.firstName)) {
          family.secondaryGuardian['displayName'] = `${getStringValue(family.secondaryGuardian.lastName)}, ${getStringValue(family.secondaryGuardian.firstName)}`
          family.guardianSummary += `\n${getStringValue(family.secondaryGuardian['displayName'])}`
        }

        //summaries
        if (family.students) {
          family.students.forEach(student => {
            student['displayName'] = `${student.lastName}, ${student.firstName}`
            if (family.studentSummary.length > 0) {
              family.studentSummary += ", ";
            }
            family.studentSummary += `${getStringValue(student.firstName)} (${getStringValue(student.grade)})`;

            //tags
            if (student.tags) {
              student.tags.forEach(tag => {
                if (!family.tagsSummary.includes(tag)) {
                  family.tagsSummary.push(tag);
                }
              });
            }

            //status
            if (student.status && !family.statusSummary.includes(student.status)) {
              family.statusSummary.push(student.status);
            }
          });
        }
  }

export function statusSorter(a, b, sortOrderMap) {
  return sortOrderMap[a.status] - sortOrderMap[b.status];
}

export function getSelectableStudentTags(allTags: StudentTag[]) {
  let outTags: StudentTag[] = [];

  if (allTags) {
    allTags.forEach(tag => {
      if (tag.userSelectable) {
        outTags.push(tag);
      }
    })
  }

  return outTags.sort();
}

export function isAdminEnabled(props) {
  console.log(`isAdminEnabled pathname:${props.location.pathname}`);
  let params = queryString.parse(props.location.search);

  let isAdmin = false;
  if (params && params.admin) {
    isAdmin = true;
    console.log('Admin mode enabled')
  }
  return isAdmin;
}
