import authManager from "../Auth/authManager";
import {DbcProps} from "../Model/dbcModel";
const apiName = "enrollmentApi";
const dbcPath = "/admin/dbc";

export async function runDbc(dbcConfig: DbcProps) {
    console.log("dbcManagerService.runDbc dbcConfig:{}", dbcConfig);
    let myInit = {
        body: dbcConfig,
    }

    return await authManager.apiPost(apiName, dbcPath, myInit);
}