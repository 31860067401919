import {Button, Select, Input, Switch, notification, Form, Spin, Card} from "antd";
import React from "react";
import * as DbcManagerService from "../../../Services/dbcManagerService";
import {DbcProps} from "../../../Model/dbcModel";
import SettingsFormWrapper from "../../../Components/Styled/SettingsFormWrapper";
import {Store} from "rc-field-form/es/interface";
import {LoadingOutlined} from "@ant-design/icons";


const {Option} = Select;
const {TextArea} = Input;

function DbcManagerComponent() {
    let [loading, setLoading] = React.useState<boolean>(false);
    let [requestConfig, setRequestConfig] = React.useState<DbcProps>(null);
    let [result, setResult] = React.useState<any>(null);

    let handleFinish = (values: Store) => {
        console.log(`dbc - entering handleFinish values: ${JSON.stringify(values)}`);
        let dbcProps: DbcProps = {
            dbcId: values.dbcId,
            commit: values.commit,
            credentials: values.credentials,
        }

        if (values.itemTypes?.length > 0) {
            dbcProps.itemTypes = values.itemTypes;
        }
        if (values.props?.length > 0) {
            dbcProps.props = JSON.parse(values.props);
        }
        setRequestConfig(dbcProps);
        setLoading(true);

        DbcManagerService.runDbc(dbcProps).then((response: any) => {
            console.log(response);
            setResult(response);
            setLoading(false);
        }).catch(error => {
            console.log(error.response)
            notification.error({
                message: 'Error',
                description: 'Unable to run DBC.', //TODO handle error appropriately
                placement: 'topRight',
                duration: 1.5
            });
            setLoading(false);
        })
    };

    return (
        <React.Fragment>
            <SettingsFormWrapper
                size="middle"
                onFinish={handleFinish}
                scrollToFirstError
                requiredMark={false}
                layout="vertical"
            >
                <Form.Item
                    name="dbcId"
                    label="DBC ID"
                    rules={[{
                        required: true,
                    }]}>
                    <Select>
                        <Option value="dbc_0_updateItems">dbc_0_updateItems</Option>
                        <Option value="dbc_1_createTimestamp">dbc_1_createTimestamp</Option>
                        <Option value="dbc_2_migrateDataModel">dbc_2_migrateDataModel</Option>
                        <Option value="dbc_3_enrollmentConfigRefactor">dbc_3_enrollmentConfigRefactor</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="itemTypes"
                    label="ItemTypes"
                    rules={[{
                        required: false,
                    }]}>
                    <Select mode="multiple">
                        <Option value="District">District</Option>
                        <Option value="Organization">Organization</Option>
                        <Option value="Student">Student</Option>
                        <Option value="Family">Family</Option>
                        <Option value="ClientConfig">ClientConfig</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="props"
                    label="Properties"
                    rules={[{
                        required: false,
                    }]}>
                    <TextArea/>
                </Form.Item>
                <Form.Item
                    name="credentials"
                    label="Credentials"
                    rules={[{
                        required: true,
                    }]}>
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="commit"
                    label="Commit"
                    rules={[{
                        required: false,
                    }]}>
                    <Switch/>
                </Form.Item>
                <Button style={{width: '100%'}} type="primary" disabled={loading}
                        htmlType="submit">
                    {loading ? <Spin indicator={<LoadingOutlined type="loading" style={{fontSize: 24}}
                                                                 spin/>}/> : 'Run DBC'}
                </Button>
            </SettingsFormWrapper>
            <Card>
                {requestConfig && <pre>{JSON.stringify(requestConfig, null, 2)}</pre>}
            </Card>
            <Card>
                {result && <pre>{JSON.stringify(result, null, 2)}</pre>}
            </Card>
        </React.Fragment>
    );
}

export default DbcManagerComponent;