import * as React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import authManager from '../Auth/authManager';
import {useUser} from "../Store/UserStore";

function RequireAuth({ children }: { children: JSX.Element }) {
    const location = useLocation();
    const user = useUser();

    if (!authManager.isAuthenticated()) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        console.log(`RequireAuth - ${location.pathname}: redirecting to login `);
        return <Navigate to="/login" state={{ from: location }} />;
    } else if (!user.clientConfig) {
        console.log(`RequireAuth - ${location.pathname}: clientConfig not loaded: user:${JSON.stringify(user)}`)
        return null;
    } else {
        console.log(`RequireAuth - ${location.pathname}: authenticated user:${JSON.stringify(user)}`);
        return children;
    }

}

export default RequireAuth;