import React, {useEffect, useRef, useState} from 'react';
import {Drawer, Form, Button, Col, Row, Input, Select, Collapse, Popconfirm, Descriptions} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {Family, Address, PhoneNumber, Student, PhoneNumberType} from '../../../Model/enrollmentModel';
import {getSelectableStudentTags} from '../../../Utils/helpers';
import {Store} from 'rc-field-form/es/interface';
import {FormInstance} from 'antd/lib/form';
import DistrictLookupDrawer from './DistrictLookupDrawer';
import GuardianPanel from "./GuardianPanel";
import StudentsPanel from "./StudentsPanel";
import {useUser} from "../../../Store/UserStore";
import dayjs from "dayjs";

const {Option} = Select;
const {Panel} = Collapse;

interface IFamilyDrawerProps {
    family?: Family;
    visible: boolean;
    isNewFamily: boolean;
    onDelete: () => void;
    onClose: () => void;
    onSubmit: (family: Family) => void;
    deleteFamily: () => void;
    setFamily: (family: Family) => void;
}

function getFormFieldsValues(family) {
    console.log("FamilyDrawerComponent.getFormFieldsValues");
    let values: any = {};
    if (family) {
        values['notes'] = family.notes;
        values['districtStatus'] = family.districtStatus;
        values['familyTags'] = family['familyTags'];
        values['referenceSource'] = family.referenceSource;
        values['referenceSourceComments'] = family.referenceSourceComments;
        values['enrolledStudentsIds'] = family.enrolledStudentsIds;
        if (family.primaryGuardian) {
            values['p_first_name'] = family.primaryGuardian.firstName;
            values['p_last_name'] = family.primaryGuardian.lastName;
            values['p_email'] = family.primaryGuardian.email;
            if (family.primaryGuardian.address) {
                values['p_address_line_1'] = family.primaryGuardian.address.addressLine1;
                values['p_address_line_2'] = family.primaryGuardian.address.addressLine2;
                values['p_city'] = family.primaryGuardian.address.city;
                values['p_state'] = family.primaryGuardian.address.state;
                values['p_zip_code'] = family.primaryGuardian.address.zipCode;
            }
            if (family.primaryGuardian.phoneNumbers) {
                family.primaryGuardian.phoneNumbers.forEach(phoneNumber => {
                    if (phoneNumber && phoneNumber.primary) {
                        values['p_phone_number'] = phoneNumber.number;
                    }
                });
            }
        }
        if (family.secondaryGuardian) {
            values['s_first_name'] = family.secondaryGuardian.firstName;
            values['s_last_name'] = family.secondaryGuardian.lastName;
            values['s_email'] = family.secondaryGuardian.email;
            if (family.secondaryGuardian.address) {
                values['s_address_line_1'] = family.secondaryGuardian.address.addressLine1;
                values['s_address_line_2'] = family.secondaryGuardian.address.addressLine2;
                values['s_city'] = family.secondaryGuardian.address.city;
                values['s_state'] = family.secondaryGuardian.address.state;
                values['s_zip_code'] = family.secondaryGuardian.address.zipCode;
            }
            if (family.secondaryGuardian.phoneNumbers) {
                family.secondaryGuardian.phoneNumbers.forEach(phoneNumber => {
                    if (phoneNumber && phoneNumber.primary) {
                        values['s_phone_number'] = phoneNumber.number;
                    }
                });
            }
        }

        if (family.students) {
            console.log(`FamilyDrawerComponent.getFormFieldsValues #students:${family.students.length}`);
            family.students.forEach(student => {
                student.birthDate = dayjs(student.birthDate);
            });
            values['students_form_list'] = family.students;
        }
    }
    return values;
}

function getOrigStudent(student, origStudents) {
    console.log("FamilyDrawerComponent.getOrigStudent");
    let origStudent = null;

    if (origStudents && student) {
        origStudents.forEach(curStudent => {
            if (curStudent.id && curStudent.id.localeCompare(student.id) === 0) {
                origStudent = curStudent;
            }
        })
    }

    return origStudent;
}

function getUpdatedFamily(family: Family, clientId: string, allValues) {
    console.log("FamilyDrawerComponent.getUpdatedFamily");

    let {
        p_first_name,
        p_last_name,
        p_email,
        p_address_line_1,
        p_address_line_2,
        p_city,
        p_state,
        p_zip_code,
        p_phone_number,
        s_first_name,
        s_last_name,
        s_email,
        s_address_line_1,
        s_address_line_2,
        s_city,
        s_state,
        s_zip_code,
        s_phone_number,
        students_form_list,
        notes,
        referenceSource,
        referenceSourceComments,
        districtStatus,
        enrolledStudentsIds
    } = allValues;

    family.notes = notes;
    family.districtStatus = districtStatus;
    family.referenceSource = referenceSource;
    family.referenceSourceComments = referenceSourceComments;
    family.enrolledStudentsIds = enrolledStudentsIds;

    //PRIMARY
    let primaryAddress: Address = {
        addressLine1: p_address_line_1,
        addressLine2: p_address_line_2,
        city: p_city,
        state: p_state,
        zipCode: p_zip_code
    };

    let primaryPhoneNumber: PhoneNumber = {
        number: p_phone_number,
        type: PhoneNumberType.Mobile,
        primary: true
    };

    family.primaryGuardian = {
        firstName: p_first_name,
        lastName: p_last_name,
        phoneNumbers: [primaryPhoneNumber],
        address: primaryAddress,
        email: p_email
    };

    //SECONDARY
    let secondaryAddress: Address = {
        addressLine1: s_address_line_1,
        addressLine2: s_address_line_2,
        city: s_city,
        state: s_state,
        zipCode: s_zip_code
    };

    let secondaryPhoneNumber: PhoneNumber = {
        number: s_phone_number,
        type: PhoneNumberType.Mobile,
        primary: true
    };

    family.secondaryGuardian = {
        firstName: s_first_name,
        lastName: s_last_name,
        phoneNumbers: [secondaryPhoneNumber],
        address: secondaryAddress,
        email: s_email
    };

    let origStudents = family.students ? family.students : [];
    family.students = [];

    students_form_list.forEach((student: any) => {
        let origStudent = getOrigStudent(student, origStudents);
        var studentObj: Student = {
            clientId: clientId,
            id: student.id,
            familyId: family.id,
            firstName: student.firstName,
            lastName: student.lastName,
            gender: student.gender,
            birthDate: student.birthDate,
            grade: student.grade,
            status: student.status,
            tags: student.tags,
            notes: student.notes,
            districtStatus: student.districtStatus,
            createdTimestamp: origStudent ? origStudent.createdTimestamp : null,
            acceptedDate: student.acceptedDate,
            deletedInd: student.deletedInd,
            schoolYearId: student.schoolYearId,
            contactInfo: student.contactInfo,
            lotteryRank: student.lotteryRank,
            waitlistPosition: student.waitlistPosition,
            siblingDate: student.siblingDate,
        }
        family.students.push(studentObj);
    });
    return family;
}

function getDistrictStatusList(user: any) {
    var sortedDistrictStatuses = [{code: null, sortOrder: 0}];
    if (user.clientConfig.districtStatuses) {
        sortedDistrictStatuses.push(...user.clientConfig.districtStatuses);
    }
    return sortedDistrictStatuses.map(function (status: any) {
        return <Option key={status.code} value={status.code}>{status.code}</Option>;
    });
}

function getTagsList(user: any) {
    var sortedTags = getSelectableStudentTags(user.clientConfig.studentTags);
    return sortedTags.map(function (tag: any) {
        return <Option key={tag.name} value={tag.name}>{tag.name}</Option>;
    });
}

function getFamilyIdSection(family: Family, isNewFamily: boolean) {
    if (!isNewFamily && family) {
        return <Descriptions size={"small"} bordered column={1}>
            <Descriptions.Item label="ID">{family.id}</Descriptions.Item>
            <Descriptions.Item label="Confirmation Number">{family.confirmationNumber}</Descriptions.Item>
        </Descriptions>
    } else {
        return null;
    }
}

function FamilyDrawerForm(props: IFamilyDrawerProps) {
    console.log("FamilyDrawerForm constructed");
    const user = useUser();
    const [districtLookupDrawerOpen, setDistrictLookupDrawerOpen] = useState(false);
    const formRef = useRef<FormInstance>();

    useEffect(() => {
        if (formRef && formRef.current) {
            formRef.current.setFieldsValue(getFormFieldsValues(props.family));
        }
    }, [props.family])

    function updateDistrictStatus(value: string) {
        console.log("FamilyDrawerComponent.updateDistrictStatus");
        let updatedFamily = props.family
        updatedFamily.districtStatus = value;

        if (updatedFamily.students) {
            updatedFamily.students.forEach(student => {
                student.districtStatus = value;
            })
        }

        props.setFamily(updatedFamily);
    }

    function updateFamilyTags(values: any[]) {
        console.log("FamilyDrawerComponent.updateFamilyTags");
        let updatedFamily = props.family;
        let removedTags = updatedFamily['familyTags'].filter(x => !values.includes(x));
        updatedFamily['familyTags'] = values;
        updatedFamily.students.forEach(student => {
            if (!student.tags) {
                student.tags = []
            }
            student.tags = student.tags.filter(x => !removedTags.includes(x));
            values.forEach(tag => {
                if (student.tags.indexOf(tag) < 0) {
                    student.tags.push(tag);
                }
            });
        });

        props.setFamily(updatedFamily);
    }

    function onFamilyValuesChange(changedValues, allValues) {
        console.log('onFamilyValuesChange')
        props.setFamily(getUpdatedFamily(props.family, user.clientConfig.clientId, allValues));
    }

    function onSubmit(values: Store) {
        let family = getUpdatedFamily(props.family, user.clientConfig.clientId, values);
        props.onSubmit(family);
    }

    function onDistrictLookupClick() {
        setDistrictLookupDrawerOpen(true);
    }

    function onDistrictLookupClose() {
        setDistrictLookupDrawerOpen(false);
    }

    function onDistrictLookupSubmit(districtStatus: string) {
        console.log(`onDistrictLookupSubmit districtStatus:${districtStatus}`);
        updateDistrictStatus(districtStatus);
        setDistrictLookupDrawerOpen(false);
    }

    let formInitialValues: any = {
        initialValues: getFormFieldsValues(props.family),
    };

    var tagsList = getTagsList(user);
    var districtStatusList = getDistrictStatusList(user);
    let showDeleteFamily = false;
    let showRestoreFamily = false;

    if (!props.isNewFamily && props.family) {
        showDeleteFamily = !props.family.deletedInd;
        showRestoreFamily = !showDeleteFamily;
    }

    return (
        <React.Fragment>
            <Drawer
                title={"Family"}
                width={800}
                onClose={props.onClose}
                visible={props.visible}
                bodyStyle={{paddingBottom: 80}}
                destroyOnClose
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        {showDeleteFamily &&
                            <Popconfirm
                                title="Are you sure delete this family?"
                                onConfirm={props.deleteFamily}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button>Delete Family</Button>
                            </Popconfirm>
                        }
                        {showRestoreFamily &&
                            <Popconfirm
                                title="Are you sure restore this family?"
                                onConfirm={props.deleteFamily}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button>Restore Family</Button>
                            </Popconfirm>
                        }
                        <Button onClick={props.onClose} style={{marginRight: 8}}>Cancel</Button>
                        <Button form="familyForm" type="primary" htmlType="submit">Save</Button>
                    </div>
                }
            >
                <DistrictLookupDrawer
                    family={props.family}
                    clientConfig={user.clientConfig}
                    visible={districtLookupDrawerOpen}
                    onClose={onDistrictLookupClose}
                    onSubmit={onDistrictLookupSubmit}
                />
                <Form
                    id="familyForm"
                    ref={formRef}
                    layout="vertical"
                    size="medium"
                    requiredMark={false}
                    {...formInitialValues}
                    onFinish={onSubmit}
                    onValuesChange={onFamilyValuesChange}
                >
                    {getFamilyIdSection(props.family, props.isNewFamily)}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="notes"
                                label="Notes"
                            >
                                <Input.TextArea rows={4}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="districtStatus"
                                label={<div>District Status <Button onClick={onDistrictLookupClick}
                                                                    icon={<SearchOutlined/>} type="link">Lookup
                                    Address</Button></div>}
                            >
                                <Select
                                    style={{width: '100%'}}
                                    onChange={updateDistrictStatus}
                                    optionLabelProp="label"
                                >
                                    {districtStatusList}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="familyTags"
                                label="Family Tags"
                            >
                                <Select
                                    mode="multiple"
                                    style={{width: '100%'}}
                                    onChange={updateFamilyTags}
                                    optionLabelProp="label"
                                >
                                    {tagsList}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Collapse
                        defaultActiveKey={["studentsPanel", "primaryGuardianPanel", "secondaryGuardianPanel", "otherInfoPanel"]}
                    >
                        <Panel header="Students" key="studentsPanel">
                            <StudentsPanel
                                clientConfig={user.clientConfig}
                                family={props.family}
                                setFamily={props.setFamily}
                            />
                        </Panel>

                        <Panel header="Primary Guardian" key="primaryGuardianPanel">
                            <GuardianPanel isPrimary={true}/>
                        </Panel>
                        <Panel header="Secondary Guardian" key="secondaryGuardianPanel">
                            <GuardianPanel isPrimary={false}/>
                        </Panel>
                        <Panel header="Other info" key="otherInfoPanel">
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        name="referenceSource"
                                        label="How did they hear about the school?">
                                        <Select style={{width: '50%'}}>
                                            <Option key="Open House" value="Open House">Open House</Option>
                                            <Option key="Field Trip" value="Field Trip">Field Trip</Option>
                                            <Option key="Friend" value="Friend">Friend</Option>
                                            <Option key="Local Public School" value="Local Public School">Local Public
                                                School</Option>
                                            <Option key="Facebook" value="Facebook">Facebook</Option>
                                            <Option key="Sibling Attending" value="Sibling Attending">Sibling
                                                Attending</Option>
                                            <Option key="Sign on School Property" value="Sign on School Property">Sign
                                                on School Property</Option>
                                            <Option key="Website" value="Website">Website</Option>
                                            <Option key="Community Event" value="Community Event">Community
                                                Event</Option>
                                            <Option key="Radio" value="Radio">Radio</Option>
                                            <Option key="Print Media (Newspaper, mailer, etc...)"
                                                    value="Print Media (Newspaper, mailer, etc...)">Print Media
                                                (Newspaper, mailer, etc...)</Option>
                                            <Option key="Other, leave in comments" value="Other, leave in comments">Other,
                                                leave in comments</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}></Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="referenceSourceComments"
                                        label="Comment">
                                        <Input.TextArea style={{width: "100%"}}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Form>
            </Drawer>
        </React.Fragment>
    );
}

export default FamilyDrawerForm;