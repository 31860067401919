
import * as React from 'react';
import { Result } from 'antd';
import { EnrollmentApplicationConfig } from "../Model/enrollmentModel";
  
export interface EnrollmentDisabledProps {
    enrollmentApplicationConfig: EnrollmentApplicationConfig;
}
  
export const EnrollmentDisabled: React.FC<EnrollmentDisabledProps> = ({ enrollmentApplicationConfig }) => {
  
    return (
      <span>
        <Result
            status="warning"
            title={`Enrollment is currently closed for Heritage Heights Academy`}
            subTitle="Please check back when enrollment opens."
        />
      </span>
    );
  }; 