import * as React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useUser} from "../Store/UserStore";

function RequireAdmin({ children }: { children: JSX.Element }) {
    let location = useLocation();
    let user = useUser();

    console.log("entering RequireAdmin");
    if (user.isAdmin) {
        console.log(`RequireAdmin: isAdmin=true`);
        return children;
    } else {
        console.log("RequireAdmin: isAdmin=false redirecting to dashboard");
        return <Navigate to="/dashboard" state={{ from: location }} />;
    }
}

export default RequireAdmin;