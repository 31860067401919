import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Button, Card, Col, DatePicker, Form, Input, List, notification, Row, Spin, Steps, Switch} from "antd";
import {
    CheckOutlined,
    CloseOutlined,
    LoadingOutlined,
    OrderedListOutlined,
    PlayCircleOutlined,
    RedoOutlined,
    StopOutlined
} from "@ant-design/icons";
import EnrollmentPeriodStatusTag from "../../Components/EnrollmentPeriodStatusTag";
import {EnrollmentPeriod} from "../../../Model/enrollmentModel";
import {FormInstance} from "antd/lib/form";
import {formatTimestamp, nowTimestamp, parseTimestamp} from "../../../Utils/DateUtils";
import {useUser} from "../../../Store/UserStore";
import * as EnrollmentPeriodServices from "../../../Services/EnrollmentPeriodsService";
import SettingsFormWrapper from "../../../Components/Styled/SettingsFormWrapper";
import {Dayjs} from "dayjs";

interface Props {
    enrollmentPeriod: EnrollmentPeriod,
}

interface StepStatus {
    startDateStatus: "finish" | "process" | "wait" | "error",
    endDateStatus: "finish" | "process" | "wait" | "error",
    lotteryDateStatus: "finish" | "process" | "wait" | "error",
}

function onOk(value: any) {
    console.log('onOk: ', value);
}

function getFormValues(enrollmentPeriod: EnrollmentPeriod) {
    return {
        ...enrollmentPeriod,
        createDate: parseTimestamp(enrollmentPeriod.createDate),
        startDate: parseTimestamp(enrollmentPeriod.startDate),
        endDate: parseTimestamp(enrollmentPeriod.endDate),
        lotteryDate: parseTimestamp(enrollmentPeriod.lotteryDate),
    }
}

function getUpdatedEnrollmentPeriod(values: any): EnrollmentPeriod {
    return {
        ...values,
        startDate: formatTimestamp(values.startDate),
        lotteryDate: formatTimestamp(values.lotteryDate),
        endDate: formatTimestamp(values.endDate),
    }
}

function getStepStatuses(startDate: Dayjs, lotteryDate: Dayjs, endDate: Dayjs): StepStatus {
    const now = nowTimestamp()
    const pastStartDate = startDate?.isBefore(now)
    const pastEndDate = endDate?.isBefore(now)
    const pastLotteryDate = lotteryDate?.isBefore(now)
    return {
        startDateStatus: getStepStatus(pastStartDate, true),
        lotteryDateStatus: getStepStatus(pastLotteryDate, pastStartDate),
        endDateStatus: getStepStatus(pastEndDate, pastLotteryDate)
    }
}

function getStepStatus(isPast: boolean, isPriorPast: boolean): "finish" | "process" | "wait" | "error" {
    if (isPast) {
        return 'finish'
    } else if (isPriorPast) {
        return 'process'
    } else {
        return 'wait'
    }
}

function EnrollmentPeriodSettingsComponent(props: Props) {
    let user = useUser()
    const [isEdited, setIsEdited] = useState(false);
    const [loading, setLoading] = useState(false);
    const formRef = useRef<FormInstance>();
    let [curEnrollmentPeriod, setCurEnrollmentPeriod] = React.useState<any>(getFormValues(props.enrollmentPeriod))
    let editDisabled = false
    let [stepStatus, setStepStatus] = useState<StepStatus>({
        startDateStatus: 'wait',
        endDateStatus: 'wait',
        lotteryDateStatus: 'wait',
    })

    function updateFormValues(enrollmentPeriod: EnrollmentPeriod) {
        let values = getFormValues(enrollmentPeriod)
        setCurEnrollmentPeriod(values)
        formRef.current.setFieldsValue(values)
    }

    useEffect(() => {
        setStepStatus(getStepStatuses(curEnrollmentPeriod.startDate, curEnrollmentPeriod.lotteryDate, curEnrollmentPeriod.endDate))
    }, [curEnrollmentPeriod.startDate, curEnrollmentPeriod.lotteryDate, curEnrollmentPeriod.endDate])

    let onCancel = () => {
        console.log(`settings - onCancel resetting form fields to props.enrollmentPeriod values`)
        setIsEdited(false);
        updateFormValues(props.enrollmentPeriod)
    }

    let onValuesChange = (changedValues: any, allValues: any) => {
        isEdited || setIsEdited(true);
        setCurEnrollmentPeriod({...curEnrollmentPeriod, ...allValues})
    }

    function onFinish(values: any) {
        console.log(`onFinish: ${JSON.stringify(values)} `)
        let updatedEnrollmentPeriodValues = {...curEnrollmentPeriod, ...values}
        setCurEnrollmentPeriod(updatedEnrollmentPeriodValues)
        setLoading(true);

        const updatedEnrollmentPeriod = getUpdatedEnrollmentPeriod(updatedEnrollmentPeriodValues);


        setLoading(true);
        EnrollmentPeriodServices.updateEnrollmentPeriod(user.clientConfig.clientId, updatedEnrollmentPeriod).then((response) => {
            notification.success({
                message: 'Enrollment Period Saved',
                placement: 'topRight',
                duration: 1.5
            });
            setIsEdited(false);
        }).catch((error) => {
            notification.error({
                message: 'Unable to save Enrollment Period',
                placement: 'topRight',
                duration: 1.5
            });
        }).finally(() => {
            setLoading(false);
        })
    }

    return (<React.Fragment>
        <Card>
            <Row>
                <Col span={12}>
                    <SettingsFormWrapper
                        onFinish={onFinish}
                        initialValues={curEnrollmentPeriod}
                        onValuesChange={onValuesChange}
                        layout='vertical'
                        ref={formRef}
                    >
                        <Form.Item
                            name='status'
                            rules={[{required: false}]}>
                            <EnrollmentPeriodStatusTag/>
                        </Form.Item>
                        <Form.Item
                            name='name'
                            rules={[{required: !props.enrollmentPeriod.isShadowNextPeriod}]}
                        >
                            <Input
                                disabled={editDisabled}/>
                        </Form.Item>
                        <Steps
                            direction="vertical"
                            items={[
                                {
                                    title: 'Start',
                                    icon: <PlayCircleOutlined/>,
                                    status: stepStatus.startDateStatus,
                                    description: <Form.Item
                                        name='startDate'
                                        rules={[{required: !props.enrollmentPeriod.isShadowNextPeriod}]}>
                                        <DatePicker
                                            showTime={{format: 'HH:mm'}}
                                            format="YYYY-MM-DD HH:mm"
                                            use12Hours
                                            onOk={onOk}
                                            disabled={editDisabled}
                                        />
                                    </Form.Item>,
                                },
                                {
                                    title: 'Lottery',
                                    icon: <OrderedListOutlined/>,
                                    status: stepStatus.lotteryDateStatus,
                                    description: <Form.Item
                                        name='lotteryDate'
                                        rules={[{required: !props.enrollmentPeriod.isShadowNextPeriod}]}>
                                        <DatePicker
                                            showTime={{format: 'HH:mm'}}
                                            format="YYYY-MM-DD HH:mm"
                                            use12Hours
                                            onOk={onOk}
                                            disabled={editDisabled}
                                        />
                                    </Form.Item>,
                                },
                                {
                                    title: 'End',
                                    icon: <StopOutlined/>,
                                    status: stepStatus.endDateStatus,
                                    description: <Form.Item
                                        name='endDate'
                                        rules={[{required: !props.enrollmentPeriod.isShadowNextPeriod}]}>
                                        <DatePicker
                                            showTime={{format: 'HH:mm'}}
                                            format="YYYY-MM-DD HH:mm"
                                            use12Hours
                                            onOk={onOk}
                                            disabled={editDisabled}
                                        />
                                    </Form.Item>,
                                },
                                {
                                    title: 'Rollover Waitlist',
                                    icon: <RedoOutlined/>,
                                    status: 'finish',
                                    description: <Form.Item
                                        name='rolloverWaitlist'
                                        label="Rollover Waitlist"
                                        tooltip={"If enabled, waitlisted students will be rolled over to the next enrollment period."}
                                        rules={[{required: false}]}>
                                        <Switch
                                            checkedChildren={<CheckOutlined/>}
                                            unCheckedChildren={<CloseOutlined/>}
                                            defaultChecked
                                        />
                                    </Form.Item>,
                                },
                            ]}
                        />
                        <Form.Item className="text-center">
                            <Row gutter={8}>
                                <Col span={20}/>
                                <Col span={4}>
                                    <Button style={{width: '100%'}} disabled={loading || !isEdited} onClick={onCancel}
                                            htmlType="button">Cancel</Button>
                                </Col>
                                <Col span={4}>
                                    <Button style={{width: '100%'}} type="primary" disabled={loading || !isEdited}
                                            htmlType="submit">
                                        {loading ?
                                            <Spin indicator={<LoadingOutlined type="loading" style={{fontSize: 24}}
                                                                              spin/>}/> : 'Save'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </SettingsFormWrapper>
                </Col>
                <Col span={12}>
                    <List
                        header={<div>Events</div>}
                        bordered
                        dataSource={props.enrollmentPeriod.events}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.type}
                                    description={item.timestamp}
                                />
                                {/*<div>{item.detail}</div>*/}
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Card>

    </React.Fragment>)
}

export default EnrollmentPeriodSettingsComponent