import * as React from 'react';
import {Button, Col, Result, Statistic, Layout} from 'antd';
import {useLocation} from "react-router-dom";

/** Presentational */
import EmailConfirmFormWrapper from '../../Components/Styled/EmailConfirmFormWrapper';
import {mainContentStyle} from "../../Themes/Styles";
import MainHeader from "../../Components/MainHeader";
import MainFooter from "../../Components/MainFooter";
import {useEffect, useState} from "react";

const { Content } = Layout;



function ApplicationConfirmationComponent() {
    let location = useLocation();
    let [confirmationNumber, setConfirmationNumber] = useState<string | null>();
    let [isNewApplication, setIsNewApplication] = useState<boolean>(true);//TODO take this in as param

    useEffect(() => {
        if (location) {
            if (location.state) {
                console.log(`location.state is not null: ${location.state}`)
                setConfirmationNumber(location.state.confirmationNumber);
                setIsNewApplication(location.state.isNewApplication ?? true)
            } else {
                console.log('location.state is null')
            }
        } else {
            console.log('location is null')
        }
    }, [location])

  return (
      <Layout style={{ height: '100vh' }}>
        <MainHeader/>
        <Content>
            <EmailConfirmFormWrapper
                style={mainContentStyle}>
              <Col md={24} lg={18}>
              <Result
                status="success"
                title={isNewApplication ? "Your application has been submitted!" : "Your application has been updated!"}
                subTitle={<Statistic decimalSeparator="" groupSeparator="" prefix="Confirmation #" title={isNewApplication ? "You should receive a confirmation email shortly." : "You should receive a confirmation email shortly."} value={confirmationNumber}/>}
                extra={[
                  <Button type="link" href="https://www.heritageha.org/">Return to Heritage Heights Academy</Button>
                ]}
              />
              </Col>
            </EmailConfirmFormWrapper>
        </Content>
        <MainFooter/>
      </Layout>
  );
}

export default ApplicationConfirmationComponent;
