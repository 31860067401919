import React, {useEffect} from 'react';
import {ClientConfig} from '../../../Model/enrollmentModel';
import {Table, Button} from 'antd';
import CompactTable from "../../../Components/Styled/CompactTable";

type Props = {
    clientConfig: ClientConfig;
    waitlistStatistics: any;
    onWaitlistTableClick: (grade, status) => void;
};

function mapStats(waitlistStatistics, grades) {
    console.log(`WaitlistStatusTable.waitlistStatistics: ${JSON.stringify(waitlistStatistics)}`)
    let data = [];

    grades.forEach(grade => {
        let curStats = waitlistStatistics[grade];
        if (curStats) {
            data.push({
                grade: grade,
                Accepted: curStats['Accepted'] ? curStats['Accepted'] : 0,
                Waitlist: curStats['Waitlist'] ? curStats['Waitlist'] : 0,
                Withdrawn: (curStats['Withdrawn'] ? curStats['Withdrawn'] : 0),
                Declined: curStats['Declined'] ? curStats['Declined'] : 0,
                MissedDeadline: curStats['Missed Deadline'] ? curStats['Missed Deadline'] : 0,
                Duplicate: curStats['Duplicate'] ? curStats['Duplicate'] : 0,
            });
        } else {
            data.push({
                grade: grade,
                Accepted: 0,
                Waitlist: 0,
                Withdrawn: 0,
                Declined: 0,
                MissedDeadline: 0,
                Duplicate: 0,
            });
        }
    })

    console.log(`WaitlistStatusTable.data: ${JSON.stringify(data)}`);
    return data;
}

function getColumns(onWaitlistTableClick: (grade, status) => void, data) {
    return [
        {
            title: 'Grade',
            dataIndex: 'grade',
            key: 'grade',
            width: 40,
            render: (text, record, index) => (
                <Button type="link" onClick={(event) => onWaitlistTableClick(data[index].grade, null)}
                        style={{width: '100%', textAlign: 'center'}}>{text}</Button>
            ),
        },
        {
            title: 'Accepted',
            dataIndex: 'Accepted',
            key: 'Accepted',
            width: 40,
            render: (text, record, index) => (
                <Button type="link" onClick={(event) => onWaitlistTableClick(data[index].grade, 'Accepted')}
                        style={{width: '100%', textAlign: 'center'}}>{text}</Button>
            ),
        },
        {
            title: 'Waitlist',
            dataIndex: 'Waitlist',
            key: 'Waitlist',
            width: 40,
            render: (text, record, index) => (
                <Button type="link" onClick={(event) => onWaitlistTableClick(data[index].grade, 'Waitlist')}
                        style={{width: '100%', textAlign: 'center'}}>{text}</Button>
            ),
        },
        {
            title: 'Withdrawn',
            dataIndex: 'Withdrawn',
            key: 'Withdrawn',
            width: 40,
            render: (text, record, index) => (
                <Button type="link" onClick={(event) => onWaitlistTableClick(data[index].grade, 'Withdrawn')}
                        style={{width: '100%', textAlign: 'center'}}>{text}</Button>
            ),
        },
        {
            title: 'Declined',
            dataIndex: 'Declined',
            key: 'Declined',
            width: 40,
            render: (text, record, index) => (
                <Button type="link" onClick={(event) => onWaitlistTableClick(data[index].grade, 'Declined')}
                        style={{width: '100%', textAlign: 'center'}}>{text}</Button>
            ),
        },
        {
            title: 'Missed Deadline',
            dataIndex: 'MissedDeadline',
            key: 'MissedDeadline',
            width: 40,
            render: (text, record, index) => (
                <Button type="link"
                        onClick={(event) => onWaitlistTableClick(data[index].grade, 'Missed Deadline')}
                        style={{width: '100%', textAlign: 'center'}}>{text}</Button>
            ),
        },
        {
            title: 'Duplicates',
            dataIndex: 'Duplicate',
            key: 'Duplicate',
            width: 40,
            render: (text, record, index) => (
                <Button type="link" onClick={(event) => onWaitlistTableClick(data[index].grade, 'Duplicate')}
                        style={{width: '100%', textAlign: 'center'}}>{text}</Button>
            ),
        }
    ];
}

function WaitlistStatusTable(props: Props) {
    let [columns, setColumns] = React.useState([]);
    let [data, setData] = React.useState([]);

    useEffect(() => {
        setColumns(getColumns(props.onWaitlistTableClick, data));
    }, [props.onWaitlistTableClick, data])

    useEffect(() => {
        setData(mapStats(props.waitlistStatistics, props.clientConfig.grades));
    }, [props.waitlistStatistics, props.clientConfig.grades])


    return (
        <CompactTable
            dataSource={data}
            columns={columns}
            bordered
            pagination={{position: ["none" as any, "none" as any]}}
            summary={(pageData: any) => {
                let totalAccepted = 0;
                let totalWaitlist = 0;
                let totalDeclined = 0;
                let totalWithdrawn = 0;
                let totalMissedDeadline = 0;
                let totalDuplicate = 0;

                pageData.forEach(({Accepted, Waitlist, Declined, Withdrawn, MissedDeadline, Duplicate}) => {
                    totalAccepted += Accepted;
                    totalWaitlist += Waitlist;
                    totalDeclined += Declined;
                    totalWithdrawn += Withdrawn;
                    totalMissedDeadline += MissedDeadline;
                    totalDuplicate += Duplicate;
                });

                return (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                            <Button type='text' style={{width: '100%', textAlign: 'center'}}>{totalAccepted}</Button>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={1}>
                            <Button type='text' style={{width: '100%', textAlign: 'center'}}>{totalWaitlist}</Button>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={2}>
                            <Button type='text' style={{width: '100%', textAlign: 'center'}}>{totalWithdrawn}</Button>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={3}>
                            <Button type='text' style={{width: '100%', textAlign: 'center'}}>{totalDeclined}</Button>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={3}>
                            <Button type='text'
                                    style={{width: '100%', textAlign: 'center'}}>{totalMissedDeadline}</Button>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={3}>
                            <Button type='text' style={{width: '100%', textAlign: 'center'}}>{totalDuplicate}</Button>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                );
            }}
        />)
}

export default WaitlistStatusTable;