import React from 'react';
import { Tag, Tooltip, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { StudentTag } from '../Model/enrollmentModel';

const { Option } = Select;

interface IEditableStudentTagsProps {
    availableTags: StudentTag[];
    tags: string[];
    id: string;
    updateStudentTags: (tags: string[], id: string) => void;
}

type State = {
    inputVisible: boolean;
    inputValue: string;
    editInputIndex: number;
    editInputValue: string;
  };

class EditableStudentTagsComponent extends React.Component<IEditableStudentTagsProps, State> {
    public input;    
    public editInput; 

    constructor(props) {
        super(props);

        this.state = {
            inputVisible: false,
            inputValue: '',
            editInputIndex: -1,
            editInputValue: '',
        }
        this.input = React.createRef(); 
        this.editInput = React.createRef();
    }
//    public input: React.RefObject<Select> = React.createRef<Select>();
//    public editInput: React.RefObject<Select> = React.createRef<Select>();

    handleClose = removedTag => {
        console.log("handleClose");
        const tags = this.props.tags.filter(tag => tag !== removedTag);
        console.log(tags);
        this.props.updateStudentTags(tags, this.props.id);
    };

    showInput = () => {
        console.log("showInput");
        this.setState({ inputVisible: true }, () => this.input.current.focus());
    };

    handleInputChange = (value) => {
        console.log("handleInputChange");
        this.setState({ inputValue: value });
    };

    handleInputConfirm = () => {
        console.log("handleInputConfirm");
        const { inputValue } = this.state;
        let tags = this.props.tags ? this.props.tags : [];
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        console.log(tags);
            this.setState({
            inputVisible: false,
            inputValue: '',
        });

        this.props.updateStudentTags(tags, this.props.id);
    };

    handleEditInputChange = (value) => {
        console.log("handleEditInputChange");
        this.setState({ editInputValue: value });
    };

    handleEditInputConfirm = () => {
        console.log("handleEditInputConfirm");
        
        this.setState(({ editInputIndex, editInputValue }) => {
            const newTags = [...this.props.tags];
            newTags[editInputIndex] = editInputValue;
            this.props.updateStudentTags(newTags, this.props.id);
            return {
                editInputIndex: -1,
                editInputValue: '',
            };
        });
    };

    saveInputRef = input => {
        console.log("saveInputRef");
        this.input = input;
    };

    saveEditInputRef = input => {
        console.log("saveEditInputRef");
        this.editInput = input;
    };

    sortTags(tags) {
        return tags.sort((a,b) => { return a - b });
    }

    isUserControllable(tag) {
        return true;
    }

    render() {
        const { inputVisible, inputValue, editInputIndex, editInputValue } = this.state;

        var sortedTags = this.sortTags(this.props.availableTags);
        var tagsList = sortedTags.map(function(tag: any){
            return <Option key={tag.name} value={tag.name}>{tag.name}</Option>;
        });

        let tags = this.props.tags;
        if (!tags) {
            tags = [];
        }

        return (
        <>
            {tags.map((tag, index) => {
            if (editInputIndex === index) {
                return (
                <Select
                    ref={this.editInput}
                    key={tag}
                    size="small"
                    className="tag-input"
                    value={editInputValue}
                    onChange={this.handleEditInputChange}
                    onBlur={this.handleEditInputConfirm}
                >{tagsList}
                </Select>
                );
            }

            const isLongTag = tag.length > 20;

            const tagElem = (
                <Tag
                className="edit-tag"
                key={tag}
                closable={this.isUserControllable(tag)}
                onClose={() => this.handleClose(tag)}
                >
                <span
                    onDoubleClick={e => {
                    if (index !== 0) {
                        this.setState({ editInputIndex: index, editInputValue: tag }, () => {
                        this.editInput.current.focus();
                        });
                        e.preventDefault();
                    }
                    }}
                >
                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </span>
                </Tag>
            );
            return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                {tagElem}
                </Tooltip>
            ) : (
                tagElem
            );
            })}
            {inputVisible && (
            <Select
                ref={this.input}
                size="small"
                className="tag-input"
                value={inputValue}
                onChange={this.handleInputChange}
                onBlur={this.handleInputConfirm}
            >{tagsList}
            </Select>
            )}
            {!inputVisible && (
            <Tag className="site-tag-plus" onClick={this.showInput}>
                <PlusOutlined /> New Tag
            </Tag>
            )}
        </>
        );
    }
}

export default EditableStudentTagsComponent;