import * as React from 'react';
import {Fragment} from 'react';

/** Components */
import AppRouter from '../Navigation/Routes';

import {useInitStore} from "../Store/UserStore";
import {ConfigProvider, theme} from "antd";
import 'antd/dist/reset.css';
import {colors} from "../Themes/Colors";
//import "antd/dist/antd.css";
//import "../assets/styles/main.css";
//import "../assets/styles/responsive.css";

function App() {

    const initUserStore = useInitStore();
    initUserStore();

    return (
        <Fragment>
            <ConfigProvider
                theme={{
                    algorithm: theme.compactAlgorithm,
                    // token: {
                    //     colorPrimary: colors.primary,
                    // },
                    components: {
                        Menu: {
                            colorItemBg: colors.background,
                            colorSubItemBg: colors.background,
                        }
                    },
                }}
            >
                <AppRouter/>
            </ConfigProvider>
        </Fragment>
    )
};

export default App;
