import React, {useEffect, useState} from 'react';
import {notification, Button} from 'antd';
import {PageHeader} from '@ant-design/pro-layout';
import {PlusOutlined} from '@ant-design/icons';
import {Family} from "../../../Model/enrollmentModel";
import FamilyTableContainer from '../../Components/Family/FamilyTableComponent';
import {decorateFamily} from '../../../Utils/helpers';
import {getFamilies} from "../../../Services/familiesService";
import {createNewFamily} from "../../../Utils/familyUtils";
import {createNewStudent} from "../../../Utils/studentUtils";
import FamilyDrawerController from "../../Components/Family/FamilyDrawerController";
import {useSchoolYearId, useUser} from "../../../Store/UserStore";
import AdminDashboardContentCard from "../../../Components/Styled/AdminDashboardContentCard";

function FamiliesContainer() {
    console.log(`constructing FamiliesContainer`);
    const user = useUser();
    const schoolYearId = useSchoolYearId();
    const [families, setFamilies] = useState<Family[]>([]);
    const [familyDrawerOpen, setFamilyDrawerOpen] = useState(false);
    const [isNewFamily, setIsNewFamily] = useState(false);
    const [curFamily, setCurFamily] = useState<Family | null>(null);

    useEffect(
        () => {
            getFamilies(user.clientConfig.clientId, schoolYearId).then((families: Family[]) => {
                setFamilies(families);
            }).catch(error => {
                console.log(`error:${error}`);
                notification.error({
                    message: 'Error',
                    description: `Unable to load families.`, //TODO handle error appropriately
                    placement: 'topRight',
                    duration: 1.5
                });
            });
        },
        [user.clientConfig, schoolYearId]
    );

    let onFamilySelected = (family) => {
        console.log(`onFamilySelected: ${family}`)
        setCurFamily(family);
        setIsNewFamily(false);
        setFamilyDrawerOpen(true);
    }

    let showFamilyDrawer = () => {
        setCurFamily(createNewFamilyShell());
        setIsNewFamily(true);
        setFamilyDrawerOpen(true);
    };

    let onFamilyDrawerClose = () => {
        setCurFamily(null);
        setIsNewFamily(false);
        setFamilyDrawerOpen(null);
    }

    let onFamilyDrawerSubmit = (updatedFamily: Family) => {
        notification.success({
            message: isNewFamily ? 'Family Created!' : 'Family Updated!',
            placement: 'topRight',
            duration: 1.5
        });

        decorateFamily(updatedFamily);
        let updatedFamilies = [];
        if (!isNewFamily) {
            families.forEach(family => {
                if (family.id !== updatedFamily.id) {
                    updatedFamilies.push(family);
                } else {
                    updatedFamilies.push(updatedFamily);
                }
            });
        }

        setFamilyDrawerOpen(false);
        setIsNewFamily(false);
        setCurFamily(null);
        setFamilies(updatedFamilies);
    }

    let onFamilyDeleted = () => {
        notification.success({
            message: 'Family Deleted!',
            placement: 'topRight',
            duration: 1.5
        });

        let updatedFamilies = [];
        families.forEach(family => {
            if (family.id !== curFamily.id) {
                updatedFamilies.push(family);
            }
        });

        setFamilyDrawerOpen(false);
        setIsNewFamily(false);
        setCurFamily(null);
        setFamilies(updatedFamilies);
    }

    let createNewFamilyShell = () => {
        let newFamily = createNewFamily(schoolYearId, user.clientConfig);
        newFamily.students.push(createNewStudent(schoolYearId));
        return newFamily;
    }


    return (
        <AdminDashboardContentCard>
            <PageHeader
                ghost={true}
                title="Families"
                subTitle="" //TODO set this to something meaningful
            >
                <FamilyDrawerController
                    clientId={user.clientConfig.clientId}
                    family={curFamily}
                    visible={familyDrawerOpen}
                    isNewFamily={isNewFamily}
                    onDelete={onFamilyDeleted}
                    onClose={onFamilyDrawerClose}
                    onSubmit={onFamilyDrawerSubmit}
                />
                <Button type="primary" onClick={showFamilyDrawer}>
                    <PlusOutlined/> New family
                </Button>
            </PageHeader>
            <FamilyTableContainer families={families} onFamilySelected={onFamilySelected}/>
        </AdminDashboardContentCard>
    );
}

export default FamiliesContainer;