/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "66f3bacf2cea44499ff8ce54936f01fc",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "66f3bacf2cea44499ff8ce54936f01fc",
            "region": "us-west-2"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://bv0c2rm3kh.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "enrollmentApi",
            "endpoint": "https://yygs8ta1ni.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:f2eee612-18ea-4493-8db1-a81a6fc72ce2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_nEPuWczRS",
    "aws_user_pools_web_client_id": "2m7jlaq5d2hqmd6tj0956549am",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "enrollmentDb-prod",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
