import React from "react";
import logo from "../../../assets/images/logo-v2/EnrollWithIt Main Logo 800x240.jpg";
import logoSymbol from "../../../assets/images/logo-v2/symbol/EnrollWithIt Logo Symbol 800x600.jpg";

interface Props {
    collapsed: boolean;
}

function AdminLogo(props: Props) {
    return (
        <React.Fragment >
            <img src={props.collapsed ? logoSymbol : logo} alt="EnrollWithIt" style={{ width: "100%", height: "auto" }} />
        </React.Fragment>
    )
}

export default AdminLogo