import {Family, Student} from "../../../Model/enrollmentModel";
import {Store} from "rc-field-form/es/interface";
import {useEffect, useState} from "react";
import authManager from "../../../Auth/authManager";
import {decorateFamily} from "../../../Utils/helpers";
import {notification} from "antd";
import FamilyDrawerForm from "./FamilyDrawerComponent";
import {getFamily, saveFamily} from "../../../Services/familiesService";
import {useSchoolYearId} from "../../../Store/UserStore";
const cloneDeep = require('lodash.clonedeep');

interface Props {
    clientId: string;
    family?: Family;
    familyId?: string;
    updatedStudent?: Student;
    visible: boolean;
    isNewFamily: boolean;
    onDelete: () => void;
    onClose: () => void;
    onSubmit: (value: Store) => void;
}

function FamilyDrawerController(props: Props) {
    console.log(`FamilyDrawerController constructed`);
    const schoolYearId = useSchoolYearId();
    const [ curFamily, setCurFamily ] = useState<Family | null>(null);

    useEffect(() => {
        if (props.family) {
            setCurFamily(cloneDeep(props.family));
        } else if (props.familyId) {
            console.log(`loadFamily familyId:${props.familyId} schoolYearId:${schoolYearId}`);

            getFamily(props.clientId, props.familyId, schoolYearId).then((family: Family) => {
                console.log("FamilyDrawerController.applyUpdatedStudents");
                if (family && props.updatedStudent) {

                    family.students.forEach(function(curStudent, i) {
                        if (curStudent.id && curStudent.id.localeCompare(props.updatedStudent.id) === 0) {
                            family.students[i] = props.updatedStudent;
                        }
                    });
                }
                decorateFamily(family);
                setCurFamily(family);
            }).catch(() => {
                notification.error({
                    message: 'Error',
                    description: `Unable to load family.`, //TODO handle error appropriately
                    placement: 'topRight',
                    duration: 1.5
                });
            });
        }
    },[props.clientId, props.family, props.familyId, props.updatedStudent, schoolYearId]);


    function onSaveFamily(family: Family) {
        saveFamily(family).then(responseFamily => {
            console.log(`saveFamily responseFamily:${responseFamily}`);
            setCurFamily(responseFamily);
            props.onSubmit(responseFamily);
        }).catch(error => {
            console.log(error.response);
            notification.error({
                message: 'Error',
                description: 'Unable to save family.', //TODO handle error appropriately
                placement: 'topRight',
                duration: 1.5
            });
            setCurFamily(family);
            props.onSubmit(family);
        });

    }

    function deleteFamily() {
        console.log("FamilyDrawerComponent.deleteFamily");
        let family: Family = null;
        if (props.family) {
            family = props.family;
        } else {
            family = curFamily;
        }

        family.deletedInd = !family.deletedInd;
        console.log(family.deletedInd ? "Deleting Family" : "Restoring Family");

        authManager.apiPost("enrollmentApi", "/families", { body: family }).then(response => {
            console.log(response);
            props.onDelete();
        }).catch(error => {
            console.log(error.response);
            notification.error({
                message: 'Error',
                description: family.deletedInd ? "Unable to delete family." : "Unable to restore family.", //TODO handle error appropriately
                placement: 'topRight',
                duration: 1.5
            });
        });
    }

    return (
      <FamilyDrawerForm
        family={curFamily}
        visible={props.visible}
        isNewFamily={props.isNewFamily}
        onDelete={props.onDelete}
        onClose={props.onClose}
        onSubmit={onSaveFamily}
        deleteFamily={deleteFamily}
        setFamily={setCurFamily}
      />
    );
}

export default FamilyDrawerController;