export const colors = {
  black: '#000000',
  white: '#ffffff',
  grey: '#ebedf0',
  lightgrey: '#F5F5F5',
  transparentBlack: 'rgba(0,0,0,.25)',

  //Theme colors
  // Logo v1
  // background: '#073763',
  // primary: '#eb9225',
  // primary2: '#257EEB',
  // secondary: '#ce3c24',
  // tertiary: '#69523d',

  // Logo v2
  background: '#173058',
  primary: '#d5885c',
  primary2: '#257EEB',
  secondary: '#f7f8f6',
  tertiary: '#6b545a',
};
