import {Col, Input, Row, Table} from "antd";
import React, {useEffect, useState} from "react";
import {findPaths} from "../Utils/objectSearchUtil";
import {SearchOutlined} from "@ant-design/icons";

interface Props<Type> {
    dataSource: Type[];
    excludedKeys: string[];
    columns: any[];
    [prop: string]: any;
}

function SearchableTableComponent<Type>(props: Props<Type>) {
    const [filteredDataSource, setFilteredDataSource] = useState<any[]>([]);
    const [ searchString, setSearchString ] = useState<string | null>(null);
    const { dataSource, excludedKeys, columns, ...otherProps } = props;

    function onSearchChange(e) {
        setSearchString(e.target.value);
    }

    useEffect(() => {
        let filteredData: Type[];
        if (searchString && searchString.length > 0) {
            filteredData = dataSource.filter(record => {
                return findPaths(record, searchString, excludedKeys).length > 0;
            });
        } else {
            filteredData = dataSource;
        }

        setFilteredDataSource(filteredData);
    },[searchString, dataSource, excludedKeys]);

    return (
        <React.Fragment>
            <Table
                dataSource={filteredDataSource}
                columns={columns}
                {...otherProps}
                title={() => <Row><Col span={8}><Input
                    placeholder="Search"
                    onChange={onSearchChange}
                    prefix={<SearchOutlined />}
                /></Col><Col span={16}/></Row> }
            />
        </React.Fragment>
    );
}

export default SearchableTableComponent;