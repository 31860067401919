import React, {useState} from 'react';
import { Form, Select, Button, Row, Col, Spin, notification } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { LoadingOutlined } from '@ant-design/icons';
import SettingsFormWrapper from '../../../Components/Styled/SettingsFormWrapper';
import { Store } from 'rc-field-form/es/interface';
import {rolloverWaitlist} from "../../../Services/EnrollmentPeriodsService";
import {useUser} from "../../../Store/UserStore";
import {EnrollmentPeriod} from "../../../Model/enrollmentModel";
import AdminDashboardContentCard from "../../../Components/Styled/AdminDashboardContentCard";

const { Option } = Select;



function RolloverWaitlistContainer() {
    const user = useUser();
    const [rolloverRunning, setRolloverRunning] = useState(false);

    var schoolYearList = user.clientConfig.enrollmentPeriodConfig.enrollmentPeriods.map(function(enrollmentPeriod: EnrollmentPeriod){
        return <Option key={enrollmentPeriod.id} value={enrollmentPeriod.id}>{enrollmentPeriod.id}</Option>;
    });

    let handleFinish = (values: Store) => {
        console.log("RolloverWaitlistContainer.handleFinish");
        console.log(`values:${values}`);
        handleRolloverWaitlist(values['sourceEnrollmentPeriod'], values['destinationEnrollmentPeriod']);
    };

    let handleRolloverWaitlist = (sourceSchoolYear: string, destinationSchoolYear: string) => {
        console.log(`rolloverWaitlist sourceEnrollmentPeriod:${sourceSchoolYear} destinationEnrollmentPeriod:${destinationSchoolYear}`);
        rolloverWaitlist(user.clientConfig.clientId, sourceSchoolYear, destinationSchoolYear).then(response => {
        console.log(`rolloverWaitlist result:${response}`);
        setRolloverRunning(false);
        notification.success({
            message: 'Success',
            description: 'Waitlist successfully rolled over.',
            placement: 'topRight',
            duration: 1.5
        });
    }).catch(error => {
        console.log(`rolloverWaitlist error:${error}`);
        notification.error({
            message: 'Error',
            description: 'Unable to rollover waitlist.',
            placement: 'topRight',
            duration: 1.5
        });
    });
  }

  return (
      <AdminDashboardContentCard>
            <PageHeader
                ghost={true}
                title="Rollover Waitlist"
                subTitle="Rollover the waitlist from one year to another"
            >
                <SettingsFormWrapper
                    size="middle"
                    onFinish={handleFinish}
                    scrollToFirstError
                    requiredMark={false}
                    layout="vertical"
                >
                    <Form.Item
                        name="sourceEnrollmentPeriod"
                        label="Source Enrollment Period"
                        rules={[{ required: true, message: 'Please enter a source enrollment period.' }]}
                    >
                        <Select style={{ width: '25%' }}>
                          {schoolYearList}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="destinationEnrollmentPeriod"
                        label="Destination Enrollment Period"
                        rules={[{ required: true, message: 'Please enter a destination enrollment period.' }]}
                    >
                        <Select style={{ width: '25%' }}>
                        {schoolYearList}
                        </Select>
                    </Form.Item>
                    <Form.Item className="text-center">
                        <Row gutter={8}>
                        <Col span={3}>
                            <Button style={{ width: '100%' }} type="primary" disabled={rolloverRunning} htmlType="submit">
                            {rolloverRunning ? <Spin indicator={<LoadingOutlined type="loading" style={{ fontSize: 24 }} spin />} /> : 'Rollover'}
                            </Button>
                        </Col>
                        </Row>
                    </Form.Item>
                </SettingsFormWrapper>
            </PageHeader>
        </AdminDashboardContentCard>
    );
}

export default RolloverWaitlistContainer;