import * as React from 'react';
import {Link, useNavigate, Navigate} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {Form, Spin, Input, Button, notification, Col, Row, Layout} from 'antd';

/** Presentational */
import FormWrapper from '../../../Components/Styled/FormWrapper';

/** App theme */
import { colors } from '../../../Themes/Colors';

/** App constants */
import { UserOutlined, LoadingOutlined, LockOutlined } from '@ant-design/icons';
import { Store } from 'rc-field-form/es/interface';
import authManager from '../../../Auth/authManager';
import MainFooter from "../../../Components/MainFooter";
import MainHeader from "../../../Components/MainHeader";
import {mainContentStyle} from "../../../Themes/Styles";
import {useState} from "react";
import {useLoadUser} from "../../../Store/UserStore";

const { Content } = Layout;

function LoginContainer() {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  let loadUser = useLoadUser();

  let handleFinish = (values: Store) => {
    console.log("login container: authenticating");
    let { username, password } = values;
    setLoading(true);

    Auth.signIn(username, password)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          console.log("login container: new password required");
          loadUser(false, user)
          navigate("/update-password");
        } else {
          console.log("Login successful, redirecting to dashboard");
          notification.success({
            message: 'Succesfully logged in!',
            description: 'Logged in successfully, Redirecting you in a few!',
            placement: 'topRight',
            duration: 1.5
          });
          navigate("/dashboard");
        }
      })
      .catch(err => {
        notification.error({
          message: 'Unable to login',
          description: err.message,
          placement: 'topRight'
        });

        console.log(err);
        setLoading(false);
      });
    };

    if (authManager.isAuthenticated()) {
      console.log("login container: authenticated, redirecting to dashboard");
      return <Navigate to={"/dashboard"}/>
    } else {
      console.log("login container: not authenticated");
    }

    return (

      <React.Fragment>
        <Layout>
          <MainHeader/>
          <Content>
            <FormWrapper onFinish={handleFinish} className="login-form" style={mainContentStyle}>
              <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
                  <Input prefix={<UserOutlined style={{ color: colors.transparentBlack }} />} placeholder="Username" />
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input
                    prefix={<LockOutlined style={{ color: colors.transparentBlack }} />}
                    type="password"
                    placeholder="Password"
                />
              </Form.Item>
              <Form.Item className="text-center">
                <Row gutter={16}>
                  <Col lg={24}>
                    <Link style={{ float: 'right' }} className="login-form-forgot" to="/forgot-password">
                      Forgot password
                    </Link>
                  </Col>
                  <Col lg={24}>
                    <Button
                      style={{ width: '100%' }}
                      type="primary"
                      disabled={loading}
                      htmlType="submit"
                      className="login-form-button"
                      id="admin-portal-login-button"
                    >
                      {loading ? <Spin indicator={<LoadingOutlined type="loading" style={{ fontSize: 24 }} spin />} /> : 'Log in'}
                    </Button>
                  </Col>
                  <Col lg={24}>
                    Or <Link to="/signup">register now!</Link>
                  </Col>
                </Row>
              </Form.Item>
            </FormWrapper>
          </Content>
          <MainFooter/>
        </Layout>
      </React.Fragment>
    );
}

export default LoginContainer;