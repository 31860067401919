import React, {useEffect, useState} from 'react';
import {Col, Collapse, Divider, notification, Row} from 'antd';
import {PageHeader} from '@ant-design/pro-layout';
import {Student, Waitlists} from "../../../Model/enrollmentModel";
import StudentDrawerForm from '../../Components/Student/StudentDrawerComponent';
import WaitlistStatusTable from '../../Components/Waitlist/WaitlistStatusTable';
import WaitlistOverviewTable from "./WaitlistOverviewTable";
import {getWaitlistForGrade, getWaitlists} from "../../../Services/waitlistService";
import {useSchoolYearId, useUser} from "../../../Store/UserStore";
import AdminDashboardContentCard from "../../../Components/Styled/AdminDashboardContentCard";

const {Panel} = Collapse;

function WaitlistOverviewContainer() {
    console.log(`WaitlistOverviewContainer.render`);
    const user = useUser();
    const schoolYearId = useSchoolYearId();
    const [waitlists, setWaitlists] = useState<Waitlists | null>(null);
    const [waitlist, setWaitlist] = useState<Student[]>([]);
    const [curStudent, setCurStudent] = useState<Student | null>(null);
    const [grade, setGrade] = useState<string>('K');
    const [studentDrawerOpen, setStudentDrawerOpen] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<string[]>([]);

    useEffect(
        () => {
            console.log(`WaitlistOverviewContainer.useEffect for clientId:${user.clientConfig.clientId} schoolYearId:${JSON.stringify(schoolYearId)} grade:${grade}`)
            getWaitlists(user.clientConfig.clientId, schoolYearId).then((waitlists: Waitlists) => {
                setWaitlist(waitlists[grade]);
                setWaitlists(waitlists);
            }).catch(error => {
                console.log(`error:${error}`);
                notification.error({
                    message: 'Error',
                    description: `Unable to load waitlist.`, //TODO handle error appropriately
                    placement: 'topRight',
                    duration: 1.5
                });
            });
        },
        [user, schoolYearId, grade]
    );

    let loadWaitlistForGrade = (grade) => {
        console.log(`WaitlistOverviewContainer.loadWaitlistForGrade for clientId:${user.clientConfig.clientId} schoolYearId:${schoolYearId} grade:${grade}`)
        getWaitlistForGrade(user.clientConfig.clientId, schoolYearId, grade).then((waitlist: Student[]) => {
            waitlists[grade] = waitlist;

            //TODO update stats
            setWaitlist(waitlist);
            setWaitlists(waitlists);
        }).catch(error => {
            console.log(`error:${error}`);
            notification.error({
                message: 'Error',
                description: `Unable to load waitlist.`, //TODO handle error appropriately
                placement: 'topRight',
                duration: 1.5
            });
        });
    };

    function onStudentSelected(student) {
        setCurStudent(student);
        setStudentDrawerOpen(true);
    }

    let onStudentDrawerClose = () => {
        setCurStudent(null);
        setStudentDrawerOpen(false);
    }

    let onStudentDrawerSubmit = (updatedStudent: Student) => {
        notification.success({
            message: 'Student Updated!',
            placement: 'topRight',
            duration: 1.5
        });

        loadWaitlistForGrade(updatedStudent.grade);
        setStudentDrawerOpen(false);
        setCurStudent(null);
    }

    function onFamilyDeleted() {
        notification.success({
            message: 'Family Deleted!',
            placement: 'topRight',
            duration: 1.5
        });

        let updatedWaitlist = [];
        waitlist.forEach(student => {
            if (student.familyId !== curStudent.familyId) {
                updatedWaitlist.push(student);
            }
        });
        setStudentDrawerOpen(false);
        setCurStudent(null);
        setWaitlist(updatedWaitlist);
    }

    function onWaitlistTableClick(grade, status) {
        console.log(`onWaitlistTableClick grade:${grade} status:${status}`);
        let updatedStatusFilter = null;
        if (status) {
            updatedStatusFilter = [];
            updatedStatusFilter.push(status);
            if (status === 'Withdrawn') {
                updatedStatusFilter.push("Withdrawn - missed deadline");
            }
        }
        setGrade(grade);
        setWaitlist(waitlists[grade]);
        setStatusFilter(updatedStatusFilter);
    }

    return (
        <AdminDashboardContentCard>
            <Row>
                <Col span={24}>
                    <PageHeader
                        ghost={true}
                        title="Waitlist"
                        subTitle={`Enrollment Waitlist for the ${schoolYearId} school year`}
                    >
                    </PageHeader>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Row>
                        {waitlists &&
                            <Collapse defaultActiveKey={["waitlistStatusTable"]}>
                                <Panel header="Waitlist Overview" key="waitlistStatusTable">
                                    <WaitlistStatusTable waitlistStatistics={waitlists.waitlistStatistics}
                                                         onWaitlistTableClick={onWaitlistTableClick}
                                                         clientConfig={user.clientConfig}
                                    />
                                </Panel>
                            </Collapse>}
                    </Row>
                </Col>
            </Row>
            <Row>
                <StudentDrawerForm
                    student={curStudent}
                    clientConfig={user.clientConfig}
                    visible={studentDrawerOpen}
                    onDelete={onFamilyDeleted}
                    onClose={onStudentDrawerClose}
                    onSubmit={onStudentDrawerSubmit}
                    schoolYearId={schoolYearId}
                />
            </Row>
            <Divider plain/>
            <WaitlistOverviewTable waitlist={waitlist} onStudentSelected={onStudentSelected}
                                   statusFilter={statusFilter}/>
        </AdminDashboardContentCard>
    );
}

export default WaitlistOverviewContainer;