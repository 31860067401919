
import * as React from 'react';
import { Col, Row, Checkbox, List } from 'antd';
import { Agreement } from '../Model/enrollmentModel';

export interface AgreementsConfirmValue {
    allAccepted: boolean;
  }
  
export interface AgreementsConfirmProps {
    value?: AgreementsConfirmValue;
    agreements: Agreement[];
    allAccepted: boolean;
    onChange?: (value: AgreementsConfirmValue) => void;
  }
  
export const AgreementsConfirm: React.FC<AgreementsConfirmProps> = ({ agreements, value = {}, onChange }) => {
  
    const triggerChange = changedValue => {
      if (onChange) {
        onChange({ ...value, ...changedValue });
      }
    };
  
    const onCheckboxChange = e => {
      triggerChange({ allAccepted: e.target.checked });
    };
  
    return (
        <Row>
            <Col span={24}>
                <List
                    size="small"
                    header={<div>Terms and Conditions</div>}
                    footer={<Checkbox
                        id="terms-and-conditions-confirm"
                        onChange={onCheckboxChange}
                        style={{ width: "100%" }}
                    >I agree to the terms and conditions</Checkbox>}
                    bordered
                    dataSource={agreements}
                    renderItem={(item: Agreement) => <List.Item>{item.agreementText}</List.Item>}
                />
            </Col>
        </Row>
    );
};