import {Form, Input} from "antd";
import * as React from "react";
import {useUser} from "../../../Store/UserStore";

function ClientConfigAdminSettings() {
    const user = useUser();

    if (user.isAdmin) {
        return (<React.Fragment>
            <Form.Item
                name={['enrollmentApplicationConfig','confirmationEmailConfig','templateName']}
                label="Confirmation Email Template">
                <Input/>
            </Form.Item>
            <Form.Item
                name={['enrollmentApplicationConfig','confirmationEmailConfig','senderEmail']}
                label="Confirmation Sender Email">
                <Input/>
            </Form.Item>
            <Form.Item
                name="districtId"
                label="District ID">
                <Input/>
            </Form.Item>
            <Form.Item
                name="logStudentIds"
                label="logStudentIds (for sortForWaitlistOrder)">
                <Input/>
            </Form.Item>
        </React.Fragment>)
    } else {
        return <React.Fragment/>
    }
}

export default ClientConfigAdminSettings;