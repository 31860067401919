import authManager from "../Auth/authManager";
import {ClientConfig, EnrollmentApplicationConfig} from "../Model/enrollmentModel";
import {API} from "aws-amplify";

const apiName = "enrollmentApi";
const clientConfigPath = '/clientConfig';
const enrlAppPath = '/applications/config';

export async function getClientConfig(clientId: string): Promise<ClientConfig> {
    console.log(`getClientConfig`);
    const myInit = {}
    return await authManager.apiGet<ClientConfig>(apiName, `${clientConfigPath}/${clientId}`, myInit);
}


export async function updateClientConfig(clientConfig: ClientConfig): Promise<any> {
    console.log(`updateClientConfig`);
    const myInit = {
        body: clientConfig,
    }
    return await authManager.apiPut(apiName, `${clientConfigPath}/${clientConfig.clientId}`, myInit);
}

export async function createClientConfig(clientConfig: ClientConfig): Promise<any> {
    console.log(`createClientConfig`);
    const myInit = {
        body: clientConfig,
    }
    return await authManager.apiPost(apiName, clientConfigPath, myInit);
}

export async function getEnrollmentApplicationConfig(clientFriendlyId): Promise<EnrollmentApplicationConfig> {
    //load client enrollment config
    const requestPath = `${enrlAppPath}?clientFriendlyId=${clientFriendlyId}`;
    const myInit = {}
    return await API.get(apiName, requestPath, myInit);
}