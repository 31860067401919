
import * as React from 'react';
import { Select, Input, Col, Row } from 'antd';
import {useState} from "react";
import {PhoneNumberType} from "../Model/enrollmentModel";
const { Option } = Select;

export interface PhoneNumberValue {
    number?: string;
    type?: PhoneNumberType
    ;
  }
  
export interface PhoneNumberInputProps {
    value?: PhoneNumberValue;
    onChange?: (value: PhoneNumberValue) => void;
    id: string;
}
  
export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ value = {}, onChange, id }) => {
    const [number, setNumber] = useState();
    const [type, setType] = useState(PhoneNumberType.Mobile);
  
    const triggerChange = changedValue => {
      if (onChange) {
        console.log(`PhoneNumberInput calling onChange number:${number} type:${type} value:${JSON.stringify(value)} changedValue:${JSON.stringify(changedValue)}`);

        onChange({ number, type, ...value, ...changedValue });
      }
    };
  
    const onNumberChange = e => {
        const newNumber = e.target.value;
      //console.log(`onNumberChange:${newNumber}`);
      //console.log(`value:${JSON.stringify(value)}`);
      setNumber(newNumber);
      
      
      /*if (newNumber && newNumber.length > 0) {
        if (/^\d+$/.test(newNumber)) {
            setNumber(newNumber);
            triggerChange({ number: newNumber });
        }
      } else {
          setNumber(newNumber);
          triggerChange({ number: newNumber });
      }*/
      
      /*if (!('number' in value) || !value.number) {
        console.log(`setting number`)
        setNumber(newNumber);
      } else {
        console.log(`not setting number`)
      }*/
      triggerChange({ number: newNumber });
    };
  
    const onTypeChange = newType => {
      if (!('type' in value)) {
        setType(newType);
      }
      triggerChange({ type: newType });
    };
  
    return (
        <Row>
            <Col span={8}>
                <Select
                value={value.type || type}
                style={{ width: "100%" }}
                onChange={onTypeChange}
                >
                    <Option key={1} value={1}>Mobile</Option>
                    <Option key={2} value={2}>Home</Option>
                    <Option key={3} value={3}>Work</Option>
                </Select>
            </Col>
            <Col span={16}>
                <Input
                    id={id}
                    type="text"
                    value={value.number || number}
                    onChange={onNumberChange}
                    style={{ width: "100%" }}
                />
            </Col>
        </Row>
    );
};