import {Button, Card, Col, DatePicker, Form, Input, Row, Select, Space} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import EditableStudentTagsComponent from "../../../Components/EditableStudentTagComponent";
import {getSelectableStudentTags, sortGrades, sortStudentStatuses} from "../../../Utils/helpers";
import React from "react";
import {ClientConfig, Family, Student} from "../../../Model/enrollmentModel";
import {createNewStudentForFamily} from "../../../Utils/studentUtils";

const { Option } = Select;

interface Props {
    clientConfig: ClientConfig;
    family: Family;
    setFamily: (family: Family) => void;
}

function StudentsPanel(props: Props) {
    const { clientConfig, family, setFamily } = props;

    var sortedGrades = sortGrades(clientConfig.grades);
    var gradesList = sortedGrades.map(function(grade: any){
        return <Option key={grade} value={grade}>{grade}</Option>;
    });

    var sortedStatus = sortStudentStatuses(clientConfig.studentStatuses);
    var statusList = sortedStatus.map(function(status: any){
        return <Option key={status.code} value={status.code}>{status.code}</Option>;
    });

    function updateStudents(students: Student[]) {
        setFamily({...family, students: students});
    }

    function updateStudentTags(tags: string[], id: string) {
        console.log("StudentsPanel.updateStudentTags");

        let updatedStudents = family.students;
        updatedStudents.forEach(student => {
            if (student.id === id) {
                student.tags = tags;
            }
        });
        updateStudents(updatedStudents);
    }

    function addStudent() {
        console.log('addStudent');
        let student = createNewStudentForFamily(family);
        let updatedStudents = family.students ? family.students : [];
        updatedStudents.push(student);
        updateStudents(updatedStudents);
    }

    function removeStudent(index) {
        console.log(`removeStudent index:${index}`);
        if (family.students.length >= index) {
            let updatedStudents = family.students.splice(index,1);
            updateStudents(updatedStudents);
        }
    }

    return (
        <React.Fragment>
            <Form.List name="students_form_list">
                {(fields) => {
                    return (
                        <div>
                            {fields.map((field, index) => {
                                return (
                                    <Card key={field.key} size="small" style={{ marginBottom: 4, width:'100%', textAlign:'center' }}>
                                        <Space direction="vertical">
                                            <Row gutter={8}>
                                                <Col span={5}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'firstName']}
                                                        label={index === 0 ? 'First Name' : ''}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "First name required",
                                                            },
                                                        ]}
                                                    >
                                                        <Input style={{width:'100%'}}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'lastName']}
                                                        label={index === 0 ? 'Last Name' : ''}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                    >
                                                        <Input style={{width:'100%'}}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'gender']}
                                                        label={index === 0 ? 'Gender' : ''}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                    >
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            options={[
                                                                {
                                                                    value: '',
                                                                    label: '',
                                                                },
                                                                {
                                                                    value: 'F',
                                                                    label: 'F',
                                                                },
                                                                {
                                                                    value: 'M',
                                                                    label: 'M',
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'birthDate']}
                                                        label={index === 0 ? 'Birthdate' : ''}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                    >
                                                        <DatePicker style={{width:'100%'}}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'grade']}
                                                        label={index === 0 ? 'Grade' : ''}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                    >
                                                        <Select style={{width:'100%'}}>
                                                            { gradesList }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'status']}
                                                        label={index === 0 ? 'Status' : ''}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                    >
                                                        <Select style={{width:'100%'}}>
                                                            { statusList }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}>
                                                    {fields.length > 1 ? (
                                                        <Form.Item
                                                            {...field}
                                                            label={index === 0 ? ' ' : ''}
                                                            style={{ width: '100%' }}
                                                            required={false}
                                                        >
                                                            <MinusCircleOutlined
                                                                className="dynamic-delete-button"
                                                                style={{ margin: '0 8px' }}
                                                                onClick={() => {
                                                                    removeStudent(field.key);
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <Row gutter={4}>
                                                {(family.students) ?
                                                    <EditableStudentTagsComponent
                                                        tags={family.students[index] ? family.students[index].tags : null}
                                                        availableTags={getSelectableStudentTags(clientConfig.studentTags)}
                                                        id={family.students[index] ? family.students[index].id : null}
                                                        updateStudentTags={updateStudentTags}/>
                                                    : ''
                                                }
                                            </Row>
                                        </Space>
                                    </Card>
                                )})}
                            <Row>
                                <Col span={18}/>
                                <Col span={6}>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            onClick={addStudent}
                                            style={{ width: '100%' }}
                                        >
                                            <PlusOutlined /> Add Student
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    );
                }}
            </Form.List>
            <Row>
                <Col span={24}>
                    <Form.Item
                        name="enrolledStudentsIds"
                        label="Other siblings"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
    </React.Fragment>
    );
}

export default StudentsPanel;