/** Auth user token key */
export const AUTH_USER_TOKEN_KEY = 'ReactAmplify.TokenKey';


//EnrollmentPeriodStatus codes
export const CLOSED_ENROLLMENT_PRE = "CLOSED_ENROLLMENT_PRE";
export const OPEN_ENROLLMENT_PRE_LOTTERY = "OPEN_ENROLLMENT_PRE_LOTTERY";
export const OPEN_ENROLLMENT_POST_LOTTERY = "OPEN_ENROLLMENT_POST_LOTTERY";
export const CLOSED_ENROLLMENT_POST = "CLOSED_ENROLLMENT_POST";

export const ENROLLED_STATUS = "Enrolled";
export const ACCEPTED_STATUS = "Accepted";
export const WAITLIST_STATUS = "Waitlist";
export const WITHDRAWN_STATUS = "Withdrawn";
export const DECLINED_STATUS = "Declined";
export const MISSED_DEADLINE_STATUS = "Missed Deadline";
export const DUPLICATE_STATUS = "Duplicate";