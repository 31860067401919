import {Form, Select} from "antd";
import * as React from "react";

const {Option} = Select;

function ClientConfigWaitlistSettings() {

    return (<React.Fragment>
        <Form.Item
            name={['waitlistConfig','postLotteryAppWaitlistPriority']}
            label="Waitlist priority for applications submitted after the lottery"
            rules={[{required: false}]}>
            <Select
                style={{width: '100%'}}
            >
                <Option key="STANDARD" value="STANDARD">Standard - Student tag priority applies
                    first</Option>
                <Option key="BOTTOM" value="BOTTOM">Bottom - Student tag priority ignored</Option>
            </Select>
        </Form.Item>
    </React.Fragment>)
}

export default ClientConfigWaitlistSettings