import styled from 'styled-components';
import { Form } from 'antd';

const SettingsFormWrapper = styled(Form)`
  max-width: 1000px;
  padding: 10px 10px 10px !important;
  //h2.text-align: center;
  //h3.text-align: center;
`;

export default SettingsFormWrapper;
