import * as React from 'react';
import AdminDashboardContentCard from "../../../Components/Styled/AdminDashboardContentCard";

function AdminHomePageContainer() {

  return (
    <AdminDashboardContentCard>
    </AdminDashboardContentCard>
  );
}

export default AdminHomePageContainer;