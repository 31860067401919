import {Menu} from "antd";
import {
    BarsOutlined,
    CalendarOutlined,
    HomeOutlined,
    LogoutOutlined,
    SearchOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import * as React from "react";

const {SubMenu} = Menu;

type Props = {
    selectedKeys: [string];
    isAdmin: boolean;
    handleLogout: (event: any) => void;
}

function AdminSideBarMenu(props: Props) {
    return (
        <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={props.selectedKeys}
        >
            {false && <Menu.Item key="/dashboard">
                <HomeOutlined/>
                <span>Home</span>
                <Link to="/dashboard"/>
            </Menu.Item>}
            <Menu.Item key="/waitlist">
                <BarsOutlined/>
                <span>Waitlist</span>
                <Link to="/waitlist"/>
            </Menu.Item>
            <SubMenu
                key="lookup"
                icon={<SearchOutlined/>}
                title="Lookup"
            >
                <Menu.Item key="/students">
                    <SearchOutlined/>
                    <span>Students</span>
                    <Link to="/students"/>
                </Menu.Item>
                <Menu.Item key="/families">
                    <SearchOutlined/>
                    <span>Families</span>
                    <Link to="/families"/>
                </Menu.Item>
                <Menu.Item key="/deletedFamilies">
                    <SearchOutlined/>
                    <span>Deleted Families</span>
                    <Link to="/deletedFamilies"/>
                </Menu.Item>
            </SubMenu>
            <Menu.Item key="/enrollmentPeriods">
                <CalendarOutlined/>
                <span>Enrollment Periods</span>
                <Link to="/enrollmentPeriods"/>
            </Menu.Item>
            <Menu.Item key="/settings">
                <SettingOutlined/>
                <span>Settings</span>
                <Link to="/settings"/>
            </Menu.Item>
            {props.isAdmin &&
                <Menu.Item key="/admin">
                    <SettingOutlined/>
                    <span>Admin</span>
                    <Link to="/admin"/>
                </Menu.Item>}
            {props.isAdmin &&
                <Menu.Item key="/rolloverWaitlist">
                    <SettingOutlined/>
                    <span>Rollover Waitlist</span>
                    <Link to="/rolloverWaitlist"/>
                </Menu.Item>}
            <Menu.Item key="/logout" onClick={props.handleLogout} id="admin-portal-menu-logout">
                <LogoutOutlined/>
                <span>Logout</span>
            </Menu.Item>
        </Menu>);
}

export default AdminSideBarMenu;