import {DatePicker, Form, Select, Steps, Switch} from "antd";
import {CaretRightOutlined, CheckOutlined, CloseOutlined, OrderedListOutlined, StopOutlined, RedoOutlined} from "@ant-design/icons";
import * as React from "react";

interface Props {

}

function onOk(value: any) {
    console.log('onOk: ', value);
}

function EnrollmentCreationReoccuringConfigComponent(props: Props) {
    return (
        <React.Fragment>
            <Form.Item
                name='frequency'
                label="Frequency"
                rules={[{required: false}]}>
                <Select
                    options={[{value: 'YEARLY', label: 'Yearly'}]}
                />
            </Form.Item>
            <Steps
                direction="vertical"
                items={[
                    {
                        title: 'Start',
                        icon: <CaretRightOutlined/>,
                        status: 'finish',
                        description: <Form.Item
                            name='startDate'
                            rules={[{required: false}]}>
                            <DatePicker
                                showTime={{format: 'HH:mm'}}
                                format="YYYY-MM-DD HH:mm"
                                use12Hours
                                onOk={onOk}
                            />
                        </Form.Item>,
                    },
                    {
                        title: 'Lottery',
                        icon: <OrderedListOutlined/>,
                        status: 'finish',
                        description: <Form.Item
                            name='lotteryDate'
                            rules={[{required: false}]}>
                            <DatePicker
                                showTime={{format: 'HH:mm'}}
                                format="YYYY-MM-DD HH:mm"
                                use12Hours
                                onOk={onOk}
                            />
                        </Form.Item>,
                    },
                    {
                        title: 'End',
                        icon: <StopOutlined/>,
                        status: 'finish',
                        description: <Form.Item
                            name='endDate'
                            rules={[{required: false}]}>
                            <DatePicker
                                showTime={{format: 'HH:mm'}}
                                format="YYYY-MM-DD HH:mm"
                                use12Hours
                                onOk={onOk}
                            />
                        </Form.Item>,
                    },
                    {
                        title: 'Rollover Waitlist',
                        icon: <RedoOutlined />,
                        status: 'finish',
                        description: <Form.Item
                            name='rolloverWaitlist'
                            label="Rollover Waitlist"
                            tooltip={"If enabled, waitlisted students will be rolled over to the next enrollment period."}
                            rules={[{required: false}]}>
                            <Switch
                                checkedChildren={<CheckOutlined/>}
                                unCheckedChildren={<CloseOutlined/>}
                                defaultChecked
                            />
                        </Form.Item>,
                    },
                ]}
            />
        </React.Fragment>
    )
}

export default EnrollmentCreationReoccuringConfigComponent;