import * as React from 'react';
import {Link, Navigate, useParams} from 'react-router-dom';
import {Form, Spin, Input, Button, notification, Col, Row, Typography, Layout} from 'antd';
import {CloseCircleOutlined} from '@ant-design/icons';

/** Presentational */
import FormWrapper from '../../../Components/Styled/FormWrapper';

/** App constants */
import {LoadingOutlined} from '@ant-design/icons';
import {Store} from 'rc-field-form/es/interface';
import MainFooter from "../../../Components/MainFooter";
import MainHeader from "../../../Components/MainHeader";
import {mainContentStyle} from "../../../Themes/Styles";
import {API} from "aws-amplify";
import {useState} from "react";

const {Content} = Layout;

const {Paragraph} = Typography;

function ApplicationLookupContainer() {
    const {clientFriendlyId} = useParams();
    const [loading, setLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<string | null>(null)
    const [confirmationNumber, setConfirmationNumber] = useState<string | null>(null)
    const [confirmationNumberConfirmed, setConfirmationNumberConfirmed] = useState<boolean>(false)
    const [lookupFailed, setLookupFailed] = useState<boolean>(false)

    const emailLookupEnabled = false;


    let handleFinish = (values: Store) => {
        const {confirmationNumber, email} = values;
        setLoading(true)
        console.log(`handleFinish clientFriendlyId:${clientFriendlyId} confirmationNumber:${confirmationNumber}`);

        let params = {
            body: {clientFriendlyId: clientFriendlyId, email: email, confirmationNumber: confirmationNumber}
        }

        API.post("enrollmentApi", "/applications/lookup", params).then((response: any) => {
            console.log(`/applications/lookup response: ${JSON.stringify(response)}`);
            if (response.exists) {
                if (confirmationNumber) {
                    setConfirmationNumberConfirmed(true)
                    setConfirmationNumber(confirmationNumber)
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            } else {
                setLookupFailed(true)
                setLoading(false)
            }
        }).catch(error => {
            console.log(`/applications/lookup error ${JSON.stringify(error)}`);
            notification.error({
                message: 'Error',
                description: 'Application Lookup not available at this time, please try again later',
                placement: 'topRight',
                duration: 1.5
            });
            setLoading(false)
        });
    };

    function validateInput(rule, value) {
        console.log(`validateInput:${JSON.stringify(value)}`)
        if (email || confirmationNumber) {
            return Promise.resolve();
        }
        return Promise.reject(emailLookupEnabled ? 'Either confirmation number or email required' : 'Confirmation number required');
    }

    function updateConfirmationNumber(e) {
        setConfirmationNumber(e.target.value)
    }

    function updateEmail(e) {
        setEmail(e.target.value)
    }

    return (
        <React.Fragment>
            <Layout style={{height: '100vh'}}>
                <MainHeader/>
                <Content>
                    <FormWrapper
                        onFinish={handleFinish}
                        className="login-form"
                        layout="vertical"
                        style={mainContentStyle}>
                        <Row>
                            <Col span={24}>
                                <h2>Lookup existing application</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="confirmationNumber"
                                    label="Confirmation Number"
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{message: 'Enter number to lookup'}, {validator: validateInput}]}>
                                    <Input onChange={updateConfirmationNumber}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        {emailLookupEnabled &&
                            <div>
                                <h4>OR</h4>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name="email"
                                            label="Email"
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[{message: 'Enter number to lookup'}, {validator: validateInput}]}>
                                            <Input onChange={updateEmail}/>
                                        </Form.Item>
                                    </Col>
                                </Row></div>}
                        <Row>
                            <Col span={24}>
                                <Form.Item className="text-center">
                                    <Row gutter={16}>
                                        <Col lg={24}>
                                            <Button
                                                id={"lookup"}
                                                style={{width: '100%'}}
                                                type="primary"
                                                disabled={loading}
                                                htmlType="submit"
                                                className="login-form-button"
                                            >
                                                {loading ? <Spin
                                                    indicator={<LoadingOutlined type="loading" style={{fontSize: 24}}
                                                                                spin/>}/> : 'Lookup'}
                                            </Button>
                                        </Col>
                                        <Col lg={24}>
                                            Or <Link id="enrollment-application" to={`/${clientFriendlyId}/enroll`}>Submit
                                            a new application</Link>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                        {lookupFailed &&
                            <Row>
                                <Col span={24}>
                                    <Paragraph>
                                        <CloseCircleOutlined style={{color: "red", margin: '0 8px'}}/>No application
                                        found
                                    </Paragraph>
                                </Col>
                            </Row>}
                    </FormWrapper>
                </Content>
                <MainFooter/>
            </Layout>
            {confirmationNumberConfirmed && (
                <Navigate
                    to={`/${clientFriendlyId}/application/status?confirmationNumber=${confirmationNumber}`}
                />
            )}
        </React.Fragment>
    );
}

export default ApplicationLookupContainer;