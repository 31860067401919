import * as React from 'react';
import {Tag} from 'antd';
import {ClockCircleOutlined, StopOutlined} from "@ant-design/icons";

interface Props {
    value?: string
    onChange?: (value: string) => void;
}

function EnrollmentPeriodStatusTag(props: Props) {
    let color = 'default';
    let label = 'default';
    let icon = <></>;
    if (props.value === 'CLOSED_ENROLLMENT_PRE') {
        color = 'purple';
        label = 'Not Open';
    } else if (props.value === 'OPEN_ENROLLMENT_PRE_LOTTERY') {
        color = 'volcano';
        label = 'Open (pre-lottery)';
        icon = <ClockCircleOutlined />;
    } else if (props.value === 'OPEN_ENROLLMENT_POST_LOTTERY') {
        color = 'orange';
        label = 'Open (post-lottery)';
        icon = <ClockCircleOutlined />;
    } else if (props.value === 'CLOSED_ENROLLMENT_POST') {
        color = 'blue';
        label = 'Closed';
        icon = <StopOutlined />;
    }
    return (
        <Tag
            color={color}
            key={props.value}
            icon={icon}
        >
            {label}
        </Tag>
    );
}

export default EnrollmentPeriodStatusTag;

/*

    CLOSED_ENROLLMENT_PRE = "CLOSED_ENROLLMENT_PRE",
    OPEN_ENROLLMENT_PRE_LOTTERY = "OPEN_ENROLLMENT_PRE_LOTTERY",
    OPEN_ENROLLMENT_POST_LOTTERY = "OPEN_ENROLLMENT_POST_LOTTERY",
    CLOSED_ENROLLMENT_POST = "CLOSED_ENROLLMENT_POST"
 */