/**
 * TODO
 *
 * 1) phone number input as dynamic list where user can specify type and primary and add additional numbers.
 *    - ###-###-#### - Mobile - Primary
 *    - ###-###-#### - Home
 *    - ###-###-#### - Work
 *
 *
 */
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Col, Divider, Form, Layout, notification, Row, Spin, Steps} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {PhoneNumber, WaitListApplication} from "../../../Model/enrollmentModel";
import {FormInstance} from 'antd/lib/form';
import {API} from 'aws-amplify';

import {EnrollmentDisabled} from '../../../Components/EnrollmentDisabled'

/** Presentational */
import RegistrationFormWrapper from '../../../Components/Styled/RegistrationFormWrapper';

/** App theme */
import {Store} from 'rc-field-form/es/interface';
import {mainContentStyle} from "../../../Themes/Styles";
import MainHeader from "../../../Components/MainHeader";
import MainFooter from "../../../Components/MainFooter";
import React, {useEffect, useRef, useState} from "react";
import {useEnrollmentApplicationConfigContext} from "../../context/EnrollmentApplicationConfigContext";
import {getApplicationByConfirmationNumber} from "../../../Services/enrollmentApplicationServices";
import WaitlistEnrollmentStudentStep from "./WaitlistEnrollmentStudentStep";
import WaitlistEnrollmentGuardianStep from "./WaitlistEnrollmentGuardianStep";
import WaitlistEnrollmentSubmitStep from "./WaitlistEnrollmentSubmitStep";
import dayjs from "dayjs";

const {Content} = Layout;

interface PropState {
    confirmationNumber: number;
}

function getUpdatedApplication(values: Store, existingApplication: WaitListApplication, clientFriendlyId: string): WaitListApplication {

    let updatedApplication: WaitListApplication = {
        ...existingApplication,
        ...values,
        clientFriendlyId: clientFriendlyId,
    }

    return updatedApplication


    // //PRIMARY GUARDIAN
    // let primaryPhoneNumbers = [];
    // if (p_p_phone_number) {
    //     var primaryPrimaryPhoneNumber: PhoneNumber = {
    //         number: p_p_phone_number.number,
    //         type: p_p_phone_number.type,
    //         primary: true
    //     };
    //     primaryPhoneNumbers.push(primaryPrimaryPhoneNumber);
    // }
    //
    // if (p_a_phone_number && p_a_phone_number.number && p_a_phone_number.number.length > 0) {
    //     var primaryAlternatePhoneNumber: PhoneNumber = {
    //         number: p_a_phone_number.number,
    //         type: p_a_phone_number.type,
    //         primary: false
    //     };
    //     primaryPhoneNumbers.push(primaryAlternatePhoneNumber);
    // }
    //
    // var primaryAddress: Address = {
    //     addressLine1: p_address,
    //     addressLine2: p_subpremise,
    //     city: p_city,
    //     state: p_state,
    //     zipCode: p_zipcode
    // };
    //
    // var primaryGuardian: Guardian = {
    //     firstName: p_first_name,
    //     lastName: p_last_name,
    //     phoneNumbers: primaryPhoneNumbers,
    //     address: primaryAddress,
    //     email: p_email
    // };
    //
    //
    // //SECONDARY GUARDIAN
    // var secondaryGuardian: Guardian = null;
    // if (s_first_name || s_last_name) {
    //
    //     let secondaryPhoneNumbers = [];
    //     if (s_p_phone_number && s_p_phone_number.number && s_p_phone_number.number.length > 0) {
    //         var secondaryPrimaryPhoneNumber: PhoneNumber = {
    //             number: s_p_phone_number.number,
    //             type: s_p_phone_number.type,
    //             primary: true
    //         };
    //         secondaryPhoneNumbers.push(secondaryPrimaryPhoneNumber);
    //     }
    //
    //     if (s_a_phone_number && s_a_phone_number.number && s_a_phone_number.number.length > 0) {
    //         var secondaryAlternatePhoneNumber: PhoneNumber = {
    //             number: s_a_phone_number.number,
    //             type: s_a_phone_number.type,
    //             primary: false
    //         };
    //         secondaryPhoneNumbers.push(secondaryAlternatePhoneNumber);
    //     }
    //
    //     var secondaryAddress: Address;
    //     if (secondaryAddressSameAsPrimary) {
    //         secondaryAddress = primaryAddress;
    //     } else {
    //         secondaryAddress = {
    //             addressLine1: s_address,
    //             addressLine2: s_subpremise,
    //             city: s_city,
    //             state: s_state,
    //             zipCode: s_zipcode
    //         };
    //     }
    //
    //     secondaryGuardian = {
    //         firstName: s_first_name,
    //         lastName: s_last_name,
    //         phoneNumbers: secondaryPhoneNumbers,
    //         address: secondaryAddress,
    //         email: s_email
    //     };
    // }
    //
    // var studentsArray: Student[] = [];
    //
    // students.forEach((student: any) => {
    //
    //     var studentObj: Student = {
    //         clientId: clientId,
    //         familyId: student.familyId ?? null,
    //         id: student.id ?? null,
    //         firstName: student.firstName ?? null,
    //         lastName: student.lastName ?? null,
    //         gender: student.gender ?? null,
    //         birthDate: student.birthDate ?? null,
    //         grade: student.gradeField.grade ?? null,
    //         status: null,
    //         tags: null,
    //         notes: null,
    //         districtStatus: null,
    //         createdTimestamp: null,
    //         acceptedDate: null,
    //         schoolYearId: null,
    //         deletedInd: false,
    //         contactInfo: null,
    //         lotteryRank: null,
    //         waitlistPosition: null,
    //         siblingDate: null,
    //     }
    //     studentsArray.push(studentObj);
    // });
    //
    // var application: WaitListApplication = {
    //     familyId: familyId,
    //     clientId: clientId,
    //     clientFriendlyId: clientFriendlyId,
    //     primaryGuardian: primaryGuardian,
    //     secondaryGuardian: secondaryGuardian,
    //     students: studentsArray,
    //     referenceSource: referenceSource,
    //     referenceSourceComments: referenceSourceComments,
    //     hasEnrolledStudents: hasEnrolledStudents,
    //     isStaff: isStaff,
    //     isFounder: isFounder,
    //     staffOrFounderName: staffOrFounderName,
    //     enrolledStudentsIds: enrolledStudentsIds,
    //     confirmationNumber: confirmationNumber,
    // };

    // return application
}

function getInitialPhoneNumbersFormValues(phoneNumbers: PhoneNumber[]) {
    let outPhoneNumbers = phoneNumbers ?? []
    if (outPhoneNumbers.length === 0) {
        outPhoneNumbers.push({number: undefined, type: undefined, primary: true})
        outPhoneNumbers.push({number: undefined, type: undefined, primary: false})
    } else if (outPhoneNumbers.length === 1) {
        outPhoneNumbers.push({number: undefined, type: undefined, primary: false})
    }
    return outPhoneNumbers
}

function getFormValuesForWaitlistApplication(application: WaitListApplication) {
    console.log(`getFormValuesForWaitlistApplication - application: ${JSON.stringify(application)}`)

    let students = (application?.students ?? [{}]).map(student => {
        return {
            ...student,
            birthDate: student.birthDate ? dayjs(student.birthDate) : undefined
        }
    })

    return {
        ...application,
        primaryGuardian: {
            ...application?.primaryGuardian,
            phoneNumbers: getInitialPhoneNumbersFormValues(application?.primaryGuardian?.phoneNumbers)
        },
        secondaryGuardian: {
            ...application?.secondaryGuardian,
            phoneNumbers: getInitialPhoneNumbersFormValues(application?.secondaryGuardian?.phoneNumbers)
        },
        students: students
    };

    // if (application) {
    //
    //     if (application.primaryGuardian) {
    //         fieldsValue['p_first_name'] = application.primaryGuardian.firstName;
    //         fieldsValue['p_last_name'] = application.primaryGuardian.lastName;
    //         fieldsValue['p_email'] = application.primaryGuardian.email;
    //         fieldsValue['p_email'] = application.primaryGuardian.email;
    //
    //         if (application.primaryGuardian.phoneNumbers) {
    //             application.primaryGuardian.phoneNumbers.forEach(phoneNumber => {
    //                 if (phoneNumber.primary) {
    //                     fieldsValue['p_p_phone_number'] = {number: phoneNumber.number, type: phoneNumber.type};
    //                 } else {
    //                     fieldsValue['p_a_phone_number'] = {number: phoneNumber.number, type: phoneNumber.type};
    //                 }
    //             });
    //         }
    //
    //         if (application.primaryGuardian.address) {
    //             fieldsValue['p_address'] = application.primaryGuardian.address.addressLine1;
    //             fieldsValue['p_subpremise'] = application.primaryGuardian.address.addressLine2;
    //             fieldsValue['p_city'] = application.primaryGuardian.address.city;
    //             fieldsValue['p_state'] = application.primaryGuardian.address.state;
    //             fieldsValue['p_zipcode'] = application.primaryGuardian.address.zipCode;
    //         }
    //     }
    //
    //     if (application.secondaryGuardian) {
    //         fieldsValue['s_first_name'] = application.secondaryGuardian.firstName;
    //         fieldsValue['s_last_name'] = application.secondaryGuardian.lastName;
    //         fieldsValue['s_email'] = application.secondaryGuardian.email;
    //
    //         if (application.secondaryGuardian.phoneNumbers) {
    //             application.secondaryGuardian.phoneNumbers.forEach(phoneNumber => {
    //                 if (phoneNumber.primary) {
    //                     fieldsValue['s_p_phone_number'] = {number: phoneNumber.number, type: phoneNumber.type};
    //                 } else {
    //                     fieldsValue['s_a_phone_number'] = {number: phoneNumber.number, type: phoneNumber.type};
    //                 }
    //             });
    //         }
    //
    //         if (application.secondaryGuardian.address) {
    //             fieldsValue['s_address'] = application.secondaryGuardian.address.addressLine1;
    //             fieldsValue['s_subpremise'] = application.secondaryGuardian.address.addressLine2;
    //             fieldsValue['s_city'] = application.secondaryGuardian.address.city;
    //             fieldsValue['s_state'] = application.secondaryGuardian.address.state;
    //             fieldsValue['s_zipcode'] = application.secondaryGuardian.address.zipCode;
    //         }
    //     }
    //
    //     if (application.students) {
    //         application.students.forEach(student => {
    //             student.birthDate = dayjs(student.birthDate);
    //             student['gradeField'] = {grade: student.grade};
    //         });
    //         fieldsValue['students'] = application.students;
    //     } else {
    //         fieldsValue['students'] = [createNewStudent(null)];
    //     }
    // } else {
    //     fieldsValue['students'] = [createNewStudent(null)];
    // }
    //
    // return fieldsValue;
}

function WaitlistEnrollmentContainer() {
    const formRef = useRef<FormInstance>();
    let navigate = useNavigate();
    let location = useLocation();
    const {clientFriendlyId} = useParams();
    const {setClientFriendlyId, enrollmentApplicationConfig} = useEnrollmentApplicationConfigContext();
    const [curFormValues, setCurFormValues] = useState<Store | null>(null)
    const [application, setApplication] = useState<WaitListApplication | null>(null);
    const [confirmationNumber, setConfirmationNumber] = useState<number | null>(null);
    const [isNewApplication, setIsNewApplication] = useState<boolean>(true);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (location && location.state) {
            let {confirmationNumber} = location.state as PropState;
            setConfirmationNumber(confirmationNumber)
        }
    }, [location])

    useEffect(() => {

        if (confirmationNumber) {
            console.log(`confirmationNumber found, populating existing form`);
            setIsNewApplication(false);

            getApplicationByConfirmationNumber(clientFriendlyId, confirmationNumber).then((application: WaitListApplication) => {
                setApplication(application);
            }).catch(reason => {
                console.log(`Error looking up application by confirmationNumber: ${reason}`)
                notification.error({
                    message: 'Error',
                    description: `Unable to find existing application for confirmation #${confirmationNumber}.`, //TODO handle error appropriately
                    placement: 'topRight',
                    duration: 1.5
                });
            });
        }
    }, [confirmationNumber, clientFriendlyId])

    useEffect(() => {
        setClientFriendlyId(clientFriendlyId)
    })

    let handleFinish = (values: Store) => {
        let updatedFormValues = {...curFormValues, ...values}
        setCurFormValues(updatedFormValues)

        let updatedApplication = getUpdatedApplication(updatedFormValues, application, clientFriendlyId);
        console.log(`updatedApplication: ${JSON.stringify(updatedApplication)}`)


        if (currentStep !== 2) {
            nextStep();
        } else {
            // show loader
            setLoading(true);

            //generate request
            let request = {
                body: {
                    application: updatedApplication
                }
            }

            //submit
            const apiName = "enrollmentApi";
            const path = "/applications";
            API.post(apiName, path, request).then((response: any) => {
                console.log(`applicatons response:${JSON.stringify(response)}`);
                notification.success({
                    message: 'Application Successful!',
                    description: 'Application created successfully, Redirecting you in a few!',
                    placement: 'topRight',
                    duration: 1.5,
                    onClose: () => {
                        navigate(`/${clientFriendlyId}/application/confirmation`,
                            {
                                state: {
                                    confirmationNumber: response.confirmationNumber,
                                    isNewApplication: isNewApplication
                                }
                            });
                    }
                });
            }).catch(error => {
                console.log(error.response);

                notification.error({
                    message: 'Error',
                    description: 'Error submitting application',
                    placement: 'topRight',
                    duration: 1.5
                });

                setLoading(false);
            });
        }
    };

    function onStepChange(current) {
        if (currentStep > current) {//Allow user to go back in the form without completing the current step.
            setCurrentStep(current);
        } else {
            formRef.current.validateFields().then((values) => {
                setCurrentStep(current);
            }).catch((errorInfo) => {
                console.log(`onStepChange error: ${errorInfo}`)
            });
        }
    }

    function nextStep() {
        setCurrentStep(currentStep + 1);
    }

    function prevStep() {
        setCurrentStep(currentStep - 1);
    }

    if (!enrollmentApplicationConfig) {
        return <div/>
    }

    if (!enrollmentApplicationConfig.enabled) {
        return <EnrollmentDisabled enrollmentApplicationConfig={enrollmentApplicationConfig}/>
    }

    return (
        <React.Fragment>
            <Layout style={{height: '100vh'}}>
                <MainHeader/>
                <Content>
                    <Row justify="center" align="middle">
                        <RegistrationFormWrapper
                            ref={formRef}
                            size="middle"
                            onFinish={handleFinish}
                            scrollToFirstError
                            layout="vertical"
                            style={mainContentStyle}
                            initialValues={getFormValuesForWaitlistApplication(application)}
                        >
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div id="scriptDiv"/>
                                    <Row><h1>{enrollmentApplicationConfig.header}</h1></Row>
                                    <Row><h2>{enrollmentApplicationConfig.subHeader}</h2></Row>
                                    {isNewApplication &&
                                        <Row><Link id="update-existing" to={`/${clientFriendlyId}/application/lookup`}>Click
                                            here to update an existing application</Link></Row>}
                                    <Divider/>
                                    <Row>
                                        <Steps current={currentStep}
                                               onChange={onStepChange}
                                               items={[
                                                   {
                                                       title: 'Students',
                                                   },
                                                   {
                                                       title: 'Guardians',
                                                   },
                                                   {
                                                       title: 'Submit',
                                                   },
                                               ]}
                                        />
                                    </Row>
                                    <Divider/>
                                    {currentStep === 0 &&
                                        <Row gutter={16}>
                                            <WaitlistEnrollmentStudentStep
                                                enrollmentApplicationConfig={enrollmentApplicationConfig}/>
                                        </Row>}
                                    {currentStep === 1 &&
                                        <Row gutter={16}>
                                            <WaitlistEnrollmentGuardianStep formRef={formRef}/>
                                        </Row>}

                                    {(currentStep === 2) &&
                                        <Row gutter={[16, 16]}>
                                            <WaitlistEnrollmentSubmitStep
                                                enrollmentApplicationConfig={enrollmentApplicationConfig}/>
                                        </Row>}

                                    {currentStep === 0 && (
                                        <Row>
                                            <Col lg={19}></Col>
                                            <Col lg={5}>
                                                <Button id="next-step-0-btn" style={{width: "100%"}} type={"primary"}
                                                        htmlType="submit">
                                                    Next
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                    {currentStep === 1 && (
                                        <Row>
                                            <Col lg={14}></Col>
                                            <Col lg={5}>
                                                <Form.Item className="text-center">
                                                    <Button id="previous-step-1-btn" style={{width: "100%"}}
                                                            onClick={() => prevStep()}>
                                                        Previous
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={5}>
                                                <Button id="next-step-1-btn" type="primary" style={{width: "100%"}}
                                                        htmlType="submit">
                                                    Next
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                    {currentStep === 2 &&
                                        <Row>
                                            <Col lg={14}></Col>
                                            <Col lg={5}>
                                                <Button id="previous-step-2-btn" style={{width: "100%"}}
                                                        onClick={() => prevStep()}>
                                                    Previous
                                                </Button>
                                            </Col>
                                            <Col lg={5}>
                                                <Button id="submit" style={{width: '100%'}} type="primary"
                                                        disabled={loading} htmlType="submit">
                                                    {loading ? <Spin indicator={<LoadingOutlined type="loading"
                                                                                                 style={{fontSize: 24}}
                                                                                                 spin/>}/> : isNewApplication ? 'Submit' : 'Update'}
                                                </Button>
                                            </Col>
                                        </Row>}
                                </Col>
                            </Row>
                        </RegistrationFormWrapper>
                    </Row>
                </Content>
                <MainFooter/>
            </Layout>
        </React.Fragment>
    );
}

export default WaitlistEnrollmentContainer;