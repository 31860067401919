import {EnrollmentApplicationStatus, WaitListApplication} from '../Model/enrollmentModel';
import {API} from "aws-amplify";

const apiName = "enrollmentApi";

export function getApplicationStatus(clientFriendlyId: string, confirmationNumber: string) {
    const path = `/applications/status?clientFriendlyId=${clientFriendlyId}&confirmationNumber=${confirmationNumber}`;
    const myInit = {}
    return new Promise( (resolve, reject) => {
        API.get(apiName, path, myInit).then((response: EnrollmentApplicationStatus) => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

export async function getApplicationByConfirmationNumber(clientFriendlyId, confirmationNumber): Promise<WaitListApplication> {
    const path = `/applications?confirmationNumber=${confirmationNumber}&clientFriendlyId=${clientFriendlyId}`;
    const myInit = {}
    const response = await API.get(apiName, path, myInit);
    let application = response.waitlistApplication;
    application.familyId = application.id;
    return application
}