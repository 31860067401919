import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, notification, Row, Spin, Tabs} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {LoadingOutlined} from '@ant-design/icons';
import SettingsFormWrapper from '../../../Components/Styled/SettingsFormWrapper';
import {Store} from 'rc-field-form/es/interface';
import {ClientConfig,} from "../../../Model/enrollmentModel";
import {updateClientConfig} from "../../../Services/clientConfigService";
import {useSetClientConfig, useUser} from "../../../Store/UserStore";
import ClientConfigAdminSettings from "./ClientConfigAdminSettings";
import ClientConfigApplicationSettings from "./ClientConfigApplicationSettings";
import ClientConfigTagsSettings from "./ClientConfigTagsSettings";
import ClientConfigWaitlistSettings from "./ClientConfigWaitlistSettings";
import ClientConfigEnrollmentSettings from "./ClientConfigEnrollmentSettings";
import ClientConfigGeneralSettings from "./ClientConfigGeneralSettings";
import AdminDashboardContentCard from "../../../Components/Styled/AdminDashboardContentCard";
import {parseTimestamp} from "../../../Utils/DateUtils";

const cloneDeep = require('lodash.clonedeep');
const {TabPane} = Tabs;

function getFormValuesForClientConfig(clientConfig: ClientConfig) {
    console.log(`getFormValuesForClientConfig clientConfig:${JSON.stringify(clientConfig)}`)

    let enrollmentPeriodArray = clientConfig!.enrollmentPeriodConfig?.enrollmentPeriods.sort((a, b) => {return b.sequenceNumber - a.sequenceNumber}).map((enrollmentPeriod) => {
        return {
            ...enrollmentPeriod,
            startDate: parseTimestamp(enrollmentPeriod.startDate),
            endDate: parseTimestamp(enrollmentPeriod.endDate),
            lotteryDate: parseTimestamp(enrollmentPeriod.lotteryDate),
        }
    })

    let output = {
        ...clientConfig,
        logStudentIds: (clientConfig!.logStudentIds ?? []).join(),
        enrollmentApplicationConfig: {
            ...clientConfig.enrollmentApplicationConfig,
            minimumBirthdate: parseTimestamp(clientConfig.enrollmentApplicationConfig.minimumBirthdate),
        },
        enrollmentPeriodConfig: {
            ...clientConfig.enrollmentPeriodConfig,
            enrollmentPeriodCreationConfig: {
                ...clientConfig.enrollmentPeriodConfig.enrollmentPeriodCreationConfig,
                startDate: parseTimestamp(clientConfig.enrollmentPeriodConfig.enrollmentPeriodCreationConfig?.startDate),
                endDate: parseTimestamp(clientConfig.enrollmentPeriodConfig.enrollmentPeriodCreationConfig?.endDate),
                lotteryDate: parseTimestamp(clientConfig.enrollmentPeriodConfig.enrollmentPeriodCreationConfig?.lotteryDate),
            },
            enrollmentPeriods: enrollmentPeriodArray,
        }
    }
    console.log(`getFormValuesForClientConfig output:${JSON.stringify(output)}`)
    return output;
}

function getUpdatedClientConfig(isAdmin: boolean, existingClientConfig: ClientConfig, values: Store): ClientConfig {
    console.log(`getUpdatedClientConfig values:${JSON.stringify(values)}`)

    let enrollmentPeriodArray = values.enrollmentPeriodConfig?.enrollmentPeriods.map((enrollmentPeriod) => {
        return {
            ...enrollmentPeriod,
            startDate: enrollmentPeriod.startDate?.format(),
            endDate: enrollmentPeriod.endDate?.format(),
            lotteryDate: enrollmentPeriod.lotteryDate?.format()
        }
    })

    let updatedClientConfig: ClientConfig = {
        ...existingClientConfig,
        ...values,
        enrollmentPeriodConfig: {
            ...existingClientConfig.enrollmentPeriodConfig,
            ...values.enrollmentPeriodConfig,
            enrollmentPeriodCreationConfig: {
                ...existingClientConfig.enrollmentPeriodConfig.enrollmentPeriodCreationConfig,
                ...values.enrollmentPeriodConfig.enrollmentPeriodCreationConfig,
                startDate: values.enrollmentPeriodConfig.enrollmentPeriodCreationConfig?.startDate?.format(),
                endDate: values.enrollmentPeriodConfig.enrollmentPeriodCreationConfig?.endDate?.format(),
                lotteryDate: values.enrollmentPeriodConfig.enrollmentPeriodCreationConfig?.lotteryDate?.format(),
            },
            enrollmentPeriods: enrollmentPeriodArray,
        }
    }

    updatedClientConfig.enrollmentApplicationConfig.minimumBirthdate = values.enrollmentApplicationConfig.minimumBirthdate?.format()


    if (isAdmin) {
        updatedClientConfig.logStudentIds = values.logStudentIds?.split() ?? []
        updatedClientConfig.districtStatuses = values.districtStatuses //TODO create form item for this field
        updatedClientConfig.contactInformation = values.contactInformation //TODO create form item for this field
    }

    return updatedClientConfig
}

function SettingsContainer() {
    console.log(`constructing SettingsContainer`);
    const user = useUser();
    const setClientConfig = useSetClientConfig();
    const [isEdited, setIsEdited] = useState(false);
    const [loading, setLoading] = useState(false);
    const formRef = useRef<FormInstance>();
    const [curFormValues, setCurFormValues] = useState<Store | null>(null)

    useEffect(() => {
        console.log(`settings - useEffect resetting form fields to user.clientConfig values`)
        const formValues = getFormValuesForClientConfig(user.clientConfig)
        setIsEdited(false);
        setCurFormValues(formValues)
        formRef.current.setFieldsValue(formValues)
    }, [user.clientConfig, formRef])

    let onValuesChange = (changedValues, allValues) => {
        setCurFormValues(({...curFormValues, ...allValues}))
        setIsEdited(true);
        console.log("onValuesChange")
    }

    let onCancel = () => {
        console.log(`settings - onCancel resetting form fields to user.clientConfig values`)
        const formValues = getFormValuesForClientConfig(user.clientConfig)
        setIsEdited(false);
        setCurFormValues(formValues)
        formRef.current.setFieldsValue(formValues)
    }

    let handleFinish = (values: Store) => {
        console.log('settings - entering handleFinish')
        let updatedFormValues = {...curFormValues, ...values}
        setCurFormValues(updatedFormValues)
        setLoading(true);

        const updatedClientConfig = getUpdatedClientConfig(user.isAdmin, user.clientConfig, updatedFormValues);
        console.log(`updatedClientConfig: ${JSON.stringify(updatedClientConfig)}`)

        updateClientConfig(updatedClientConfig).then(response => {
            console.log(response);
            setClientConfig(cloneDeep(updatedClientConfig));
            setLoading(false);
        }).catch(error => {
            console.log(error.response)
            notification.error({
                message: 'Error',
                description: 'Unable to save config.', //TODO handle error appropriately
                placement: 'topRight',
                duration: 1.5
            });
            setLoading(false);
        });
    };

    return (
        <AdminDashboardContentCard>
            <SettingsFormWrapper
                size="middle"
                onFinish={handleFinish}
                scrollToFirstError
                ref={formRef}
                requiredMark={false}
                layout="vertical"
                initialValues={curFormValues}
                onValuesChange={onValuesChange}
            >
                <Tabs tabPosition="left">
                    <TabPane tab="General" key="general">
                        <ClientConfigGeneralSettings/>
                    </TabPane>
                    <TabPane tab="Enrollment" key="enrollment">
                        <ClientConfigEnrollmentSettings formRef={formRef}/>
                    </TabPane>
                    <TabPane tab="Waitlist" key="waitlist">
                        <ClientConfigWaitlistSettings/>
                    </TabPane>
                    <TabPane tab="Tags" key="tags">
                        <ClientConfigTagsSettings formRef={formRef} studentTags={curFormValues?.tags}/>
                    </TabPane>
                    <TabPane tab="Application" key="application">
                        <ClientConfigApplicationSettings formRef={formRef}/>
                    </TabPane>
                    {user.isAdmin && <TabPane tab="Admin" key="admin-settings">
                        <ClientConfigAdminSettings/>
                    </TabPane>}
                </Tabs>
                <Form.Item className="text-center">
                    <Row gutter={8}>
                        <Col span={18}/>
                        <Col span={3}>
                            <Button style={{width: '100%'}} disabled={loading || !isEdited} onClick={onCancel}
                                    htmlType="button">Cancel</Button>
                        </Col>
                        <Col span={3}>
                            <Button style={{width: '100%'}} type="primary" disabled={loading || !isEdited}
                                    htmlType="submit">
                                {loading ? <Spin indicator={<LoadingOutlined type="loading" style={{fontSize: 24}}
                                                                             spin/>}/> : 'Save'}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </SettingsFormWrapper>
        </AdminDashboardContentCard>
    );
}

export default SettingsContainer;