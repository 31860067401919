import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Spin, Input, Button, Col, Row } from 'antd';

/** Presentational */
import FormWrapper from '../../../Components/Styled/FormWrapper';

/** App constants */
import { LoadingOutlined } from '@ant-design/icons';
import { Store } from 'rc-field-form/es/interface';

type Props = {
};

type State = {
  loading: boolean;
};

class ApplicationLookupHelpContainer extends React.Component<Props, State> {
  state = {
    loading: false
  };

  handleFinish = (values: Store) => {
    this.setState({ loading: true });
  };

  render() {
    const { loading } = this.state;

    return (
      <React.Fragment>
        <FormWrapper onFinish={this.handleFinish} className="login-form">
          <Form.Item name="Confirmation Number" rules={[{ required: true, message: 'Confirmation number required' }]}>
              <Input/>
          </Form.Item>
          <Form.Item className="text-center">
            <Row gutter={16}>
              <Col lg={24}>
                <Link style={{ float: 'right' }} className="login-form-forgot" to="/forgot-confirmation-number">
                  Forgot confirmation number
                </Link>
              </Col>
              <Col lg={24}>
                <Button
                  style={{ width: '100%' }}
                  type="primary"
                  disabled={loading}
                  htmlType="submit"
                  className="login-form-button"
                >
                  {loading ? <Spin indicator={<LoadingOutlined type="loading" style={{ fontSize: 24 }} spin />} /> : 'Log in'}
                </Button>
              </Col>
              <Col lg={24}>
                Or <Link to="/enroll">Start new application</Link>
              </Col>
            </Row>
          </Form.Item>
        </FormWrapper>
      </React.Fragment>
    );
  }
}

export default ApplicationLookupHelpContainer;