import {Layout} from "antd";
import * as React from "react";

const { Footer } = Layout;

const MainFooter = props => {
    return (
        <Footer style={{ textAlign: 'center' }}>Enroll With It ©2020</Footer>
    );
};

export default MainFooter;