import authManager from "../Auth/authManager";
import {EnrollmentServicesMetaData} from "../Model/enrollmentModel";

const apiName = "enrollmentApi";
const metadataPath = '/metadata';

export async function getEnrollmentServicesMetaData(): Promise<EnrollmentServicesMetaData> {
    console.log(`getEnrollmentServicesMetaData`);
    const myInit = {}
    return await authManager.apiGet<EnrollmentServicesMetaData>(apiName, metadataPath, myInit);
}