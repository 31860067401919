import {Family} from "../Model/enrollmentModel";

export function createNewFamily(schoolYearId, clientConfig) {
    return {
        id: null,
        clientId: clientConfig.clientId,
        clientFriendlyId: clientConfig.clientFriendlyId,
        primaryGuardian: null,
        secondaryGuardian: null,
        students: [],
        notes: null,
        referenceSource: null,
        referenceSourceComments: null,
        districtStatus: null,
        schoolYearId: schoolYearId,
        deletedInd: false,
        hasEnrolledStudents: null,
        enrolledStudentsIds: null,
    } as Family;
}