import * as React from 'react';
import {Button, Col, Layout, Result} from 'antd';

/** Presentational */
import EmailConfirmFormWrapper from '../../../Components/Styled/EmailConfirmFormWrapper';
import MainFooter from "../../../Components/MainFooter";
import MainHeader from "../../../Components/MainHeader";
import {mainContentStyle} from "../../../Themes/Styles";

const { Content } = Layout;

type Props = {

}

class ApplicationLookupConfirmationContainer extends React.Component<Props> {

  render() {

    return (
        <Layout style={{ height: '100vh' }}>
            <MainHeader/>
            <Content>
                <EmailConfirmFormWrapper style={mainContentStyle}>
                    <Col md={24} lg={18}>
                        <Result
                            status="success"
                            title="Application Found"
                            subTitle="You should be recieving an email containing a link to update it."
                            extra={[
                            <Button type="link" href="https://www.heritageha.org/">Return to Heritage Heights Academy</Button>
                            ]}
                        />
                    </Col>
                </EmailConfirmFormWrapper>
            </Content>
            <MainFooter/>
        </Layout>
    );
  }
}

export default ApplicationLookupConfirmationContainer;
