import * as React from 'react';
import { Link} from 'react-router-dom';
import { Col } from 'antd';

/** Presentational */
import FullWidthWrapper from '../Components/Styled/FullWidthWrapper';
import EmailConfirmFormWrapper from '../Components/Styled/EmailConfirmFormWrapper';

function ConfirmEmailComponent() {

  return (
    <FullWidthWrapper align="center">
      <EmailConfirmFormWrapper>
        <Col md={24} lg={18}>
          <div className="full-width">
            <h2>Account Created</h2>
            <p>Check your email for a confirmation link to complete registration</p>
            <Link to="/login">Return to login</Link>
          </div>
        </Col>
      </EmailConfirmFormWrapper>
    </FullWidthWrapper>
  );
}

export default ConfirmEmailComponent;
