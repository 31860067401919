import React, {useState} from 'react';
import {notification, Button, Upload, Tabs} from 'antd';
import {PageHeader} from '@ant-design/pro-layout';
import {UploadOutlined} from '@ant-design/icons';
import {Family, Address, Student, Guardian} from '../../../Model/enrollmentModel';
import FamilyTableContainer from '../../Components/Family/FamilyTableComponent';
import {decorateFamilies, decorateFamily} from '../../../Utils/helpers';
import Papa from 'papaparse';
import districtBoundary from './districtBoundaryRaw.json';
import {getFamilies, saveFamily} from "../../../Services/familiesService";
import FamilyDrawerController from "../../Components/Family/FamilyDrawerController";
import DbcManagerComponent from "./dbcManagerComponent";
import {useSchoolYearId, useUser} from "../../../Store/UserStore";
import dayjs from "dayjs";
import OrganizationManagerComponent from "./OrganizationManagerComponent";
import ClientConfigComponent from "./clientConfigComponent";
import AdminDashboardContentCard from "../../../Components/Styled/AdminDashboardContentCard";
import DistrictStatusComponent from "./districtStatusComponent";

const {TabPane} = Tabs;

function AdminContainer() {
    console.log(`constructing AdminContainer`);
    const user = useUser();
    const schoolYearId = useSchoolYearId();
    const [families, setFamilies] = useState<Family[]>([]);
    const [familyDrawerOpen, setFamilyDrawerOpen] = useState(false);
    const [isNewFamily, setIsNewFamily] = useState(false);
    const [curFamily, setCurFamily] = useState<Family | null>(null);
    const [startIndex, setStartIndex] = useState(0);
    const [fileList, setFileList] = useState<any>(null);

    let onFamilySelected = (family) => {
        setIsNewFamily(false);
        setCurFamily(family);
        setFamilyDrawerOpen(true);
    }

    let onFamilyDrawerClose = () => {
        setFamilyDrawerOpen(false);
        setIsNewFamily(false);
        setCurFamily(null);
    }

    let onFamilyDrawerSubmit = (updatedFamily: Family) => {
        notification.success({
            message: isNewFamily ? 'Family Created!' : 'Family Updated!',
            placement: 'topRight',
            duration: 1.5
        });

        decorateFamily(updatedFamily);
        let updatedFamilies = [];
        if (!isNewFamily) {
            families.forEach(family => {
                if (family.id !== updatedFamily.id) {
                    updatedFamilies.push(family);
                } else {
                    updatedFamilies.push(updatedFamily);
                }
            });
        }
        setFamilyDrawerOpen(false);
        setIsNewFamily(false);
        setCurFamily(null);
        setFamilies(updatedFamilies);
    }

    let onFamilyDeleted = () => {
        notification.success({
            message: 'Family Deleted!',
            placement: 'topRight',
            duration: 1.5
        });

        let updatedFamilies = [];
        families.forEach(family => {
            if (family.id !== curFamily.id) {
                updatedFamilies.push(family);
            }
        });

        setFamilyDrawerOpen(false);
        setIsNewFamily(false);
        setCurFamily(null);
        setFamilies(updatedFamilies);
    }

    let onUploadFileChange = (info) => {
        console.log(`onUploadFileChange info:${info}`)
    }

    let removeFile = (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);

        setFileList(newFileList);
    }

    let beforeFileUpload = (file) => {
        console.log("beforeFileUpload");
        /*const reader = new FileReader();
        reader.readAsText(file);
          reader.readAsDataURL(file);
          reader.onload = () => {
          };*/

        Papa.parse(file, {
            complete: loadData,
            header: true
        });

        setFileList([...fileList, file]);

        return false;
    }
    let loadData = (result) => {
        console.log("loadData");
        var data = result.data;
        let loadedFamilies = [];

        data.forEach((row) => {
            processRow(row, loadedFamilies);
        })
        decorateFamilies(loadedFamilies);
        setFamilies(loadedFamilies);
    }

    let processRow = (row, families) => {
        console.log("processRow");
        //console.log(JSON.stringify(row));
        let family: Family = findCurFamily(row, families);
        let isExistingFam = true;

        if (!family) {
            isExistingFam = false;
            //PRIMARY
            let primaryAddress: Address = {
                addressLine1: getRowValue(row, 'Primary Guardian Address 1'),
                addressLine2: getRowValue(row, 'Primary Guardian Address 2'),
                city: getRowValue(row, 'Primary Guardian City'),
                state: getRowValue(row, 'Primary Guardian State'),
                zipCode: getRowValue(row, 'Primary Guardian Zip')
            };

            let primaryPhoneNumbers = [];
            let primaryHomePhoneNumber: string = getRowValue(row, 'Primary Guardian Home Phone');
            let primaryMobilePhoneNumber: string = getRowValue(row, 'Primary Guardian Mobile Phone');
            let primaryDayPhoneNumber: string = getRowValue(row, 'Primary Guardian Day Phone');

            if (primaryMobilePhoneNumber) {
                primaryPhoneNumbers.push({
                    number: primaryMobilePhoneNumber,
                    type: 'Mobile',
                    primary: true
                })
            }
            if (primaryHomePhoneNumber) {
                primaryPhoneNumbers.push({
                    number: primaryHomePhoneNumber,
                    type: 'Home',
                    primary: !primaryMobilePhoneNumber
                })
            }
            if (primaryDayPhoneNumber) {
                primaryPhoneNumbers.push({
                    number: primaryDayPhoneNumber,
                    type: 'Work',
                    primary: !primaryMobilePhoneNumber && !primaryHomePhoneNumber
                })
            }

            let primaryGuardian: Guardian = {
                firstName: getRowValue(row, 'Primary Guardian First Name'),
                lastName: getRowValue(row, 'Primary Guardian Last Name'),
                phoneNumbers: primaryPhoneNumbers,
                address: primaryAddress,
                email: getRowValue(row, 'Primary Guardian E-Mail Address')
            };

            //SECONDARY
            let secondaryAddress: Address = {
                addressLine1: getRowValue(row, 'Secondary Guardian Address 1'),
                addressLine2: getRowValue(row, 'Secondary Guardian Address 2'),
                city: getRowValue(row, 'Secondary Guardian City'),
                state: getRowValue(row, 'Secondary Guardian State'),
                zipCode: getRowValue(row, 'Secondary Guardian Zip')
            };


            let secondaryPhoneNumbers = [];
            let secondaryHomePhoneNumber: string = getRowValue(row, 'Secondary Guardian Home Phone');
            let secondaryMobilePhoneNumber: string = getRowValue(row, 'Secondary Guardian Mobile Phone');
            let secondaryDayPhoneNumber: string = getRowValue(row, 'Secondary Guardian Day Phone');

            if (secondaryMobilePhoneNumber) {
                secondaryPhoneNumbers.push({
                    number: secondaryMobilePhoneNumber,
                    type: 'Mobile',
                    primary: true
                })
            }
            if (secondaryHomePhoneNumber) {
                secondaryPhoneNumbers.push({
                    number: secondaryHomePhoneNumber,
                    type: 'Home',
                    primary: !secondaryMobilePhoneNumber
                })
            }
            if (secondaryDayPhoneNumber) {
                secondaryPhoneNumbers.push({
                    number: secondaryDayPhoneNumber,
                    type: 'Work',
                    primary: !secondaryMobilePhoneNumber && !secondaryHomePhoneNumber
                })
            }

            let secondaryGuardian: Guardian = {
                firstName: getRowValue(row, 'Secondary Guardian First Name'),
                lastName: getRowValue(row, 'Secondary Guardian Last Name'),
                phoneNumbers: secondaryPhoneNumbers,
                address: secondaryAddress,
                email: getRowValue(row, 'Secondary Guardian E-Mail Address')
            };

            family = {
                id: null,
                clientId: user.clientConfig.clientId,
                clientFriendlyId: user.clientConfig.clientFriendlyId,
                primaryGuardian: primaryGuardian,
                secondaryGuardian: secondaryGuardian,
                students: [],
                notes: null,
                referenceSource: getRowValue(row, 'How Did You Hear'),
                referenceSourceComments: null,
                confirmationNumber: null,
                districtStatus: null,
                schoolYearId: schoolYearId,
                deletedInd: false,
                hasEnrolledStudents: null,
                enrolledStudentsIds: null,
                guardianSummary: null,
                studentSummary: null,
                tagsSummary: [],
                statusSummary: [],
            }
            family['confirmationNumber'] = getRowValue(row, 'Online Applications Code');
            family['dataloadKey'] = row['Online Applications Code'];
            families.push(family);
        }

        let student: Student = {
            clientId: user.clientConfig.clientId,
            id: null,
            familyId: family.id,
            firstName: getRowValue(row, 'Student First Name'),
            lastName: getRowValue(row, 'Student Last Name'),
            gender: null,
            birthDate: getBirthDate(row),
            grade: parseGrade(getRowValue(row, 'Grade')),
            status: getStatus(row),
            tags: getTags(row),
            notes: null,
            districtStatus: null,
            createdTimestamp: getStudentCreatedTimestamp(row),
            acceptedDate: getAcceptedDate(row),
            schoolYearId: family.schoolYearId,
            deletedInd: false,
            contactInfo: null,
            lotteryRank: null,
            waitlistPosition: null,
            siblingDate: null,
        }

        family.students.push(student);
        //console.log(JSON.stringify(family));

        if (isExistingFam) {
            console.log(`FOUND FAMILY MATCH ${row['Online Applications Code']} #students:${family.students.length}`);
        }
    }

    let findCurFamily = (row, families) => {
        return families.find(function (family) {
            return family['dataloadKey'] === row['Online Applications Code'];
        });
    }

    let getRowValue = (row, columnName) => {
        if (row[columnName] && row[columnName].length > 0) {
            return row[columnName];
        } else {
            return null;
        }
    }
    let getBirthDate = (row) => {
        let date = getRowValue(row, 'Student Birthdate');
        if (date) {
            return dayjs(date, 'M/D/YYYY').format();
        } else {
            return dayjs().format();
        }
    }
    let parseGrade = (gradeStr) => {
        if (gradeStr) {
            if (gradeStr === '0') {
                return 'K';
            } else {
                return gradeStr;
            }
        } else {
            return gradeStr;
        }
    }

    let getTags = (row) => {
        let tags = [];

        if (row['Is Founding Member'] === 'Y') {
            tags.push("Board Member");
        }
        if (row['Is Faculty Sibling'] === 'Y') {
            tags.push("Staff");
        }
        if (row['Siblings Accepted'] === 'Y') {
            tags.push("Sibling");
        }
        return tags;
    }

    let getStatus = (row) => {
        if (row['Accepted'] === 'Y') {
            return 'Accepted';
        } else if (row['Withdrawn'] === 'Y') {
            return 'Withdrawn'
        } else {
            return 'Waitlist'
        }
    }

    let getStudentCreatedTimestamp = (row) => {
        let date = getRowValue(row, 'Date Application Added');
        if (date) {
            return dayjs(date, 'M/D/YYYY').format();
        } else {
            return dayjs().format();
        }
    }

    let getAcceptedDate = (row) => {
        let date = getRowValue(row, 'Date Accepted');
        if (date) {
            return dayjs(date, 'M/D/YYYY').format();
        } else {
            return dayjs().format();
        }
    }

    let submitFamilies = () => {
        let chunkSize = 50;

        if (startIndex >= families.length) {
            console.log("no more families to load");
        }
        for (let i = startIndex; i < families.length && i < startIndex + chunkSize; i++) {
            handleSaveFamily(families[i]);
        }
        setStartIndex(startIndex + chunkSize);
    }

    let handleSaveFamily = (family: Family) => {

        saveFamily(family).then(response => {
            console.log(response);
        }).catch(error => {
            console.log(`error:${error}`);
            notification.error({
                message: 'Error',
                description: 'Unable to save family.', //TODO handle error appropriately
                placement: 'topRight',
                duration: 1.5
            });
        });
    }

    /*return [
          { latitude: 51.5, longitude: 7.4 },
          { latitude: 51.555, longitude: 7.4 },
          { latitude: 51.555, longitude: 7.625 },
          { latitude: 51.5125, longitude: 7.625 },
      ]*/
    let transformDistrictBoundary = (districtBoundary) => {
        //console.log(JSON.stringify(districtBoundary));

        let transformedDistrictBoundary = [];

        districtBoundary.forEach(polygon => {
            let transformedPolygon = [];


            polygon[0].forEach(point => {
                transformedPolygon.push({
                    latitude: point[1],
                    longitude: point[0]
                })
            })

            transformedDistrictBoundary.push(transformedPolygon)
        });

        console.log(JSON.stringify(transformedDistrictBoundary));
    }

    let loadFamilies = () => {
        getFamilies(user.clientConfig.clientId, schoolYearId).then((families: Family[]) => {
            setFamilies(families);
        }).catch(error => {
            console.log(`error:${error}`);
            notification.error({
                message: 'Error',
                description: `Unable to load families.`, //TODO handle error appropriately
                placement: 'topRight',
                duration: 1.5
            });
        });
    };

    transformDistrictBoundary(districtBoundary);

    return (
        <AdminDashboardContentCard>
            <Tabs tabPosition="left">
                <TabPane tab="Dbc" key="dbc">
                    <DbcManagerComponent/>
                </TabPane>
                <TabPane tab="ClientConfig" key="clientConfig">
                    <ClientConfigComponent/>
                </TabPane>
                <TabPane tab ="DistrictStatus" key={"DistrictStatus"}>
                    <DistrictStatusComponent/>
                </TabPane>
                <TabPane tab="Family Import" key="familyImport">
                    <PageHeader
                        ghost={true}
                        title="Families"
                        subTitle="" //TODO set this to something meaningful
                    >
                        <Upload onRemove={removeFile} beforeUpload={beforeFileUpload} onChange={onUploadFileChange}>
                            <Button icon={<UploadOutlined/>}>Click to Upload Waitlist</Button>
                        </Upload>

                        <Button onClick={loadFamilies}>Click to load current families</Button>
                        <Button onClick={submitFamilies} icon={<UploadOutlined/>}>Save</Button>
                        <FamilyDrawerController
                            clientId={user.clientConfig.clientId}
                            family={curFamily}
                            visible={familyDrawerOpen}
                            isNewFamily={isNewFamily}
                            onDelete={onFamilyDeleted}
                            onClose={onFamilyDrawerClose}
                            onSubmit={onFamilyDrawerSubmit}
                        />
                    </PageHeader>
                    <FamilyTableContainer families={families} onFamilySelected={onFamilySelected}/>
                </TabPane>
                <TabPane tab="Manage Organization" key="organization">
                    <OrganizationManagerComponent/>
                </TabPane>
            </Tabs>

        </AdminDashboardContentCard>
    );
}

export default AdminContainer;