import * as React from 'react';
import { Navigate, useLocation, Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Form, Input, Button, notification, Popover, Spin, Row, Col } from 'antd';

/** App theme */
import { colors } from '../../../Themes/Colors';
import FormWrapper from '../../../Components/Styled/FormWrapper';
import { LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { Store } from 'rc-field-form/es/interface';
import {useState} from "react";

function PasswordResetContainer() {
  const location = useLocation();
  const [ confirmDirty, setConfirmDirty ] = useState(false);
  const [ redirect, setRedirect ] = useState(false);
  const [ loading, setLoading ] = useState(false);


  function handleBlur(event: React.FormEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;
    setConfirmDirty(confirmDirty || !!value);
  }

  function handleFinish(values: Store) {
    let { password, code } = values;
    let username = location.search.split('=')[1];

    Auth.forgotPasswordSubmit(username.trim(), code.trim(), password.trim())
      .then(() => {
        notification.success({
          message: 'Success!',
          description: 'Password reset successful, Redirecting you in a few!',
          placement: 'topRight',
          duration: 1.5,
          onClose: () => {
            setRedirect(true);
          }
        });
      })
      .catch(err => {
        notification['error']({
          message: 'Error resetting password',
          description: err.message,
          placement: 'topRight',
          duration: 1.5
        });

        setLoading(false);
      });

    // show loader
    setLoading(true);
  }

  const title = 'Password Policy';
  const passwordPolicyContent = (
    <React.Fragment>
      <h4>Your password should contain: </h4>
      <ul>
        <li>Minimum length of 8 characters</li>
        <li>Numerical characters (0-9)</li>
        <li>Special characters</li>
        <li>Uppercase letter</li>
        <li>Lowercase letter</li>
      </ul>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <FormWrapper
        onFinish={handleFinish}
        scrollToFirstError
      >
        <div className="text-center">
          <p>Check your email for the confirmation code</p>
        </div>
        <Form.Item name="code" rules={[{ required: true, message: 'Please input your confirmation code!' }]}>
          <Row>
            <Col lg={24}>
              <Input
                prefix={<LockOutlined style={{ color: colors.transparentBlack }} />}
                placeholder="Enter your verification code"
              />
            </Col>
          </Row>
        </Form.Item>

        <Popover placement="right" title={title} content={passwordPolicyContent} trigger="focus">
          <Form.Item
            name="password"
            hasFeedback
            rules={[{ required: true, message: 'Please input your new Password!' }]}
          >
              <Input
                prefix={<LockOutlined style={{ color: colors.transparentBlack }} />}
                type="password"
                placeholder="New Password"
                onBlur={handleBlur}
              />
          </Form.Item>
        </Popover>

        <Form.Item
          name="confirm"
          hasFeedback
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log(`p1:${getFieldValue('password')} p2:${value}`)
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Row>
            <Col lg={24}>
              <Input
                prefix={<LockOutlined style={{ color: colors.transparentBlack }} />}
                type="password"
                placeholder="Confirm Password"
                onBlur={handleBlur}
              />
            </Col>
          </Row>
        </Form.Item>

        <Form.Item className="text-center">
          <Row>
            <Col lg={24}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit" className="login-form-button">
                {loading ? (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                ) : (
                  'Confirm username'
                )}
              </Button>
            </Col>
            <Col lg={24}>
              <Link to="/login">Back to login</Link>
            </Col>
          </Row>
        </Form.Item>
      </FormWrapper>

      {redirect && <Navigate to={{ pathname: '/login' }} />}
    </React.Fragment>
  );
}

export default PasswordResetContainer;