import * as React from 'react';
import {useState} from 'react';
import {Link, Navigate} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {Button, Col, Form, Input, notification, Popover, Row, Spin} from 'antd';

/** App theme */
import {colors} from '../../../Themes/Colors';
import FormWrapper from '../../../Components/Styled/FormWrapper';
import {LoadingOutlined, LockOutlined} from '@ant-design/icons';
import {Store} from 'rc-field-form/es/interface';
import {useUser} from "../../../Store/UserStore";

function UpdatePasswordContainer() {
    const [confirmDirty, setConfirmDirty] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = useUser();


    function handleBlur(event: React.FormEvent<HTMLInputElement>) {
        const value = event.currentTarget.value;
        setConfirmDirty(confirmDirty || !!value);
    }

    function handleFinish(values: Store) {
        console.log(values);
        console.log(user);
        let { password } = values;

        Auth.completeNewPassword(
            user.cognitoUser, // the Cognito User Object
            password, // the new password
            // // OPTIONAL, the required attributes
            // {
            //     email: 'xxxx@example.com',
            //     phone_number: '1234567890'
            // }
        )
            .then((user) => {
                notification.success({
                    message: 'Success!',
                    description: 'Password updated successfully',
                    placement: 'topRight',
                    duration: 1.5,
                    onClose: () => {
                        setRedirect(true);
                    }
                });
            })
            .catch((e) => {
                notification['error']({
                    message: 'Error updating password',
                    description: e.message,
                    placement: 'topRight',
                    duration: 1.5
                });
            });

        // show loader
        setLoading(true);
    }

    const title = 'Password Policy';
    const passwordPolicyContent = (
        <React.Fragment>
            <h4>Your password should contain: </h4>
            <ul>
                <li>Minimum length of 8 characters</li>
                <li>Numerical characters (0-9)</li>
                <li>Special characters</li>
                <li>Uppercase letter</li>
                <li>Lowercase letter</li>
            </ul>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <FormWrapper
                onFinish={handleFinish}
                scrollToFirstError
            >
                <div className="text-center">
                    <p>Your password has expired and needs to be updated</p>
                </div>

                <Popover placement="right" title={title} content={passwordPolicyContent} trigger="focus">
                    <Form.Item
                        name="password"
                        hasFeedback
                        rules={[{required: true, message: 'Please input your new Password!'}]}
                    >
                        <Input
                            prefix={<LockOutlined style={{color: colors.transparentBlack}}/>}
                            type="password"
                            placeholder="New Password"
                            onBlur={handleBlur}
                        />
                    </Form.Item>
                </Popover>

                <Form.Item
                    name="confirm"
                    hasFeedback
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input
                        prefix={<LockOutlined style={{color: colors.transparentBlack}}/>}
                        type="password"
                        placeholder="Confirm Password"
                        onBlur={handleBlur}
                    />
                </Form.Item>

                <Form.Item className="text-center">
                    <Row>
                        <Col lg={24}>
                            <Button style={{width: '100%'}} type="primary" htmlType="submit"
                                    className="login-form-button">
                                {loading ? (
                                    <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
                                ) : (
                                    'Update Password'
                                )}
                            </Button>
                        </Col>
                        <Col lg={24}>
                            <Link to="/login">Back to login</Link>
                        </Col>
                    </Row>
                </Form.Item>
            </FormWrapper>

            {redirect && <Navigate to={{pathname: '/login'}}/>}
        </React.Fragment>
    );
}

export default UpdatePasswordContainer;