import * as React from 'react';
import {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Layout} from 'antd';
import {colors} from '../../../Themes/Colors';
import * as MetadataService from '../../../Services/metadataService';

import MainHeaderContainer from '../MainHeaderContainer';
import AdminSideBarMenu from "./AdminSideBarMenu";
import AdminLogo from "./AdminLogo";
import {logout} from '../../../Services/authService';
import {useUser} from "../../../Store/UserStore";
import {EnrollmentServicesMetaData} from "../../../Model/enrollmentModel";

function DashBoardContainer() {
    const user = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const [metadata, setMetadata] = useState<EnrollmentServicesMetaData>(null);

    useEffect(() => {
        MetadataService.getEnrollmentServicesMetaData().then((data) => {
            setMetadata(data);
        });
    }, [setMetadata]);
    console.log(`DashBoardContainer metadata:${JSON.stringify(metadata)}`);


    let handleLogoutEvent = (event: any) => {
        logout().then(() => {
            navigate("/login");
        }).catch(error => {
            console.log(`${JSON.stringify(error)}`);
        })
    };

    return (
        <Layout className="cover" id="app-header" style={{height: '100vh'}}>
            <Layout.Sider
                className="cover"
                collapsible
                onCollapse={(value) => setCollapsed(value)}
                style={{backgroundColor: colors.background}}
            >
                <AdminLogo collapsed={collapsed}/>
                <AdminSideBarMenu
                    selectedKeys={[location.pathname]}
                    isAdmin={user && user.isAdmin}
                    handleLogout={handleLogoutEvent}
                />
            </Layout.Sider>
            <Layout>
                <Layout.Header className="header" style={{background: colors.white, padding: 0}}>
                    <MainHeaderContainer/>
                </Layout.Header>

                <Layout.Content
                    style={{
                        margin: '10px 10px',
                        //padding: 24,
                        minHeight: 280
                    }}
                >
                    <Outlet/>
                </Layout.Content>
            </Layout>
        </Layout>
    );
}

export default DashBoardContainer;
