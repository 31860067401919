import React, { useState, createContext, useContext, useEffect } from 'react';
import {EnrollmentApplicationConfig} from "../../Model/enrollmentModel";
import {getEnrollmentApplicationConfig} from "../../Services/clientConfigService";

export const EnrollmentApplicationConfigContext = createContext(null);

export default function EnrollmentApplicationConfigContextComp({ children }) {
    const [enrollmentApplicationConfig, setEnrollmentApplicationConfig] = useState<EnrollmentApplicationConfig | null>(null);
    const [ clientFriendlyId, setClientFriendlyId ] = useState();

    useEffect(() => {
        console.log(`EnrollmentApplicationConfigContext clientFriendlyId:${clientFriendlyId}`);
        if (clientFriendlyId) {
            getEnrollmentApplicationConfig(clientFriendlyId).then(config => setEnrollmentApplicationConfig(config));//todo handle errors
        }
    },[clientFriendlyId]);

    return (
        <EnrollmentApplicationConfigContext.Provider value={{ setClientFriendlyId, enrollmentApplicationConfig }}>
            {children}
        </EnrollmentApplicationConfigContext.Provider>
    );
}

export const useEnrollmentApplicationConfigContext = () => useContext(EnrollmentApplicationConfigContext);