import {Button, Card, Checkbox, Col, DatePicker, Form, Input, Row, Select} from "antd";
import {GradeSelect} from "../../../Components/GradeSelect";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {EnrollmentApplicationConfig} from "../../../Model/enrollmentModel";
import {sortGrades} from "../../../Utils/helpers";
import dayjs from "dayjs";
const { Option } = Select;

interface Props {
    enrollmentApplicationConfig: EnrollmentApplicationConfig;
}
const checkGender = (rule, value) => {
    if (value && value.length > 0) {
        return Promise.resolve();
    }
    return Promise.reject('Gender required');
};

const checkGrade = (rule, value) => {
    if (value?.length > 0) {
        return Promise.resolve();
    }
    return Promise.reject('Grade required');
};

function WaitlistEnrollmentStudentStep(props: Props) {
    const [hasEnrolledStudents, setHasEnrolledStudents] = useState<boolean>(false);
    const [isFounder, setIsFounder] = useState<boolean>(false);
    const [isStaff, setIsStaff] = useState<boolean>(false);

    var sortedGrades = sortGrades(props.enrollmentApplicationConfig.grades);
    var gradesList = sortedGrades.map(function(grade: any){
        return <Option key={grade} value={grade}>{grade}</Option>;
    })

    const checkBirthdateSet = (rule, value) => {
        console.log(`checkBirthdateSet - entering rule:${rule} value:${value}`);

        if (value) {
            return Promise.resolve();
        } else {
            console.log('checkBirthdateSet - No birthdate provided')
            return Promise.reject('Birthdate required');
        }
    }

    const checkMinBirthdate = (rule, value) => {
        console.log(`checkMinBirthdate - entering rule:${rule} value:${value}`);

        if (!value) {
            console.log('checkMinBirthdate - No birthdate provided')
            return Promise.resolve();
        }
        if (!props.enrollmentApplicationConfig || !props.enrollmentApplicationConfig.minimumBirthdate) {
            console.log('checkMinBirthdate - valid birthdate (No minimumBirthdate set)')
            return Promise.resolve();
        }

        console.log(`checkMinBirthdate - minimumBirthdate:${props.enrollmentApplicationConfig.minimumBirthdate}`)
        let minBirthdate = dayjs(props.enrollmentApplicationConfig.minimumBirthdate);
        let birthdate = dayjs(value, "ddd, DD MMM YYYY HH:mm:ss");
        console.log(`checkMinBirthdate - birthdate:${birthdate} minBirthdate:${minBirthdate}`)
        let isBirthdateValid = birthdate.isBefore(minBirthdate, 'day') || birthdate.isSame(minBirthdate, 'day'); //Couldn't get isSameOrBefore plugin to compile

        if (isBirthdateValid) {
            console.log('checkMinBirthdate - valid birthdate (prior to minimumBirthdate)')
            return Promise.resolve();
        } else {
            console.log('checkMinBirthdate - birthdate after minimumBirthdate')
            return Promise.reject(`Students must be born before ${minBirthdate} to be eligible for enrollment`);
        }
    }

    return (<React.Fragment>
        <Card title="New Students" style={{ width: '100%' }}>
            <Form.List name="students">
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            {fields.map((field, index) => (
                                <Row gutter={8} key={field.key}>
                                    <Col span={5}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'firstName']}
                                            label={index === 0 ? 'First Name' : ''}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "First name required",
                                                },
                                            ]}
                                            required={false}
                                        >
                                            <Input style={{ width: '100%' }}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'lastName']}
                                            label={index === 0 ? 'Last Name' : ''}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Last name required",
                                                },
                                            ]}
                                            required={false}
                                        >
                                            <Input style={{ width: '100%' }}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'gender']}
                                            label={index === 0 ? `Gender` : ''}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[{ validator: checkGender, message: 'Gender required' }]}
                                            required={false}
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                options={[
                                                    {
                                                        value: '',
                                                        label: '',
                                                    },
                                                    {
                                                        value: 'F',
                                                        label: 'F',
                                                    },
                                                    {
                                                        value: 'M',
                                                        label: 'M',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'birthDate']}
                                            label={index === 0 ? 'Birthdate' : ''}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                { validator: checkBirthdateSet, message: "Birthdate required"},
                                                { validator: checkMinBirthdate, message: `Students born after ${dayjs(props.enrollmentApplicationConfig.minimumBirthdate).format('MMM D, YYYY')} are not eligible for enrollment`}
                                            ]}
                                            required={false}
                                        >
                                            <DatePicker style={{ width: '100%' }}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'grade']}
                                            label={index === 0 ? `Grade (${props.enrollmentApplicationConfig.enrollmentPeriod})` : ''}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[{ validator: checkGrade, message: 'Grade required' }]}
                                            required={false}
                                        >
                                            <GradeSelect id={`grade_${field.name}`} gradesList={gradesList} enrollmentPeriod={props.enrollmentApplicationConfig.enrollmentPeriod}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        {fields.length > 1 ? (
                                            <Form.Item
                                                {...field}
                                                label={index === 0 ? ' ' : ''}
                                                style={{ width: '100%' }}
                                                required={false}
                                            >
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    style={{ margin: '0 8px' }}
                                                    onClick={() => {
                                                        remove(field.name);
                                                    }}
                                                />
                                            </Form.Item>
                                        ) : null}
                                    </Col>
                                </Row>
                            ))}
                            <Row>
                                <Col span={7}>
                                    <Form.Item>
                                        <Button
                                            id="add-student-btn"
                                            type="dashed"
                                            onClick={() => {
                                                add();
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            <PlusOutlined /> Add Student
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col span={17}></Col>
                            </Row>
                        </div>
                    );
                }}
            </Form.List>
        </Card>

        <Card style={{ width: '100%' }}>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        name="hasEnrolledStudents"
                        valuePropName="checked">
                        <Checkbox onChange={e => setHasEnrolledStudents(e.target.checked)}>I currently have a student enrolled at Heritage Heights Academy</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            {hasEnrolledStudents &&
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="enrolledStudentsIds"
                            label="Student name(s)"
                            rules={[
                                {
                                    required: true,
                                    message: "Student names required",
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>}
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        name="isStaff"
                        valuePropName="checked">
                        <Checkbox onChange={e => setIsStaff(e.target.checked)}>Student(s) related to school staff member</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        name="isFounder"
                        valuePropName="checked">
                        <Checkbox onChange={e => setIsFounder(e.target.checked)}>Student(s) related to school Board Member, past or present</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            {(isFounder || isStaff) &&
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="staffOrFounderName"
                            label="Staff or Board member's name"
                            rules={[
                                {
                                    required: true,
                                    message: "Staff or Board member's name required",
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>}
        </Card>
    </React.Fragment>)
}

export default WaitlistEnrollmentStudentStep