import React, {useEffect, useState} from 'react';
import { Family } from '../../../Model/enrollmentModel';
import SearchableTableComponent from "../../../Components/SearchableTableComponent";
import {getFamilyTableColumns} from "../TableColumns/TableColumnFactory";
import {useUser} from "../../../Store/UserStore";

const SEARCH_EXCLUDED_FAMILY_KEYS = ["id","clientFriendlyId","type","primary","familyId"];

interface IFamilyTableProps {
    families: Family[];
    onFamilySelected: (family: Family) => void;
}

function FamilyTableComponent(props: IFamilyTableProps) {
    const user = useUser();
    const [ columns, setColumns ] = useState<any[]>([]);

    useEffect(() => {
        setColumns(getFamilyTableColumns(user.clientConfig));
    }, [user.clientConfig]);

    return (
      <React.Fragment>
        <SearchableTableComponent
          rowKey={(record => {return record.id})}
          dataSource={props.families}
          excludedKeys={SEARCH_EXCLUDED_FAMILY_KEYS}
          columns={columns}
          bordered
          pagination={{ 
            position: ["topRight"],
            showSizeChanger: true,
            defaultPageSize: 20
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => props.onFamilySelected(record),
              onDoubleClick: () => props.onFamilySelected(record),
              onContextMenu: event => {}, // right button click row
            };
          }}
		    />
      </React.Fragment>
    );
}

export default FamilyTableComponent;