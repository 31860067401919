import {Layout} from "antd";

const { Header } = Layout;

const headerStyle = {
    background: "#f0f2f5",
    font:"American Typewriter",
    fontSize: "30px"
};

const MainHeader = props => {
    return (
        <Header
            style={headerStyle}
        >Enroll With It</Header>
    );
};

export default MainHeader;