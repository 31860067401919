import React, {useEffect, useState} from "react";
import {Student} from "../../../Model/enrollmentModel";
import SearchableTableComponent from "../../../Components/SearchableTableComponent";
import {getWaitlistTableColumns} from "../../Components/TableColumns/TableColumnFactory";
import {useUser} from "../../../Store/UserStore";

const SEARCH_EXCLUDED_STUDENT_KEYS = ["id","clientFriendlyId","clientId","familyId"];

interface Props {
    waitlist: Student[],
    onStudentSelected: (Student) => void,
    statusFilter: string[],
}

function WaitlistOverviewTable(props: Props) {
    const user = useUser();
    const [ columns, setColumns ] = useState<any[]>([]);

    useEffect(() => {
        let columns: any = getWaitlistTableColumns(user.clientConfig, props.statusFilter);
        setColumns(columns);
    }, [user.clientConfig, props.statusFilter]);

    return (<SearchableTableComponent
        dataSource={props.waitlist}
        excludedKeys={SEARCH_EXCLUDED_STUDENT_KEYS}
        columns={columns}
        rowClassName={() => 'editable-row'}
        pagination={{
            position: ["topRight"],
            showSizeChanger: true,
            defaultPageSize: 50
        }}
        onRow={(record, rowIndex) => {
            return {
                onClick: () => props.onStudentSelected(record), // click row
                onDoubleClick: () => props.onStudentSelected(record), // double click row
                onContextMenu: event => {}, // right button click row
            };
        }}
        bordered
    />);
}

export default WaitlistOverviewTable;