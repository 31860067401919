import * as React from 'react';
import {Col, List, Card, Layout, Row, Avatar, Tag} from 'antd';
import {PageHeader} from '@ant-design/pro-layout';
import {EnrollmentApplicationStatus} from "../../../Model/enrollmentModel";
import {getApplicationStatus} from "../../../Services/enrollmentApplicationServices";

/** Presentational */
import {CheckOutlined, UserOutlined} from "@ant-design/icons";
import {mainContentStyle} from "../../../Themes/Styles";
import MainHeader from "../../../Components/MainHeader";
import MainFooter from "../../../Components/MainFooter";
import {
    ACCEPTED_STATUS,
    CLOSED_ENROLLMENT_POST,
    CLOSED_ENROLLMENT_PRE, DECLINED_STATUS, DUPLICATE_STATUS, ENROLLED_STATUS, MISSED_DEADLINE_STATUS,
    OPEN_ENROLLMENT_POST_LOTTERY,
    OPEN_ENROLLMENT_PRE_LOTTERY, WAITLIST_STATUS, WITHDRAWN_STATUS
} from "../../../Utils/constants";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";

const {Content} = Layout;

function renderWaitlistStatus(status) {
    if (status === ENROLLED_STATUS) {
        return <Tag color="green">{ENROLLED_STATUS}</Tag>;
    } else if (status === ACCEPTED_STATUS) {
        return <Tag color="green">{ACCEPTED_STATUS}</Tag>;
    } else if (status === WAITLIST_STATUS) {
        return <Tag color="blue" icon={<CheckOutlined/>}>On {WAITLIST_STATUS}</Tag>;
    } else if (status === WITHDRAWN_STATUS) {
        return <Tag color="orange">{WITHDRAWN_STATUS}</Tag>;
    } else if (status === DECLINED_STATUS) {
        return <Tag color="orange">{DECLINED_STATUS}</Tag>;
    } else if (status === DUPLICATE_STATUS) {
        return <Tag color="orange">{DUPLICATE_STATUS}</Tag>;
    } else if (status === MISSED_DEADLINE_STATUS) {
        return <Tag color="orange">{MISSED_DEADLINE_STATUS}</Tag>;
    }
    return <div/>;
}

function getFooter(clientFriendlyId, confirmationNumber) {
    return (
        <Row>
            <Col span={6}>Confirmation # {confirmationNumber}</Col>
            <Col span={13}></Col>
            <Col span={5}>
                <Link id="update-enrollment-application"
                      to={`/${clientFriendlyId}/enroll`}
                      state={{confirmationNumber: confirmationNumber}}
                >Update Application</Link>
            </Col>
        </Row>

    )
}

function renderItem(item, enrollmentPeriodStatus) {
    let showWaitlistPosition = enrollmentPeriodStatus === OPEN_ENROLLMENT_POST_LOTTERY && item.waitlistPosition && item.waitlistPosition > 0;

    return <List.Item>
        <Col span={20}>
            <List.Item.Meta
                avatar={<Avatar shape="square" size="large" icon={<UserOutlined/>}/>}
                title={`${item.lastName}, ${item.firstName}`}
                description={`Grade: ${item.grade}`}
            />
        </Col>
        <Col span={4}>
            {showWaitlistPosition &&
                //<Statistic title="Waitlist Position" value={item.waitlistPosition} prefix={<NumberOutlined />} />
                <Tag color="blue">#{item.waitlistPosition} On {WAITLIST_STATUS}</Tag>
            }
            {!showWaitlistPosition && renderWaitlistStatus(item.status)}
        </Col>
    </List.Item>
}

function renderAppInfo(enrollmentApplicationStatus) {
    let enrollmentPeriodInfo;

    if (enrollmentApplicationStatus.enrollmentPeriodStatus === CLOSED_ENROLLMENT_PRE) {
        enrollmentPeriodInfo = <Row><Col span={24}>Open enrollment for this school year will begin soon!</Col></Row>
    } else if (enrollmentApplicationStatus.enrollmentPeriodStatus === OPEN_ENROLLMENT_PRE_LOTTERY) {
        enrollmentPeriodInfo = <Row><Col span={24}>Open enrollment has begun!</Col></Row>
    } else if (enrollmentApplicationStatus.enrollmentPeriodStatus === OPEN_ENROLLMENT_POST_LOTTERY) {
        enrollmentPeriodInfo = <Row><Col span={24}>Enrollment is currently open</Col></Row>
    } else if (enrollmentApplicationStatus.enrollmentPeriodStatus === CLOSED_ENROLLMENT_POST) {
        enrollmentPeriodInfo = <Row><Col span={24}>Enrollment for this school year is currently closed</Col></Row>
    } else {
        enrollmentPeriodInfo = <Row><Col span={24}>Enrollment for this school year is currently closed</Col></Row>
    }

    return <Col span={24} style={{fontSize: 14, color: '#0f1c30', fontWeight: "bold"}}>
        {enrollmentPeriodInfo}
    </Col>
}

function ApplicationStatusContainer() {
    let {clientFriendlyId, confirmationNumberParam} = useParams()
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let [enrollmentApplicationStatus, setEnrollmentApplicationStatus] = useState<EnrollmentApplicationStatus | null>(null);
    let [error, setError] = useState<string>(null);
    let [confirmationNumber, setConfirmationNumber] = useState<string | null>(null);

    useEffect(() => {
        if (confirmationNumberParam) {
            setConfirmationNumber(confirmationNumberParam)
        } else if (searchParams) {
            setConfirmationNumber(searchParams.get("confirmationNumber") ?? null)
        } else {
            navigate(`/${clientFriendlyId}/application/lookup`)
        }
    }, [clientFriendlyId, confirmationNumberParam, searchParams, navigate])

    useEffect(() => {
        if (clientFriendlyId && confirmationNumber) {
            getApplicationStatus(clientFriendlyId, confirmationNumber).then((status: EnrollmentApplicationStatus) => {
                setEnrollmentApplicationStatus(status)
            }).catch(error => {
                setError(error);
            });
        }
    }, [clientFriendlyId, confirmationNumber])

    if (!enrollmentApplicationStatus) {
        return (<></>);
    } else if (error) {
        return (<></>); //TODO implement error page
    }

    return (
        <Layout style={{height: '100vh'}}>
            <MainHeader/>
            <Content>
                <Col span={24}>
                    <Row justify="center" align="middle" style={{minHeight: '100vh', paddingBottom: '400px'}}>
                        <Card style={mainContentStyle}>
                            <PageHeader
                                className="site-page-header"
                                title={`${enrollmentApplicationStatus.schoolDisplayName} Enrollment Application Status`}
                                subTitle={`${enrollmentApplicationStatus.schoolYear} School Year`}
                            />
                            <List
                                style={{margin: '20px'}}
                                size="large"
                                header={renderAppInfo(enrollmentApplicationStatus)}
                                footer={getFooter(clientFriendlyId, confirmationNumber)}
                                bordered
                                dataSource={enrollmentApplicationStatus.students}
                                renderItem={item => renderItem(item, enrollmentApplicationStatus.enrollmentPeriodStatus)}
                            />
                        </Card>
                    </Row>
                </Col>
            </Content>
            <MainFooter/>
        </Layout>
    );
}

export default ApplicationStatusContainer;
