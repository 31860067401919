import * as React from "react";
import {useRef, useState} from "react";
import {Button, Col, Form, notification, Radio, Row, Spin} from "antd";
import EnrollmentCreationManualConfigComponent from "./EnrollmentCreationManualConfigComponent";
import EnrollmentCreationReoccuringConfigComponent from "./EnrollmentCreationReoccuringConfigComponent";
import {EnrollmentPeriodCreationConfig} from "../../../Model/enrollmentModel";
import * as EnrollmentPeriodServices from "../../../Services/EnrollmentPeriodsService";
import {useUser} from "../../../Store/UserStore";
import {LoadingOutlined} from "@ant-design/icons";
import {FormInstance} from "antd/lib/form";
import {parseTimestamp} from "../../../Utils/DateUtils";
import SettingsFormWrapper from "../../../Components/Styled/SettingsFormWrapper";

interface Props {
}

// let options = [
//     {label: 'Reoccuring', value: 'REOCCURRING'},
//     {label: 'Manual', value: 'MANUAL'}
// ]

function getFormValues(enrollmentPeriodCreationConfig: EnrollmentPeriodCreationConfig) {
    return {
        ...enrollmentPeriodCreationConfig,
        startDate: parseTimestamp(enrollmentPeriodCreationConfig.startDate),
        endDate: parseTimestamp(enrollmentPeriodCreationConfig.endDate),
        lotteryDate: parseTimestamp(enrollmentPeriodCreationConfig.lotteryDate),
    }
}

function EnrollmentCreationConfigComponent(props: Props) {
    let user = useUser()
    let [curEnrollmentPeriodCreationConfig, setCurEnrollmentPeriodCreationConfig] = React.useState<any>(getFormValues(user.clientConfig.enrollmentPeriodConfig.enrollmentPeriodCreationConfig))
    const [isEdited, setIsEdited] = useState(false);
    const [loading, setLoading] = useState(false);
    const formRef = useRef<FormInstance>();

    function updateFormValues(enrollmentPeriodCreationConfig: EnrollmentPeriodCreationConfig) {
        let values = getFormValues(enrollmentPeriodCreationConfig)
        setCurEnrollmentPeriodCreationConfig(values)
        formRef.current.setFieldsValue(values)
    }

    let onCancel = () => {
        console.log(`settings - onCancel resetting form fields to user.clientConfig values`)
        setIsEdited(false);
        updateFormValues(user.clientConfig.enrollmentPeriodConfig.enrollmentPeriodCreationConfig)
    }

    function onFinish(values: any) {
        console.log(`onFinish: ${JSON.stringify(values)} `)
        setLoading(true);
        EnrollmentPeriodServices.updateEnrollmentPeriodCreationConfig(user.clientConfig.clientId, values as EnrollmentPeriodCreationConfig).then((response) => {
            notification.success({
                message: 'Enrollment Period Settings Saved',
                placement: 'topRight',
                duration: 1.5
            });
            setIsEdited(false);
        }).catch((error) => {
            notification.error({
                message: 'Unable to save Enrollment Period Settings',
                placement: 'topRight',
                duration: 1.5
            });
        }).finally(() => {
            setLoading(false);
        })
    }


    return (<React.Fragment>
        <SettingsFormWrapper
            onFinish={onFinish}
            initialValues={curEnrollmentPeriodCreationConfig}
            onValuesChange={(changedValues, allValues) => {
                isEdited || setIsEdited(true);
                setCurEnrollmentPeriodCreationConfig(allValues)
            }}
            layout='vertical'
            ref={formRef}
        >
            <Form.Item
                label="Enrollment Period Creation Mode"
                tooltip={"Reoccuring: Automatically create enrollment periods on a schedule. Manual: Create enrollment periods manually."}
                name='mode'
                rules={[{required: true}]}>
                {/*<Segmented options={options}/>*/}
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value="REOCCURRING">Reoccuring</Radio.Button>
                    <Radio.Button value="MANUAL" disabled={true}>Manual</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {curEnrollmentPeriodCreationConfig.mode === 'REOCCURRING' && (<EnrollmentCreationReoccuringConfigComponent/>)}
            {curEnrollmentPeriodCreationConfig.mode === 'MANUAL' && (
                <EnrollmentCreationManualConfigComponent addEnrollmentPeriod={() => {
                    console.log(`addEnrollmentPeriod`) //TODO implement me
                }}/>)}
            <Form.Item className="text-center">
                <Row gutter={8}>
                    <Col span={20}/>
                    <Col span={2}>
                        <Button style={{width: '100%'}} disabled={loading || !isEdited} onClick={onCancel}
                                htmlType="button">Cancel</Button>
                    </Col>
                    <Col span={2}>
                        <Button style={{width: '100%'}} type="primary" disabled={loading || !isEdited}
                                htmlType="submit">
                            {loading ? <Spin indicator={<LoadingOutlined type="loading" style={{fontSize: 24}}
                                                                         spin/>}/> : 'Save'}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </SettingsFormWrapper>


    </React.Fragment>)
}

export default EnrollmentCreationConfigComponent