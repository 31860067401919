import * as React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import EnrollmentApplicationConfigContextProvider from "../publicSite/context/EnrollmentApplicationConfigContext";

/** Container components */
import ApplicationConfirmationComponent from '../publicSite/applications/ApplicationConfirmationComponent';
import ConfirmEmailContainer from '../adminPortal/ConfirmEmailComponent';
import DashBoardContainer from '../adminPortal/Containers/DashboardContainer';
import WaitlistEnrollmentContainer from '../publicSite/Containers/WaitlistEnrollmentContainer';
import ForgotPasswordContainer from '../adminPortal/Containers/ForgotPasswordContainer';
import LoginContainer from '../adminPortal/Containers/LoginContainer';
import PasswordResetContainer from '../adminPortal/Containers/PasswordResetContainer';
import SignUpContainer from '../adminPortal/Containers/SignUpContainer';
import ApplicationLookupContainer from '../publicSite/Containers/ApplicationLookupContainer';
import ApplicationLookupHelpContainer from '../publicSite/Containers/ApplicationLookupHelpContainer';
import ApplicationLookupConfirmationContainer from '../publicSite/Containers/ApplicationLookupConfirmation';
import ApplicationStatusContainer from '../publicSite/Containers/ApplicationStatusContainer';

/** private route component */
import RequireAuth from "./RequireAuth";
import AdminHomePageContainer from "../adminPortal/Containers/AdminHomePageContainer";
import WaitlistOverviewContainer from "../adminPortal/Containers/WaitlistOverviewContainer";
import FamiliesContainer from "../adminPortal/Containers/FamiliesContainer";
import DeletedFamiliesContainer from "../adminPortal/Containers/DeletedFamiliesContainer";
import StudentsContainer from "../adminPortal/Containers/StudentsContainer";
import RolloverWaitlistContainer from "../adminPortal/Containers/RolloverWaitlistContainer";
import SettingsContainer from "../adminPortal/Containers/SettingsContainer";
import AdminContainer from "../adminPortal/Containers/AdminContainer";
import RequireAdmin from "./RequireAdmin";
import UpdatePasswordContainer from "../adminPortal/Containers/LoginContainer/updatePassword";
import EnrollmentPeriodSettingsContainer from "../adminPortal/Containers/EnrollmentPeriodSettingsContainer";
import HomePageContainer from "../publicSite/Containers/HomePageContainer";

function AppRouter() {
    let isAdminPortal = window.location.host.split('.')[0] === 'admin';
    let publicRoutes = !isAdminPortal;
    let adminPortalRoutes = isAdminPortal;

    console.log(`AppRouter isAdminPortal:${isAdminPortal}`);
    if (publicRoutes) {
        return (
            <React.Fragment>
                <EnrollmentApplicationConfigContextProvider>
                    <Routes>
                        <Route path="/" element={<HomePageContainer/>}/>
                        <Route path="/:clientFriendlyId/enroll" element={<WaitlistEnrollmentContainer/>}/>
                        <Route path="/:clientFriendlyId/application/lookup" element={<ApplicationLookupContainer/>}/>
                        <Route path="/:clientFriendlyId/application/lookup-help" element={<ApplicationLookupHelpContainer/>}/>
                        <Route path="/:clientFriendlyId/application/lookup-confirmation" element={<ApplicationLookupConfirmationContainer/>}/>
                        <Route path="/:clientFriendlyId/application/confirmation" element={<ApplicationConfirmationComponent/>}/>
                        <Route path="/:clientFriendlyId/application/status" element={<ApplicationStatusContainer/>}/>
                        <Route path="/:clientFriendlyId/application/:confirmationNumberParam/status" element={<ApplicationStatusContainer/>}/>
                        <Route path="" element={<Navigate to="/" />} />
                    </Routes>
                </EnrollmentApplicationConfigContextProvider>
            </React.Fragment>
        );
    }
    if (adminPortalRoutes) {
        return (
            <React.Fragment>
                <Routes>
                    <Route path="/login" element={<LoginContainer/>}/>

                    <Route element={<RequireAuth><DashBoardContainer/></RequireAuth>}>
                        <Route path="/admin" element={<RequireAdmin><AdminContainer/></RequireAdmin>} />
                        <Route path="/dashboard" element={<AdminHomePageContainer/>}/>
                        <Route path="/deletedFamilies" element={<DeletedFamiliesContainer/>} />
                        <Route path="/families" element={<FamiliesContainer/>} />
                        <Route path="/rolloverWaitlist" element={<RequireAdmin><RolloverWaitlistContainer/></RequireAdmin>} />
                        <Route path="/students" element={<StudentsContainer/>} />
                        <Route path="/settings" element={<SettingsContainer/>} />
                        <Route path="/waitlist" element={<WaitlistOverviewContainer/>} />
                        <Route path="/enrollmentPeriods" element={<EnrollmentPeriodSettingsContainer/>} />
                    </Route>

                    <Route path="/signup" element={<SignUpContainer/>}/>
                    <Route path="/verify-code" element={<ConfirmEmailContainer/>}/>
                    <Route path="/reset-password" element={<PasswordResetContainer/>}/>
                    <Route path="/forgot-password" element={<ForgotPasswordContainer/>}/>
                    <Route path="/update-password" element={<UpdatePasswordContainer />} />

                    <Route path="" element={<Navigate to="/login" />} />
                </Routes>
            </React.Fragment>
        );
    }
}

export default AppRouter;
