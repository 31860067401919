/**
 * searches deep into an object recursively...
 * @param {Object} obj object to be searched
 * @param {any} searchValue the value/key to search for
 * @param {boolean} [searchKeys] whether to search object keys as well as values. Defaults to `true` if `serchValue` is a string, `false` otherwise.
 * @returns {string[]} Paths on the object to the matching results
 */
export function findPaths(
    obj,
    searchValue,
    excludedKeys = []
  ) {
    const paths = []
    const visited = new Set()
    searchValue = searchValue.toLowerCase();
    const gvpio = (obj, prefix) => {
        if (obj) {
            for (const [curr, currElem] of Object.entries(obj)) {
                if (!excludedKeys.includes(curr)) {
                    if (typeof currElem === "object") {
                        if (visited.has(currElem)) continue
                        visited.add(currElem)
                        gvpio(currElem, prefix + curr + "/")
                        continue
                    }
                    if (`${currElem}`.toLowerCase().includes(searchValue)) {
                        paths.push(prefix + curr)
                    }
                }
            }
        }
    }
    gvpio(obj, "")
    console.log(`findPaths found: ${paths}`);
    return paths
  }