import {Card, Checkbox, Col, Form, Input, Row} from "antd";
import AddressAutocompleteInput from "../../../Components/AddressAutocompleteInput";
import {PhoneNumberInput} from "../../../Components/PhoneNumberInput";
import React, {useState} from "react";


interface Props {
    isPrimary: boolean
    formRef: any
}

const checkPhoneNumber = (rule, value) => {
    console.log(`checkPhoneNumber: value:${JSON.stringify(value)}`);
    if (value && value.length > 0) {
        value = value.replace(/\D/g, '');
        if (value.number.length === 10) {
            return Promise.resolve();
        } else {
            return Promise.reject('Invalid phone number');
        }
    } else {
        return Promise.resolve();
    }
};

const checkRequiredPhoneNumber = (rule, value) => {
    console.log(`checkPhoneNumber: value:${JSON.stringify(value)}`);
    if (value && value.number && value.number.length > 0) {
        let number = value.number.replace(/\D/g, '');
        console.log(`number:${number}`);
        if (number.length === 10) {
            return Promise.resolve();
        } else {
            return Promise.reject('Invalid phone number');
        }
    } else {
        return Promise.reject('Phone number required');
    }
};

function WaitlistGuardianComponent(props: Props) {
    const [secondaryAddressSameAsPrimary, setSecondaryAddressSameAsPrimary] = useState<boolean>(false);

    function onSecondaryAddressSameAsPrimaryChange(e) {
        setSecondaryAddressSameAsPrimary(e.target.checked);
    }

    let guardianKey = props.isPrimary ? "primaryGuardian" : "secondaryGuardian"
    let isRequired = props.isPrimary

    return (
        <Card title={props.isPrimary ? "Primary Guardian" : "Secondary Guardian (optional)"} style={{width: '100%'}}>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        name={[guardianKey, "firstName"]}
                        label="First Name"
                        rules={[{required: isRequired, message: 'First name required'}]}>
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[guardianKey, "lastName"]}
                        label="Last Name"
                        rules={[{required: isRequired, message: 'Last name required'}]}>
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
            {!props.isPrimary && <Row>
                <Col span={24}>
                    <Form.Item
                        name="secondaryAddressSameAsPrimary"
                        valuePropName="checked">
                        <Checkbox onChange={onSecondaryAddressSameAsPrimaryChange}>Address same as primary
                            guardian</Checkbox>
                    </Form.Item>
                </Col>
            </Row>}
            {(props.isPrimary || (!props.isPrimary && !secondaryAddressSameAsPrimary)) &&
                <React.Fragment>
                    <Row gutter={8}>
                        <Col span={20}>
                            <Form.Item
                                name={[guardianKey, "address", "addressLine1"]} //address
                                label="Address"
                                rules={[{required: isRequired, message: 'Address required'}]}
                                shouldUpdate={(p, c) => p !== c}
                            >
                                <AddressAutocompleteInput prefix={`${guardianKey}`} formRef={props.formRef}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name={[guardianKey, "address", "addressLine2"]} //subpremise
                                label="Apt/Suite"
                                shouldUpdate={(p, c) => p !== c}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={16}>
                            <Form.Item
                                name={[guardianKey, "address", "city"]}
                                label="City"
                                rules={[{required: isRequired, message: 'City required'}]}
                                shouldUpdate={(p, c) => p !== c}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name={[guardianKey, "address", "state"]}
                                label="State"
                                rules={[{required: isRequired, message: 'State required'}]}
                                shouldUpdate={(p, c) => p !== c}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name={[guardianKey, "address", "zipCode"]}
                                label="Zipcode"
                                rules={[{required: isRequired, message: 'Zipcode required'}]}
                                shouldUpdate={(p, c) => p !== c}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                </React.Fragment>
            }
            <Row gutter={8}>
                <Form.List name={[guardianKey, "phoneNumbers"]}>
                    {(fields, {add, remove}) => {
                        return (
                            fields.map((field, index) => {
                                    return (
                                        <React.Fragment>
                                            <Col span={12}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name]}
                                                    label={index === 0 ? "Primary Phone Number" : "Alternate Phone Number"}
                                                    rules={[{
                                                        required: isRequired && index === 0,
                                                        message: 'Valid phone number required'
                                                    }, {validator: (props.isPrimary && index === 0) ? checkRequiredPhoneNumber : checkPhoneNumber}]}
                                                    validateTrigger={['onChange']}
                                                >
                                                    <PhoneNumberInput id={`${guardianKey}_phoneNumber_${index}`}/>
                                                </Form.Item>
                                            </Col>
                                        </React.Fragment>
                                    )
                                }
                            )
                        )
                    }
                    }
                </Form.List>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        name={[guardianKey, "email"]}
                        label="Email"
                        rules={[{type: 'email', message: 'The input is not valid E-mail!',}, {
                            required: isRequired,
                            message: 'Email required'
                        }]}>
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    )
}

export default WaitlistGuardianComponent