import {Family, Student} from "../Model/enrollmentModel";
import {v4 as uuid} from "uuid";

export function createNewStudent(schoolYearId: string): Student {
    return {
        familyId: null,
        id: null,
        firstName: null,
        lastName: null,
        gender: null,
        birthDate: null,
        grade: null,
        status: null,
        notes: null,
        tags: [],
        districtStatus: null,
        createdTimestamp: null,
        acceptedDate: null,
        schoolYearId: schoolYearId,
        deletedInd: false,
        contactInfo: null,
        lotteryRank: null,
        waitlistPosition: null,
        siblingDate: null,
    } as Student;
}


export function createNewStudentForFamily(family: Family): Student {
    return {
        id: uuid(),
        familyId: family.id,
        firstName: null,
        lastName: null,
        birthDate: null,
        grade: null,
        status: null,
        tags: family['familyTags'] ? family['familyTags'] : [],
        notes: null,
        districtStatus: family.districtStatus,
        createdTimestamp: null,
        acceptedDate: null,
        schoolYearId: family.schoolYearId,
        deletedInd: false,
        contactInfo: null,
        lotteryRank: null,
        waitlistPosition: null,
        siblingDate: null,
    } as Student;
}