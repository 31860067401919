import authManager from "../Auth/authManager";
import {Family} from "../Model/enrollmentModel";
import {decorateFamilies, decorateFamily} from "../Utils/helpers";

const apiName = "enrollmentApi";
const path = `/families`;
const recalcPath = `/families/recalculateDistrictStatus`;

export async function getFamilies(clientId, schoolYearId): Promise<Family[]> {
    console.log(`getFamilies`);
    const myInit = {
        queryStringParameters: {
            clientId: clientId,
            schoolYearId: schoolYearId
        },
    }

    let families = await authManager.apiGet<Family[]>(apiName, path, myInit);

    decorateFamilies(families);
    return families;
}

export async function getFamily(clientId, familyId, schoolYearId): Promise<Family> {
    const myInit = {
        queryStringParameters: {
            clientId: clientId,
            familyId: familyId,
            schoolYearId: schoolYearId
        },
    }
    let family = await authManager.apiGet<Family>(apiName,path, myInit);
    decorateFamily(family);
    return family;
}

export async function getDeletedFamilies(clientId, schoolYearId): Promise<Family[]> {
    console.log(`getFamilies`);
    const myInit = {
        queryStringParameters: {
            clientId,
            deletedInd: true,
            schoolYearId: schoolYearId
        },
    }

    let families = await authManager.apiGet<Family[]>(apiName, path, myInit);

    decorateFamilies(families);
    return families;
}

export async function saveFamily(family: Family) {
    //remove display keys
    delete family['statusSummary'];
    delete family['guardianSummary'];
    delete family['studentSummary'];
    delete family['tagsSummary'];
    console.log(`saveFamily family:${JSON.stringify(family)}`);
    const myInit = {
        body: family
    }

    let updatedFamily = await authManager.apiPost<Family>(apiName, path, myInit);
    decorateFamily(updatedFamily);
    return updatedFamily;
}

export async function recalculateFamilyDistrictStatus(clientId: string, schoolYearId: string, familyId: string) {
    console.log(`recalculateFamilyDistrictStatus`);
    const myInit = {
        body: {
            clientId: clientId,
            schoolYearId: schoolYearId,
            familyId: familyId
        },
    }

    let response = await authManager.apiPost(apiName, recalcPath, myInit);
    console.log(`recalculateFamilyDistrictStatus: ${JSON.stringify(response)}`);
    return response;
}