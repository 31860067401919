import {Auth} from "aws-amplify";
import {AUTH_USER_TOKEN_KEY} from "../Utils/constants";

export function logout() {
    return new Promise((resolve, reject) => {
        Auth.signOut({ global: true }).then(() => {
            localStorage.removeItem(AUTH_USER_TOKEN_KEY);
            resolve(null);
        }).catch(error => {
            reject(error);
        });
    });
};