import {Auth, API} from 'aws-amplify';
import {AUTH_USER_TOKEN_KEY} from '../Utils/constants';
import jwtDecode from "jwt-decode";

class AuthManager {

    isAuthenticated = () => {
        return this.validateToken();
    }

    apiGet = async <T>(api, path, init) => {
        await this.validateSession();
        return await API.get(api, path, init) as T;
    }

    apiPost = async <T>(api, path, init) => {
        await this.validateSession();
        return await API.post(api, path, init) as T;
    }

    apiPut = async <T>(api, path, init) => {
        await this.validateSession();
        return await API.put(api, path, init) as T;
    }

    apiDelete = async <T>(api, path, init) => {
        await this.validateSession();
        return await API.del(api, path, init) as T;
    }

    validateToken = (): boolean => {
        let token = localStorage.getItem(AUTH_USER_TOKEN_KEY);
        if (!token) {
            console.log(`validateToken: no token`);
            return false;
        }
        try {
            const decodedJwt: any = jwtDecode(token);
            let valid = decodedJwt.exp >= Date.now() / 1000;
            console.log(`validateToken: valid:${valid}`);
            return valid;
        } catch (e) {
            console.log(`validateToken: exception:${e}`);
            return false;
        }
    };

    validateSession = async () => {
        console.log(`validateSession`);
        if (this.validateToken()) {
            console.log(`validateSession:true`);
            return true;
        } else {
            let user = await Auth.currentSession();
            localStorage.setItem(AUTH_USER_TOKEN_KEY, user.getAccessToken().getJwtToken());
            console.log(`validateSession:true`);
            return true;
        }
    }
}

const authManager = new AuthManager();

export default authManager;