import {Button, Card, Col, Form, Input, Row, Spin} from "antd";
import React, {useEffect, useRef, useState} from "react";
import SettingsFormWrapper from "../../../Components/Styled/SettingsFormWrapper";
import {Store} from 'rc-field-form/es/interface';
import {createClientConfig} from "../../../Services/clientConfigService";
import {useUser} from "../../../Store/UserStore";
import {ClientConfig, ClientDesc, Organization} from "../../../Model/enrollmentModel";
import {LoadingOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {saveOrganization} from "../../../Services/organizationsService";
import {FormInstance} from "antd/lib/form";


function OrganizationManagerComponent() {
    let user = useUser()
    const orgFormRef = useRef<FormInstance>();
    const [loading, setLoading] = useState<boolean>(false)
    const [initialOrgFormValues, setInitialOrgFormValues] = useState<Store | null>()

    useEffect(() => {
        let initialOrgFormValues = {
            organizationId: user.organization.organizationId,
            organizationName: user.organization.name,
            organizationClients: user.organization.clients,
        }
        setInitialOrgFormValues(initialOrgFormValues)
        orgFormRef.current.setFieldsValue(initialOrgFormValues)
    },[user.organization, orgFormRef])

    async function handleCreateClientFinish(values: Store) {
        let clientConfig: ClientConfig = {
            clientFriendlyId: values.clientFriendlyId,
            clientId: null,
            contactInformation: null,
            districtId: null,
            districtStatuses: null,
            enrollmentApplicationConfig: null,
            grades: null,
            logStudentIds: null,
            lotteryConfig: null,
            organizationId: user.organization.organizationId,
            schoolHomePageUrl: null,
            schoolName: values.schoolName,
            studentStatuses: null,
            studentTags: null,
            waitlistConfig: null,
            enrollmentPeriodConfig: null,

        }

        setLoading(true)
        try {
            let result = await createClientConfig(clientConfig)
            console.log(`clientConfig created:${result}`)
        } catch (err) {
            console.log(`clientConfig creation failed:${err}`)
        }
        setLoading(false)
    }

    async function handleUpdateOrgFinish(values: Store) {
        let organization: Organization = {
            ...user.organization,
            name: values.organizationName,
            clients: values.organizationClients,
        }

        setLoading(true)
        try {
            let result = await saveOrganization(organization)
            console.log(`organization saved:${result}`)
        } catch (err) {
            console.log(`organization save failed:${err}`)
        }
        setLoading(false)
    }

    let addClientToOrg = () => {
        let clients = orgFormRef.current?.getFieldValue("organizationClients") as ClientDesc[];
        let newClient: ClientDesc = {
            clientId: null,
            schoolName: null,
        }
        clients.push(newClient);
        orgFormRef.current?.setFieldsValue({"organizationClients": clients});
    }

    let removeClientFromOrg = (index: number) => {
        const clients = orgFormRef.current?.getFieldValue("organizationClients") as ClientDesc[];
        clients.splice(index, 1);
        orgFormRef.current?.setFieldsValue({"organizationClients": clients});
    }

    return (<React.Fragment>
        <Card title="Create Client">

            <SettingsFormWrapper
                size="middle"
                onFinish={handleCreateClientFinish}
                scrollToFirstError
                requiredMark={false}
                layout="vertical"
                initialValues={initialOrgFormValues}
            >
                <Form.Item
                    name="clientFriendlyId"
                    label="Unique School ID"
                    rules={[{
                        required: true,
                        message: 'Please enter an unique ID for your school\'s waitlist.'
                    }]}>
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="schoolName"
                    label="School Name"
                    rules={[{
                        required: true,
                        message: 'Please enter an school name.'
                    }]}>
                    <Input/>
                </Form.Item>
                <Button style={{width: '100%'}} type="primary" disabled={loading}
                        htmlType="submit">
                    {loading ? <Spin indicator={<LoadingOutlined type="loading" style={{fontSize: 24}}
                                                                 spin/>}/> : 'Create Client'}
                </Button>
            </SettingsFormWrapper>
        </Card>
        <Card title="Update Organization">

            <SettingsFormWrapper
                size="middle"
                onFinish={handleUpdateOrgFinish}
                scrollToFirstError
                requiredMark={false}
                layout="vertical"
                ref={orgFormRef}
            >
                <Form.Item
                    name="organizationId"
                    label="Organization ID"
                    rules={[{
                        required: true,
                        message: 'Organization ID required'
                    }]}>
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    name="organizationName"
                    label="Organization Name"
                    rules={[{
                        required: true,
                        message: 'Organization Name required'
                    }]}>
                    <Input/>
                </Form.Item>
                <Form.List name="organizationClients">
                    {(fields, {add, remove}) => {
                        return (
                            <div>
                                {fields.map((field, index) => {
                                    return (
                                        <Row gutter={8}>
                                            <Col span={12}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'clientId']}
                                                    label={index === 0 ? 'ClientId' : ''}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input the terms and conditions text.",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        style={{width: '100%'}}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'schoolName']}
                                                    label={index === 0 ? 'SchoolName' : ''}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "SchoolName required",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        style={{width: '100%'}}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>
                                                <Form.Item
                                                    {...field}
                                                    label={index === 0 ? ' ' : ''}
                                                    style={{width: '100%'}}
                                                    required={false}
                                                >
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        style={{margin: '0 8px'}}
                                                        onClick={() => {
                                                            removeClientFromOrg(index);
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                            </Col>
                                        </Row>
                                    )
                                })}
                                <Row>
                                    <Col span={8}>
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    addClientToOrg();
                                                }}
                                                style={{width: '100%'}}
                                            >
                                                <PlusOutlined/> Add Client
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}/>
                                </Row>
                            </div>
                        );
                    }}
                </Form.List>
                <Button style={{width: '100%'}} type="primary" disabled={loading}
                        htmlType="submit">
                    {loading ? <Spin indicator={<LoadingOutlined type="loading" style={{fontSize: 24}}
                                                                 spin/>}/> : 'Update Organization'}
                </Button>
            </SettingsFormWrapper>
        </Card>
    </React.Fragment>)

}

export default OrganizationManagerComponent