import React, {useEffect, useState} from "react";
import {Input} from "antd";
import {Address} from "../Model/enrollmentModel";
import {getStringValue} from "../Utils/helpers";
const google = window.google;

interface Props {
    formRef: any;
    prefix: string;
    value?: string;
    onChange?: (value: any) => void;
    [prop: string]: any;
}

const componentForm = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    country: "long_name",
    postal_code: "short_name"
};

function initializeAutocomplete(inputFieldName) {
    console.log(`maps - initializeAutocomplete - inputFieldName:${inputFieldName}`)
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    let autocomplete = new google.maps.places.Autocomplete(
        document.getElementById(inputFieldName) as HTMLInputElement,
        { types: ["geocode"] }
    );
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    autocomplete.setFields(["address_component"]);
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    return autocomplete;
}

let fillInAddress = (autocomplete: google.maps.places.Autocomplete, fieldPrefix: string, formRef:any) => {
    console.log(`maps - fillInAddress autocomplete:${autocomplete} fieldPrefix:${fieldPrefix} formRef:${formRef}`)
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();

    console.log(`maps - fillInAddress place:${place}`)

    let placeResult = {}

    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];

        if (componentForm[addressType]) {
            placeResult[addressType] = component[componentForm[addressType]];
        }
    }

    console.log(`maps - fillInAddress placeResult:${placeResult}`)
    console.log(`maps - fillInAddress street_number:${placeResult['street_number']}`)
    console.log(`maps - fillInAddress route:${placeResult['route']}`)

    var secondaryAddress: Address = {
        addressLine1: `${getStringValue(placeResult['street_number'])} ${getStringValue(placeResult['route'])}`,
        addressLine2: null,
        city: `${getStringValue(placeResult['locality'])}`,
        state: `${getStringValue(placeResult['administrative_area_level_1'])}`,
        zipCode: `${getStringValue(placeResult['postal_code'])}`
    };

    console.log(`maps - fillInAddress secondaryAddress:${secondaryAddress}`)

    const guardianFieldValues = formRef.current.getFieldValue(fieldPrefix);
    guardianFieldValues['address'] = {
        ...guardianFieldValues['address'],
        addressLine1: secondaryAddress.addressLine1,
        city: secondaryAddress.city,
        state: secondaryAddress.state,
        zipCode: secondaryAddress.zipCode,
    };
    console.log(`maps - fillInAddress guardianFieldValues:${JSON.stringify(guardianFieldValues)}`)
    formRef.current.setFieldsValue({fieldPrefix: guardianFieldValues});


    console.log(`maps - fillInAddress city:${JSON.stringify(formRef.current.getFieldValue([fieldPrefix,'address','city']))}`)


    // formRef.current.setFieldsValue({
    //     [`${fieldPrefix}_address_addressLine1`]: secondaryAddress.addressLine1,
    //     [`${fieldPrefix}_address_city`]: secondaryAddress.city,
    //     [`${fieldPrefix}_address_state`]: secondaryAddress.state,
    //     [`${fieldPrefix}_address_zipcode`]: secondaryAddress.zipCode,
    // });

    console.log(`maps - fillInAddress complete`)
}

function AddressAutocompleteInput(props: Props) {

    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

    useEffect(() => {
        if (props.formRef) {
            console.log(`maps - useEffect - initializing prefix:${props.prefix}`)
            let newAc = initializeAutocomplete(`${props.prefix}_autocomplete_input`);
            newAc.addListener("place_changed", () => fillInAddress(newAc, props.prefix, props.formRef));
            setAutocomplete(newAc);
        } else {
            console.log(`maps - useEffect - skipping initializing prefix:${props.prefix}`)
        }
    }, [props.prefix, props.formRef]);

    useEffect(() => {
        console.log(`autocomplete changed: ${autocomplete}`)
    },[autocomplete])

    return (
        <React.Fragment>
            <Input
                id={`${props.prefix}_autocomplete_input`}
                value={props.value}
                onChange={props.onChange}
                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
            />
        </React.Fragment>
    )
}
export default AddressAutocompleteInput;