import {Col, Form, Input, Row} from "antd";
import React from "react";

interface Props {
    isPrimary: boolean;
}

function GuardianPanel(props: Props) {
    let propPrefix = props.isPrimary ? "p_" : "s_";

    return (
        <React.Fragment>
            <Row gutter={2}>
                <Col span={12}>
                    <Form.Item
                        name={`${propPrefix}first_name`}
                        label="First Name"
                        rules={[{ required: props.isPrimary, message: 'Please enter a first name' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={`${propPrefix}last_name`}
                        label="Last Name"
                        rules={[{ required: props.isPrimary, message: 'Please enter a last name.' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={2}>
                <Col span={12}>
                    <Form.Item
                        name={`${propPrefix}email`}
                        label="Email"
                        rules={[{ type: 'email', message: 'The input is not valid E-mail!', }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={`${propPrefix}phone_number`}
                        label="Phone Number" >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={2}>
                <Col span={18}>
                    <Form.Item
                        name={`${propPrefix}address_line_1`}
                        label="Address">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={`${propPrefix}address_line_2`}
                        label="Apartment/Suite #">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={2}>
                <Col span={12}>
                    <Form.Item
                        name={`${propPrefix}city`}
                        label="City">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={`${propPrefix}state`}
                        label="State">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={`${propPrefix}zip_code`}
                        label="Zip Code" >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default GuardianPanel;