import authManager from "../Auth/authManager";
import {Organization} from "../Model/enrollmentModel";

const apiName = "enrollmentApi";
const organizationsPath = '/organizations';

export async function getOrganizations(): Promise<Organization[]> {
    console.log(`getOrganizations`);
    const myInit = {}
    return await authManager.apiGet<Organization[]>(apiName, organizationsPath, myInit);
}

export async function getOrganization(organizationId): Promise<Organization> {
    console.log(`getOrganization`);
    const myInit = {}
    return await authManager.apiGet<Organization>(apiName, `${organizationsPath}/${organizationId}`, myInit);
}


export async function saveOrganization(organization: Organization): Promise<any> {
    console.log(`saveOrganization`);
    const myInit = {
        body: organization,
    }
    return await authManager.apiPost(apiName, organizationsPath, myInit);
}