import * as React from 'react';
import { Tag } from 'antd';

function EnrollmentStatusTag(status: string) {
    let color = 'default';
    if (status === 'Enrolled') {
        color = 'green';
    } else if (status === 'Accepted') {
        color = 'geekblue';
    } else if (status === 'Waitlist') {
        color = 'default';
    }
    return (
        <Tag color={color} key={status}>
            {status.toUpperCase()}
        </Tag>
    );
}

export default EnrollmentStatusTag;