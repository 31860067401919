import SettingsFormWrapper from "../../../Components/Styled/SettingsFormWrapper";
import {Button, Form, notification, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import React, {useEffect, useRef} from "react";
import {Store} from "rc-field-form/es/interface";
import {useSetClientConfig, useUser} from "../../../Store/UserStore";
import TextArea from "antd/lib/input/TextArea";
import {updateClientConfig} from "../../../Services/clientConfigService";
import cloneDeep from "lodash.clonedeep";
import {FormInstance} from "antd/lib/form";


function ClientConfigComponent() {
    const user = useUser();
    const setClientConfig = useSetClientConfig();
    const formRef = useRef<FormInstance>();
    let [loading, setLoading] = React.useState<boolean>(false);

    useEffect(() => {
        formRef.current.setFieldsValue({
            clientConfig: JSON.stringify(user.clientConfig, undefined, 4)
        })
    },[user])

    let handleFinish = (values: Store) => {
        console.log(`clientConfig - entering handleFinish values: ${JSON.stringify(values)}`);

        let updatedClientConfig = JSON.parse(values.clientConfig);

        updateClientConfig(updatedClientConfig).then(response => {
            console.log(response);
            setClientConfig(cloneDeep(updatedClientConfig));
            setLoading(false);
        }).catch(error => {
            console.log(error.response)
            notification.error({
                message: 'Error',
                description: 'Unable to save config.', //TODO handle error appropriately
                placement: 'topRight',
                duration: 1.5
            });
            setLoading(false);
        });
    };

    return (
        <React.Fragment>
            <SettingsFormWrapper
                size="middle"
                onFinish={handleFinish}
                scrollToFirstError
                requiredMark={false}
                layout="vertical"
                ref={formRef}
            >
                <Form.Item
                    name="clientConfig"
                    label="ClientConfig"
                    rules={[{
                        required: false,
                    }]}>
                    <TextArea/>
                </Form.Item>
                <Button style={{width: '100%'}} type="primary" disabled={loading}
                        htmlType="submit">
                    {loading ? <Spin indicator={<LoadingOutlined type="loading" style={{fontSize: 24}}
                                                                 spin/>}/> : 'Save'}
                </Button>
            </SettingsFormWrapper>
        </React.Fragment>
    )
}

export default ClientConfigComponent;