import React, {useState, useEffect} from 'react';
import { notification } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {Student} from "../../../Model/enrollmentModel";
import { decorateStudent } from '../../../Utils/helpers';
import StudentDrawerForm from '../../Components/Student/StudentDrawerComponent';
import {getStudents} from "../../../Services/studentsServices";
import SearchableTableComponent from "../../../Components/SearchableTableComponent";
import {getStudentsTableColumns} from "../../Components/TableColumns/TableColumnFactory";
import {useSchoolYearId, useUser} from "../../../Store/UserStore";
import AdminDashboardContentCard from "../../../Components/Styled/AdminDashboardContentCard";

const SEARCH_EXCLUDED_STUDENT_KEYS = ["id","clientFriendlyId","clientId","familyId"];

function StudentsContainer() {
  console.log(`constructing StudentsContainer`);
  const user = useUser();
  const schoolYearId = useSchoolYearId();
  const [ students, setStudents ] = useState<Student[]>([]);
  const [ studentDrawerOpen, setStudentDrawerOpen ] = useState(false);
  const [ curStudent, setCurStudent ] = useState<Student | null>(null);
  const [ columns, setColumns ] = useState<any | null>(null);

  useEffect(
      () => {
          setColumns(getStudentsTableColumns(user.clientConfig));
        },
      [user]
  );

  useEffect(
      () => {
        console.log("StudentsContainer.loadStudents");
        getStudents(user.clientConfig.clientId, schoolYearId).then((students: Student[]) => {
          setStudents(students);
        }).catch(error => {
          console.log(`error:${error}`);
          notification.error({
            message: 'Error',
            description: `Unable to load students.`, //TODO handle error appropriately
            placement: 'topRight',
            duration: 1.5
          });
        });
      },
      [user, schoolYearId]
  );

  let onStudentSelected= (student) => {
    setCurStudent(student);
    setStudentDrawerOpen(true);
  }

  let onStudentDrawerClose = () => {
    setCurStudent(null);
    setStudentDrawerOpen(false);
  }

  let onStudentDrawerSubmit = (updatedStudent: Student) => {
    notification.success({
      message: 'Student Updated!',
      placement: 'topRight',
      duration: 1.5
    });

    decorateStudent(updatedStudent);
    let updatedStudents = [];
      students.forEach(student => {
        if (student.id !== updatedStudent.id) {
          updatedStudents.push(student);
        } else if (!updatedStudent.deletedInd) {
          updatedStudents.push(updatedStudent);
        }
      });

    setStudentDrawerOpen(false);
    setCurStudent(null);
    setStudents(updatedStudents);
  }

  let onFamilyDeleted = () => {
    notification.success({
      message: 'Family Deleted!',
      placement: 'topRight',
      duration: 1.5
    });

    let updatedStudents = [];
    students.forEach(student => {
      if (student.familyId !== curStudent.familyId) {
        updatedStudents.push(student);
      }
    });

    setStudentDrawerOpen(false);
    setCurStudent(null);
    setStudents(updatedStudents);
  }

  return (
    <AdminDashboardContentCard>
      <PageHeader
        ghost={true}
        title="Students"
        subTitle="" //TODO set this to something meaningful
      />
      <StudentDrawerForm
          student={curStudent}
          clientConfig={user.clientConfig}
          visible={studentDrawerOpen}
          schoolYearId={schoolYearId}
          onDelete={onFamilyDeleted}
          onClose={onStudentDrawerClose}
          onSubmit={onStudentDrawerSubmit}
        />
        <SearchableTableComponent
        dataSource={students}
        excludedKeys={SEARCH_EXCLUDED_STUDENT_KEYS}
        columns={columns}
        pagination={{
          position: ["topRight"],
          showSizeChanger: true,
          defaultPageSize: 20
        }}
        rowClassName={() => 'editable-row'}
        bordered
        onRow={(record, rowIndex) => {
          return {
            onClick: () => onStudentSelected(record), // click row
            onDoubleClick: () => onStudentSelected(record), // double click row
            onContextMenu: event => {}, // right button click row
          };
        }}
          />
    </AdminDashboardContentCard>
  );
}

export default StudentsContainer;