import {Button, Col, Form, Input, Row} from "antd";
import {Link} from "react-router-dom";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import * as React from "react";
import {useUser} from "../../../Store/UserStore";
import {EnrollmentApplicationConfig} from "../../../Model/enrollmentModel";
import EnrollmentLinkQRCode from "../../Components/EnrollmentLinkQRCode";

interface Props {
    formRef: any
}

function ClientConfigApplicationSettings(props: Props) {
    const user = useUser();

    let addAgreement = () => {
        console.log("addAgreement");
        const enrollmentApplicationConfig = props.formRef.current?.getFieldValue(["enrollmentApplicationConfig"]) as EnrollmentApplicationConfig;

        if (!enrollmentApplicationConfig.agreements) {
            enrollmentApplicationConfig.agreements = [];
        }

        enrollmentApplicationConfig.agreements.push({
            id: null,
            agreementText: null,
        });

        console.log("addAgreement enrollmentApplicationConfig:" + JSON.stringify(enrollmentApplicationConfig));
        props.formRef.current?.setFieldsValue({"enrollmentApplicationConfig": enrollmentApplicationConfig});
    }

    let removeAgreement = (index: number) => {
        const enrollmentApplicationConfig = props.formRef.current?.getFieldValue(["enrollmentApplicationConfig"]) as EnrollmentApplicationConfig;
        enrollmentApplicationConfig.agreements = enrollmentApplicationConfig.agreements.splice(index, 1);
        props.formRef.current?.setFieldsValue({"enrollmentApplicationConfig": enrollmentApplicationConfig});
    }

    return (<React.Fragment>
        <Form.Item
            name="enrlSiteLink"
            label="Enrollment Application Page"
            rules={[{required: false}]}>
            <Link to={`/${user.clientConfig?.clientFriendlyId}/enroll`} target="_blank">
                <Button
                    type="link">{`https://enrollwithit.com/${user.clientConfig?.clientFriendlyId}/enroll`}</Button>
            </Link>
            <EnrollmentLinkQRCode downloadable={true}/>
        </Form.Item>
        {/*<Form.Item*/}
        {/*    name={['enrollmentApplicationConfig', 'enabled']}*/}
        {/*    label="Enable Applications"*/}
        {/*    rules={[{required: false}]}>*/}
        {/*    <Switch*/}
        {/*        checkedChildren={<CheckOutlined/>}*/}
        {/*        unCheckedChildren={<CloseOutlined/>}*/}
        {/*        //checked={user.clientConfig.enrollmentApplicationConfig.enrollmentEnabledOverride}*/}
        {/*    />*/}
        {/*</Form.Item>*/}
        <Form.Item
            name={['enrollmentApplicationConfig', 'header']}
            label="Title">
            <Input/>
        </Form.Item>
        <Form.Item
            name={['enrollmentApplicationConfig', 'subHeader']}
            label="Subtitle">
            <Input/>
        </Form.Item>
        <Form.List name={['enrollmentApplicationConfig', 'agreements']}>
            {(fields, {add, remove}) => {
                return (
                    <div>
                        {fields.map((field, index) => {
                            return (
                                <Row gutter={8}>
                                    <Col span={23}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'agreementText']}
                                            label={index === 0 ? 'Terms and Conditions' : ''}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please input the terms and conditions text.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                style={{width: '100%'}}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        <Form.Item
                                            {...field}
                                            label={index === 0 ? ' ' : ''}
                                            style={{width: '100%'}}
                                            required={false}
                                        >
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                style={{margin: '0 8px'}}
                                                onClick={() => {
                                                    //remove(field.name);
                                                    removeAgreement(index);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                    </Col>
                                </Row>
                            )
                        })}
                        <Row>
                            <Col span={8}>
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            addAgreement();
                                            //add();
                                        }}
                                        style={{width: '100%'}}
                                    >
                                        <PlusOutlined/> Add Terms and Conditions
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={8}/>
                        </Row>
                    </div>
                );
            }}
        </Form.List>
    </React.Fragment>)
}

export default ClientConfigApplicationSettings