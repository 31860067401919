import { Table } from 'antd'
import styled from "styled-components"

const CompactTable = styled(Table)`
  tbody {
    tr {
      td {
        height: 2px;
        padding: 2px;
        .ant-btn {
          height: 2px;
          padding: 2px;
        }
      }
    }
  }
`;

export default CompactTable;