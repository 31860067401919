import React, {useEffect, useState} from 'react';
import {notification} from 'antd';
import {PageHeader} from '@ant-design/pro-layout';
import {Family} from "../../../Model/enrollmentModel";
import FamilyTableContainer from '../../Components/Family/FamilyTableComponent';
import {decorateFamily} from '../../../Utils/helpers';
import {getDeletedFamilies} from "../../../Services/familiesService";
import FamilyDrawerController from "../../Components/Family/FamilyDrawerController";
import {useSchoolYearId, useUser} from "../../../Store/UserStore";
import AdminDashboardContentCard from "../../../Components/Styled/AdminDashboardContentCard";

function DeletedFamiliesContainer() {
    console.log(`constructing DeletedFamiliesContainer`);
    const user = useUser();
    const schoolYearId = useSchoolYearId();
    const [families, setFamilies] = useState<Family[]>([]);
    const [familyDrawerOpen, setFamilyDrawerOpen] = useState(false);
    const [curFamily, setCurFamily] = useState<Family | null>(null);

    useEffect(
        () => {
            getDeletedFamilies(user.clientConfig.clientId, schoolYearId).then((families: Family[]) => {
                setFamilies(families);
            }).catch(error => {
                console.log(`error:${error}`);
                notification.error({
                    message: 'Error',
                    description: `Unable to load families.`, //TODO handle error appropriately
                    placement: 'topRight',
                    duration: 1.5
                });
            });
        }, [user.clientConfig.clientId, schoolYearId]
    );

    let onFamilySelected = (family) => {
        setCurFamily(family);
        setFamilyDrawerOpen(true);
    }

    let onFamilyDrawerClose = () => {
        setCurFamily(null);
        setFamilyDrawerOpen(null);
    }

    let onFamilyDrawerSubmit = (updatedFamily: Family) => {
        notification.success({
            message: 'Family Updated!',
            placement: 'topRight',
            duration: 1.5
        });

        decorateFamily(updatedFamily);
        let updatedFamilies = [];
        families.forEach(family => {
            if (family.id !== updatedFamily.id) {
                updatedFamilies.push(family);
            } else {
                updatedFamilies.push(updatedFamily);
            }
        });

        setFamilyDrawerOpen(false);
        setCurFamily(null);
        setFamilies(updatedFamilies);
    }

    let onFamilyDeleted = () => {
        notification.success({
            message: 'Family Restored!',
            placement: 'topRight',
            duration: 1.5
        });

        let updatedFamilies = [];
        families.forEach(family => {
            if (family.id !== curFamily.id) {
                updatedFamilies.push(family);
            }
        });

        setFamilyDrawerOpen(false);
        setCurFamily(null);
        setFamilies(updatedFamilies);
    }

    return (
        <AdminDashboardContentCard key="deletedFamiliesContainerWrapper">
            <PageHeader
                ghost={true}
                title="Deleted Families"
                subTitle="" //TODO set this to something meaningful
            >
                <FamilyDrawerController
                    clientId={user.clientConfig.clientId}
                    family={curFamily}
                    visible={familyDrawerOpen}
                    isNewFamily={false}
                    onDelete={onFamilyDeleted}
                    onClose={onFamilyDrawerClose}
                    onSubmit={onFamilyDrawerSubmit}
                />
            </PageHeader>
            <FamilyTableContainer families={families} onFamilySelected={onFamilySelected}/>
        </AdminDashboardContentCard>
    );
}

export default DeletedFamiliesContainer;