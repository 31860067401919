import {ClientConfig, ContactInfo, Family, Student} from "../../../Model/enrollmentModel";
import {Col, Progress, Row, Statistic, Tag} from "antd";
import React from "react";
import {statusSorter} from "../../../Utils/helpers";
import EnrollmentStatusTag from "../EnrollmentStatusTag";
import dayjs from "dayjs";
import {parseTimestamp} from "../../../Utils/DateUtils";

const {Countdown} = Statistic;

const tagsSummaryFilter = (value: string, record: any) => {
    return record.tagsSummary.indexOf(value) >= 0;
}

const tagsFilter = (value: string, record: any) => {
    return record.tags.indexOf(value) >= 0;
}

const districtStatusFilter = (value: string, record: Family) => {
    if (value) {
        if (record.districtStatus) {
            return record.districtStatus.indexOf(value) >= 0;
        } else {
            return false;
        }
    } else {
        return !(record.districtStatus);
    }
}

function getStatusFilter(clientConfig, statusFilterOverride) {
    if (statusFilterOverride) {
        return statusFilterOverride;
    } else {
        let defaultStudentStatusesFilters = [];
        clientConfig.studentStatuses.forEach(element => {
            defaultStudentStatusesFilters.push(element.code);
        });
        return defaultStudentStatusesFilters;
    }
}

function getStudentTagsFilterList(clientConfig: ClientConfig): any[] {
    let studentTagsFilters = [];
    clientConfig.studentTags.forEach(element => {
        studentTagsFilters.push({
            text: element.name,
            value: element.name
        })
    });
    return studentTagsFilters;
}

function getStudentStatusFilterList(clientConfig: ClientConfig): any[] {
    let studentStatusesFilters = [];
    clientConfig.studentStatuses.forEach(element => {
        studentStatusesFilters.push({
            text: element.code,
            value: element.code
        })
    });
    return studentStatusesFilters;
}

function getDistrictStatusesFilterList(clientConfig: ClientConfig) {
    let districtStatusFilters = [{text: "Pending", value: null}];
    clientConfig.districtStatuses.forEach(element => {
        districtStatusFilters.push({
            text: element.code,
            value: element.code
        })
    });

    return districtStatusFilters;
}

function getWaitlistDistrictStatusesFilterList(clientConfig: ClientConfig) {
    let defaultStudentStatusesFilters = [];
    clientConfig.studentStatuses.forEach(element => {
        if (element.showOnWaitlist) {
            defaultStudentStatusesFilters.push(element.code);
        }
    });
    return defaultStudentStatusesFilters;

}

function getGradeFilterList(clientConfig: ClientConfig) {
    let gradeFilters = [];
    clientConfig.grades.forEach(element => {
        gradeFilters.push({
            text: element,
            value: element
        })
    });
    return gradeFilters;
}

function getStatusSortOrderMap(clientConfig: ClientConfig) {
    let sortOrderMap = {};
    clientConfig.studentStatuses.forEach(status => {
        sortOrderMap[status.code] = status.sortOrder;
    });
    return sortOrderMap;
}

const guardianColumn = {
    title: 'Guardians',
    dataIndex: ['guardianSummary'],
    key: 'guardianSummary',
    sorter: (a, b) => a.guardianSummary.localeCompare(b.guardianSummary),
    width: 300,
}
const notesColumn = {
    title: "Notes",
    dataIndex: ['notes'],
    key: 'notes',
}
const studentSummaryColumn = {
    title: "Students",
    dataIndex: ['studentSummary'],
    key: 'studentSummary',
    width: 300,
}

function tagsColumn(isSummary: boolean, filterEnabled: boolean, clientConfig: ClientConfig) {
    let key = isSummary ? 'tagsSummary' : 'tags';
    let onFilter = isSummary ? tagsSummaryFilter : tagsFilter;

    let column = {
        title: 'Tags',
        dataIndex: [key],
        key: key,
        width: 180,
        render: (tags: any) => (
            //TODO handle tags not being initialized
            //tags = ( typeof tags != 'undefined' && tags instanceof Array ) ? tags : [];
            <>
                {tags.map((tag: string) => {
                    let color = 'default';
                    if (tag === 'Founder' || tag === 'Staff') {
                        color = 'gold';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    };

    if (filterEnabled) {
        column['filters'] = getStudentTagsFilterList(clientConfig);
        column['onFilter'] = onFilter;
    }
    return column;
}

function districtStatusColumn(filterEnabled: boolean, clientConfig: ClientConfig) {
    let column = {
        title: 'District Status',
        dataIndex: ['districtStatus'],
        key: 'districtStatus',
        width: 180,
        render: (status: any) => {
            let color = 'warning';
            if (status === 'In District') {
                color = 'default';
            } else if (status === 'Out of District') {
                color = 'default';
            }
            return (
                <Tag color={color} key={status}>
                    {(status && status.length > 0) ? status : "Pending"}
                </Tag>
            );
        },
    };

    if (filterEnabled) {
        column['filters'] = getDistrictStatusesFilterList(clientConfig);
        column['onFilter'] = districtStatusFilter;
    }
    return column;
}

function enrollmentStatusColumn(clientConfig: ClientConfig) {
    return {
        title: 'Enrollment Status',
        dataIndex: ['statusSummary'],
        key: 'statusSummary',
        width: 180,
        filters: getStudentStatusFilterList(clientConfig),
        onFilter: (value: any, record: any) => record.statusSummary.indexOf(value) === 0,
        render: (statusSummary: any) => (
            //TODO handle tags not being initialized
            //tags = ( typeof tags != 'undefined' && tags instanceof Array ) ? tags : [];
            <>
                {statusSummary.map((status: any) => {
                    let color = 'default';
                    if (status === 'Enrolled') {
                        color = 'green';
                    } else if (status === 'Accepted') {
                        color = 'geekblue';
                    } else if (status === 'Waitlist') {
                        color = 'default';
                    }
                    return (
                        <Tag color={color} key={status}>
                            {status.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    };
}

const positionColumn = {
    title: 'Position',
    dataIndex: 'waitlistPosition',
    key: 'waitlistPosition',
    width: 75,
    sorter: (a: Student, b: Student) => a.waitlistPosition - b.waitlistPosition,
}

function gradeColumn(filterEnabled: boolean, sortEnabled: boolean, clientConfig: ClientConfig) {
    let column = {
        title: 'Grade',
        dataIndex: 'grade',
        key: 'grade',
        width: 75,
    }
    if (filterEnabled) {
        column['sorter'] = (a: Student, b: Student) => a.grade.toString().localeCompare(b.grade);
        column['filters'] = getGradeFilterList(clientConfig);
    }
    if (sortEnabled) {
        column['sorter'] = (a: Student, b: Student) => a.grade.toString().localeCompare(b.grade);
    }
    return column;
}

const applicationDateColumn = {
    title: 'Application Date',
    dataIndex: 'applicationDate',
    key: 'applicationDate',
    width: 175,
    sorter: (a: { applicationDate: string; }, b: { applicationDate: string; }) => {
        if (!a.applicationDate) {
            return -1;
        } else if (!b.applicationDate) {
            return 1;
        } else {
            return parseTimestamp(a.applicationDate).isAfter(parseTimestamp(b.applicationDate)) ? 1 : -1;
        }
    },
}

// const createdTimestampDateColumn = {
//     title: 'createdTimestamp',
//     dataIndex: 'createdTimestamp',
//     key: 'createdTimestamp',
//     width: 175,
//     sorter: (a, b) => {
//         return a.createdTimestamp - b.createdTimestamp
//     },
// }

const studentNameColumn = {
    title: 'Name',
    dataIndex: 'displayName',
    key: 'name',
    width: 200,
    sorter: (a, b) => {
        if (a.displayName) {
            return a.displayName.localeCompare(b.displayName)
        } else {
            return b.displayName ? 1 : 0;//if b.displayName != null then a < b, else equals
        }
    },
}
const contactCountColumn = {
    title: 'Contact Count',
    dataIndex: 'contactInfo',
    key: 'contactInfo',
    width: 180,
    render: (contactInfo: ContactInfo) => {
        let contactEventsCount = 0;
        let showTimer = false;
        let deadline = null;
        if (contactInfo && contactInfo.contactEvents) {
            contactEventsCount = contactInfo.contactEvents.length;
        }
        let percent = contactEventsCount > 2 ? 100 : (contactEventsCount / 2 * 100);

        if (percent >= 100) {
            showTimer = true;
            deadline = dayjs(contactInfo.contactEvents[contactEventsCount - 1].timestamp).add(1, "days");
        }

        return (<>
            <Row>
                <Col span={8}>
                    {<Progress percent={percent} steps={2} showInfo={false}/>}
                </Col>
                <Col span={16}>
                    {showTimer && <Countdown value={deadline}/>}
                </Col>
            </Row>
        </>);
    },
}

function waitlistStatusColumn(isWaitlist: boolean, statusFilterOverride: string[], clientConfig: ClientConfig): any {
    let column = {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 180,
        sorter: (a, b) => {
            return statusSorter(a, b, getStatusSortOrderMap(clientConfig))
        },
        onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
        render: EnrollmentStatusTag
    };

    if (isWaitlist) {
        column['defaultFilteredValue'] = getWaitlistDistrictStatusesFilterList(clientConfig);
        column['filteredValue'] = getStatusFilter(clientConfig, statusFilterOverride);
    } else {
        column['filters'] = getStudentStatusFilterList(clientConfig);
    }

    return column;
}

function getFamilyTableColumns(clientConfig: ClientConfig) {
    return [
        guardianColumn,
        studentSummaryColumn,
        notesColumn,
        tagsColumn(true, true, clientConfig),
        districtStatusColumn(true, clientConfig),
        enrollmentStatusColumn(clientConfig)
    ];
}

function getWaitlistTableColumns(clientConfig: ClientConfig, statusFilterOverride: string[]) {
    return [
        positionColumn,
        gradeColumn(false, false, clientConfig),
        applicationDateColumn,
        studentNameColumn,
        notesColumn,
        contactCountColumn,
        tagsColumn(false, false, clientConfig),
        districtStatusColumn(false, clientConfig),
        waitlistStatusColumn(true, statusFilterOverride, clientConfig)
    ];
}

function getStudentsTableColumns(clientConfig: ClientConfig) {

    return [
        gradeColumn(true, true, clientConfig),
        applicationDateColumn,
        studentNameColumn,
        notesColumn,
        tagsColumn(false, true, clientConfig),
        districtStatusColumn(true, clientConfig),
        waitlistStatusColumn(false, null, clientConfig),
    ];
}

export {
    getFamilyTableColumns,
    getWaitlistTableColumns,
    getStudentsTableColumns,
};