import {ClientConfig, ClientDesc, Organization, User} from "../Model/enrollmentModel";
import {getClientConfig} from "../Services/clientConfigService";
import {create} from "zustand";
import {registerListener} from "../Utils/MessageBusUtils";
import {AuthListener} from '../Auth/AuthListener';
import authManager from "../Auth/authManager";
import {Auth} from "aws-amplify";
import {getOrganizations} from "../Services/organizationsService";
import {createJSONStorage, devtools, persist} from "zustand/middleware";


interface UserStore {
    user: User,
    loadUser: (authenticated: boolean, authEventPayloadData: any) => void,
    currentOrganizationId: string
    currentClientId: string,
    currentSchoolYearId: string,
    selectSchoolYear: (schoolYearId: string) => void,
    selectOrganization: (organizationId: string) => void,

    selectClient: (clientId: string) => void,
    setClientConfig: (clientConfig: ClientConfig) => void,
    init: () => void,
}

function checkIfAdmin(cognitoUser: any) {
    let isAdmin = false
    let groups: any[] = cognitoUser?.signInUserSession?.idToken?.payload['cognito:groups']
    if (!groups) {
        groups = []
    }
    isAdmin = groups.includes('admin')
    return isAdmin
}

function clearUser(set, cognitoUser) {
    set(() => ({
        user: {
            authenticated: false,
            cognitoUser: cognitoUser,
            isAdmin: false,
            clientConfig: null,
            authorizedOrganizations: null,
            organization: null
        },
        currentOrganizationId: null,
        currentClientId: null,
        currentSchoolYearId: null,
    }));
}

function getOrganizationById(authorizedOrganizations: Organization[], organizationId: string): Organization {
    let outOrganization = null;

    if (authorizedOrganizations?.length > 0) {
        outOrganization = authorizedOrganizations.find(org => {
            return org.organizationId === organizationId
        }) ?? authorizedOrganizations[0];
    }

    return outOrganization;
}

function getCurClientId(clientId: string, organization: Organization): string {
    let outCurClientDesc: ClientDesc = null;

    if (organization?.clients?.length > 0) {
        console.log(`loadUser: setting updatedCurClientId, looking for ${clientId} in organization.clients`);
        outCurClientDesc = organization.clients.find((client: ClientDesc) => {
            return client.clientId === clientId
        }) ?? organization.clients[0];
    }

    return outCurClientDesc?.clientId;
}

async function getClientConfigById(clientId: string): Promise<ClientConfig> {
    let outClientConfig: ClientConfig = null;

    if (clientId) {
        outClientConfig = await getClientConfig(clientId);
    }

    return outClientConfig;
}

function getCurEnrollmentPeriodId(enrollmentPeriodId: string, clientConfig: ClientConfig) {
    console.log(`getCurEnrollmentPeriod enrollmentPeriodId:${enrollmentPeriodId} enrollmentPeriods:${JSON.stringify(clientConfig?.enrollmentPeriodConfig?.enrollmentPeriods)}`);
    let outCurEnrollmentPeriod = null;

    if (clientConfig?.enrollmentPeriodConfig?.enrollmentPeriods?.length > 0) {
        if (!enrollmentPeriodId) {
            console.log(`getCurEnrollmentPeriodId enrollmentPeriodId not set, setting to last enrollmentPeriod`);

            return clientConfig?.enrollmentPeriodConfig?.enrollmentPeriods.filter(enrollmentPeriod => {
                return !enrollmentPeriod.isShadowNextPeriod
            }).slice(-1)[0]?.id;
        }

        outCurEnrollmentPeriod = clientConfig.enrollmentPeriodConfig.enrollmentPeriods.find(enrollmentPeriod => {
            return enrollmentPeriod.id === enrollmentPeriodId
        });
    }

    return outCurEnrollmentPeriod?.id;
}

async function loadUserImpl(authenticated: boolean, authEventPayloadData: any, get: any, set: any) {
    console.log(`loadUser: starting`);
    if (!authenticated) {
        console.log(`loadUser: user not authenticated`);
        clearUser(set, authEventPayloadData)
        return
    }

    let isAdmin = false;
    let cognitoUser = authEventPayloadData ?? await Auth.currentAuthenticatedUser();
    isAdmin = checkIfAdmin(cognitoUser)
    console.log(`loadUser: isAdmin:${isAdmin}`);

    let authorizedOrganizations = await getOrganizations()
    console.log(`loadUser: authorizedOrganizations:${JSON.stringify(authorizedOrganizations)}`);

    let organization = getOrganizationById(authorizedOrganizations, get().currentOrganizationId);
    console.log(`loadUser: organization:${JSON.stringify(organization)}`);

    let updatedCurClientId = getCurClientId(get().currentClientId, organization);
    console.log(`loadUser: updatedCurClientId:${JSON.stringify(updatedCurClientId)}`);

    let clientConfig = await getClientConfigById(updatedCurClientId);
    console.log(`loadUser: clientConfig:${JSON.stringify(clientConfig)}`);

    let updatedCurSchoolYearId = getCurEnrollmentPeriodId(get().currentSchoolYearId, clientConfig)
    console.log(`loadUser: updatedCurSchoolYearId:${JSON.stringify(updatedCurSchoolYearId)}`);

    set(() => ({
        user: {
            authenticated: authenticated,
            cognitoUser: cognitoUser,
            isAdmin: isAdmin,
            clientConfig: clientConfig,
            authorizedOrganizations: authorizedOrganizations,
            organization: organization
        },
        currentOrganizationId: organization?.organizationId,
        currentClientId: clientConfig?.clientId,
        currentSchoolYearId: updatedCurSchoolYearId,
    }));
}

async function selectOrganization(organizationId: string, get: any, set: any) {

    let organization = getOrganizationById(get().user.authorizedOrganizations, organizationId);
    console.log(`loadUser: organization:${JSON.stringify(organization)}`);

    let updatedCurClientId = getCurClientId(null, organization);
    console.log(`loadUser: updatedCurClientId:${JSON.stringify(updatedCurClientId)}`);

    let clientConfig = await getClientConfigById(updatedCurClientId);
    console.log(`loadUser: clientConfig:${JSON.stringify(clientConfig)}`);

    let updatedCurSchoolYearId = getCurEnrollmentPeriodId(null, clientConfig)
    console.log(`loadUser: updatedCurSchoolYearId:${JSON.stringify(updatedCurSchoolYearId)}`);

    set(() => ({
        user: {
            ...get().user,
            clientConfig: clientConfig,
            organization: organization
        },
        currentOrganizationId: organization?.organizationId,
        currentClientId: clientConfig?.clientId,
        currentSchoolYearId: updatedCurSchoolYearId,
    }));
}

async function selectClient(clientId: string, get: any, set: any) {

    let clientConfig = await getClientConfigById(clientId);
    console.log(`loadUser: clientConfig:${JSON.stringify(clientConfig)}`);

    let updatedCurSchoolYearId = getCurEnrollmentPeriodId(get().currentSchoolYearId, clientConfig)
    console.log(`loadUser: updatedCurSchoolYearId:${JSON.stringify(updatedCurSchoolYearId)}`);

    set(() => ({
        user: {
            ...get().user,
            clientConfig: clientConfig,
        },
        currentClientId: clientConfig?.clientId,
        currentSchoolYearId: updatedCurSchoolYearId,
    }));
}

const useUserStore = create<UserStore>()(
    devtools(
        persist(
            (set, get) => ({
                    user: {
                        authorizedOrganizations: null,
                        organization: null,
                        clientConfig: null,
                        authenticated: false,
                        isAdmin: false,
                        cognitoUser: null
                    },
                    loadUser: (authenticated: boolean, cognitoUserInfo: any) => loadUserImpl(authenticated, cognitoUserInfo, get, set),
                    currentSchoolYearId: null,
                    currentOrganizationId: null,
                    currentClientId: null,
                    selectSchoolYear: (schoolYearId: string) => {
                        set((state) => ({currentSchoolYearId: schoolYearId}))
                    },
                    selectOrganization: (organizationId) => selectOrganization(organizationId, get, set),
                    selectClient: (clientId) => selectClient(clientId, get, set),
                    setClientConfig: (clientConfig: ClientConfig) => (set((state) => ({
                        user: {
                            ...state.user,
                            clientConfig: clientConfig
                        }
                    }))),
                    init: () => {
                        console.log("UserStore.init");
                        registerListener('auth', 'authListener', (new AuthListener(get().loadUser)).listener);
                        loadUserImpl(authManager.isAuthenticated(), null, get, set);
                    }
                }
            ),
            {
                name: 'user-store', // unique name
                storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
            }
        )
    )
)

export const useInitStore = () => useUserStore((state) => state.init);
export const useUser = () => useUserStore((state) => state.user);
export const useLoadUser = () => useUserStore((state) => state.loadUser);
export const useSetClientConfig = () => useUserStore((state) => state.setClientConfig);
export const useSchoolYearId = () => useUserStore((state) => state.currentSchoolYearId);
export const useSelectSchoolYear = () => useUserStore((state) => state.selectSchoolYear);
export const useSelectOrganization = () => useUserStore((state) => state.selectOrganization);
export const useSelectClient = () => useUserStore((state) => state.selectClient);