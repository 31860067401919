import authManager from "../Auth/authManager";
import {decorateStudents} from "../Utils/helpers";
import {Student} from "../Model/enrollmentModel";

const apiName = "enrollmentApi";
const path = "/waitlists";

export async function getWaitlistForGrade(clientId, schoolYearId, grade) {
    console.log(`loading waitlist for schoolYearId:${schoolYearId} grade:${grade}`);

    const myInit = {
        queryStringParameters: {
            clientId: clientId,
            schoolYearId: schoolYearId,
            grade: grade,
        },
    }
    let waitlist = await authManager.apiGet<Student[]>(apiName,path, myInit);

    decorateStudents(waitlist);

    return waitlist;
}

export async function getWaitlists(clientId, schoolYearId) {
    console.log(`loading waitlist for schoolYearId:${schoolYearId}`);

    const myInit = {
        queryStringParameters: {
            clientId: clientId,
            schoolYearId: schoolYearId
        },
    }
    let waitlists = await authManager.apiGet(apiName,path, myInit);

    Object.keys(waitlists).forEach(function(key) {
        if (key !== 'waitlistStatistics') {
            decorateStudents(waitlists[key]);
        }
    });

    return waitlists;
}