import React from 'react';
import {Drawer, Form, Button, Col, Row, Input, Select, DatePicker, notification, InputNumber} from 'antd';
import {ClientConfig, Student, Family, ContactInfo} from '../../../Model/enrollmentModel';
import {sortGrades, sortStudentStatuses, getSelectableStudentTags} from '../../../Utils/helpers';
import authManager from '../../../Auth/authManager';
import {Store} from 'rc-field-form/es/interface';
import {FormInstance} from 'antd/lib/form';
import FamilyDrawerController from "../Family/FamilyDrawerController";
import dayjs from "dayjs";

const cloneDeep = require('lodash.clonedeep');

const {Option} = Select;

interface IStudentDrawerProps {
    student: Student;
    clientConfig: ClientConfig;
    schoolYearId: string;
    visible: boolean;
    onDelete: () => void;
    onClose: () => void;
    onSubmit: (student: Student) => void;
}

type State = {
    student: Student;
    familyDrawerOpen: boolean;
}

function getDateFieldValue(date) {
    if (date) {
        return dayjs(date);
    } else {
        return null;
    }
}

function getContactInfo(count, curContactInfo) {
    if (!count) {
        count = 0;
    }
    if (!curContactInfo || !curContactInfo.contactEvents) {
        curContactInfo = {
            contactEvents: []
        }
    }

    let diff = count - curContactInfo.contactEvents.length;

    if (diff > 0) {
        for (let i1 = 0; i1 < diff; i1++) {
            curContactInfo.contactEvents.push({
                timestamp: dayjs()
            });
        }
    } else if (diff < 0) {
        let newContactEvents = [];
        for (let i2 = 0, j = 0; i2 > diff; i2--, j++) {
            newContactEvents.push(curContactInfo.contactEvents[j]);
        }
    }
    return curContactInfo;
}

function getContactEventsCount(contactInfo: ContactInfo) {
    let count = 0;

    if (contactInfo && contactInfo.contactEvents) {
        count = contactInfo.contactEvents.length;
    }
    return count;
}

class StudentDrawerForm extends React.Component<IStudentDrawerProps, State> {
    formRef = React.createRef<FormInstance>();

    constructor(props: IStudentDrawerProps) {
        super(props);
        console.log(`constructing StudentDrawerForm clientConfig:${props.clientConfig}`)

        this.state = {
            student: cloneDeep(this.props.student),
            familyDrawerOpen: false,
        }

        this.saveStudent = this.saveStudent.bind(this);
        this.onFamilyIdClick = this.onFamilyIdClick.bind(this);
        this.onFamilyDrawerClose = this.onFamilyDrawerClose.bind(this);
        this.onFamilyDrawerSubmit = this.onFamilyDrawerSubmit.bind(this);
        this.updateStudentFromFamily = this.updateStudentFromFamily.bind(this);
        this.setStudent = this.setStudent.bind(this);
        this.onValuesChange = this.onValuesChange.bind(this);
        this.onFamilyDeleted = this.onFamilyDeleted.bind(this);
    }

    onValuesChange(changedValues, allValues) {
        this.setState({student: this.updateStudent(allValues)});
    }

    updateStudent(values) {
        let {student} = this.state;
        student.firstName = values.firstName;
        student.lastName = values.lastName;
        student.gender = values.gender;
        student.grade = values.grade;
        student.birthDate = values.birthDate;
        student.tags = values.tags;
        student.siblingDate = values.siblingDate;
        student.status = values.status;
        student.notes = values.notes;
        student.contactInfo = getContactInfo(values.contactEventsCount, student.contactInfo);
        return student;
    }

    componentDidUpdate(prevProps) {
        console.log("StudentDrawerComponent.componentDidUpdate");
        //console.log(`prevFamily:${JSON.stringify(prevProps.family)}`);
        //console.log(`newFamily:${JSON.stringify(this.props.family)}`);

        if (this.propsChanged(prevProps)) {
            console.log("StudentDrawerComponent.propsChanged");
            this.setStudent(this.props.student);
        }
    }

    propsChanged(prevProps) {
        return prevProps.student !== this.props.student ||
            prevProps.clientConfig !== this.props.clientConfig;
    }

    saveStudent(values: Store) {
        let student = this.updateStudent(values);
        this.setState({student: student});

        console.log(`Submitting student:${JSON.stringify(student)}`);

        authManager.apiPost("enrollmentApi", "/students", {body: student}).then(response => {
            console.log(response);
            this.props.onSubmit(student);
        }).catch(error => {
            console.log(error.response)
            notification.error({
                message: 'Error',
                description: 'Unable to save students.', //TODO handle error appropriately
                placement: 'topRight',
                duration: 1.5
            });
        });
    }

    getFormFieldsValues(student) {
        let values: any = {};
        if (student) {
            values['firstName'] = student.firstName;
            values['lastName'] = student.lastName;
            values['grade'] = student.grade;
            values['tags'] = student.tags;
            values['birthDate'] = getDateFieldValue(student.birthDate);
            values['status'] = student.status;
            values['siblingDate'] = getDateFieldValue(student.siblingDate);
            values['notes'] = student.notes;
            values['contactEventsCount'] = getContactEventsCount(student.contactInfo);
            values['waotlistPosition'] = student.waotlistPosition;
        }
        return values;
    }

    onFamilyIdClick() {
        this.setState({familyDrawerOpen: true});
    }

    onFamilyDeleted() {
        this.onFamilyDrawerClose();
        this.props.onDelete();
    }

    onFamilyDrawerClose = () => {
        this.setState({
            familyDrawerOpen: false,
        });
    }

    onFamilyDrawerSubmit = (family: Family) => {
        console.log(`onFamilySubmit family:${family}`);
        let updatedStudent = this.updateStudentFromFamily(family);

        this.setState({familyDrawerOpen: false,});
        this.props.onSubmit(updatedStudent);
    }

    updateStudentFromFamily(family: Family) {
        const {student} = this.state;
        let updatedStudent = student;
        updatedStudent.deletedInd = true;

        if (student && family && family.students) {
            family.students.forEach(curStudent => {
                if (curStudent.id && curStudent.id.localeCompare(student.id) === 0) {
                    console.log(`updateStudentFromFamily match found from family`);
                    updatedStudent = curStudent;
                }
            });
        }
        console.log(`updateStudentFromFamily updatedStudent:${student}`);
        return updatedStudent;
    }

    setStudent(student) {
        if (this.formRef && this.formRef.current) {
            this.formRef.current.setFieldsValue(this.getFormFieldsValues(student));
        }

        this.setState({
            student: cloneDeep(student),
        });
    }

    render() {
        const {student} = this.state;
        console.log(`render student:${JSON.stringify(student)}`);
        let formInitialValues: any = {
            initialValues: this.getFormFieldsValues(student),
        };


        var sortedGrades = sortGrades(this.props.clientConfig.grades);
        var gradesList = sortedGrades.map(function (grade: any) {
            return <Option key={grade} value={grade}>{grade}</Option>;
        })

        var sortedStatus = sortStudentStatuses(this.props.clientConfig.studentStatuses);
        var statusList = sortedStatus.map(function (status: any) {
            return <Option key={status.code} value={status.code}>{status.code}</Option>;
        });

        var sortedTags = getSelectableStudentTags(this.props.clientConfig.studentTags);
        var tagsList = sortedTags.map(function (tag: any) {
            return <Option key={tag.name} value={tag.name}>{tag.name}</Option>;
        });

        return (
            <>
                <Drawer
                    title={"Student"}
                    width={400}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    bodyStyle={{paddingBottom: 80}}
                    destroyOnClose
                    footer={
                        <div
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={this.props.onClose} style={{marginRight: 8}}>
                                Cancel
                            </Button>
                            <Button form="studentForm" type="primary" htmlType="submit">
                                Save
                            </Button>
                        </div>
                    }
                >
                    <FamilyDrawerController
                        clientId={this.props.clientConfig.clientId}
                        familyId={student ? student.familyId : null}
                        updatedStudent={student}
                        visible={this.state.familyDrawerOpen}
                        isNewFamily={false}
                        onDelete={this.onFamilyDeleted}
                        onClose={this.onFamilyDrawerClose}
                        onSubmit={this.onFamilyDrawerSubmit}
                    />
                    <Form
                        id="studentForm"
                        ref={this.formRef}
                        layout="vertical"
                        size="medium"
                        requiredMark={false}
                        {...formInitialValues}
                        onFinish={this.saveStudent}
                        onValuesChange={this.onValuesChange}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="familyId"
                                    label={'Family'}
                                >
                                    <Button
                                        type="link"
                                        style={{width: '100%', textAlign: "left"}}
                                        onClick={this.onFamilyIdClick}
                                    >{student ? student.familyId : ''}</Button>
                                </Form.Item>
                                <Form.Item
                                    name="firstName"
                                    label='First Name'
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "First name required",
                                        },
                                    ]}
                                >
                                    <Input style={{width: '100%'}}/>
                                </Form.Item>
                                <Form.Item
                                    name="lastName"
                                    label='Last Name'
                                >
                                    <Input style={{width: '100%'}}/>
                                </Form.Item>
                                <Form.Item
                                    name='gender'
                                    label='Gender'
                                    validateTrigger={['onChange', 'onBlur']}
                                >
                                    <Select
                                        style={{width: '100%'}}
                                        options={[
                                            {
                                                value: '',
                                                label: '',
                                            },
                                            {
                                                value: 'F',
                                                label: 'F',
                                            },
                                            {
                                                value: 'M',
                                                label: 'M',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='birthDate'
                                    label='Birth Date'
                                    validateTrigger={['onChange', 'onBlur']}
                                >
                                    <DatePicker/>
                                </Form.Item>
                                <Form.Item
                                    name='grade'
                                    label='Grade'
                                    validateTrigger={['onChange', 'onBlur']}
                                >
                                    <Select style={{width: '100%'}}>
                                        {gradesList}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="tags"
                                    label="Tags"
                                >
                                    <Select
                                        mode="multiple"
                                        style={{width: '100%'}}
                                        optionLabelProp="label"
                                    >
                                        {tagsList}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name='siblingDate'
                                    label='Sibling Date'
                                    validateTrigger={['onChange', 'onBlur']}
                                >
                                    <DatePicker/>
                                </Form.Item>
                                <Form.Item
                                    name='status'
                                    label='Status'
                                    validateTrigger={['onChange', 'onBlur']}
                                >
                                    <Select style={{width: '100%'}}>
                                        {statusList}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name='contactEventsCount'
                                    label='Contact Count'
                                    validateTrigger={['onChange', 'onBlur']}
                                >
                                    <InputNumber min={0}/>
                                </Form.Item>

                                <Form.Item
                                    name="notes"
                                    label="Notes"
                                >
                                    <Input.TextArea rows={4}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            </>
        );
    }
}

export default StudentDrawerForm;