import authManager from "../Auth/authManager";
import {EnrollmentPeriod, EnrollmentPeriodCreationConfig} from "../Model/enrollmentModel";

export async function getEnrollmentPeriod(clientId: string, enrollmentPeriodId: string): Promise<EnrollmentPeriod> {
    console.log(`getEnrollmentPeriod`);
    const apiName = "enrollmentApi";
    const path = `/${clientId}/enrollmentPeriods/${enrollmentPeriodId}`;
    const myInit = {}

    return await authManager.apiGet<EnrollmentPeriod>(apiName, path, myInit);
}

export async function createEnrollmentPeriod(clientId: string, enrollmentPeriod: EnrollmentPeriod): Promise<EnrollmentPeriod> {
    console.log(`createEnrollmentPeriod`);
    const apiName = "enrollmentApi";
    const path = `/${clientId}/enrollmentPeriods`;
    const myInit = {
        body: enrollmentPeriod,
    }

    return await authManager.apiPost<EnrollmentPeriod>(apiName, path, myInit);
}

export async function updateEnrollmentPeriod(clientId: string, enrollmentPeriod: EnrollmentPeriod): Promise<EnrollmentPeriod> {
    console.log(`updateEnrollmentPeriod`);
    const apiName = "enrollmentApi";
    const path = `/${clientId}/enrollmentPeriods/${enrollmentPeriod.id}`;
    const myInit = {
        body: enrollmentPeriod,
    }

    return await authManager.apiPut<EnrollmentPeriod>(apiName, path, myInit);
}

export async function rolloverWaitlist(clientId: string, sourceEnrollmentPeriodId: string, destinationEnrollmentPeriodId: string): Promise<any> {
    console.log(`rolloverWaitlist`);
    const apiName = "enrollmentApi";
    const path = `/${clientId}/enrollmentPeriods/${destinationEnrollmentPeriodId}/rollover?sourceEnrollmentPeriodId=${sourceEnrollmentPeriodId}`;
    const myInit = {
        body: {},
    }

    return await authManager.apiPost<any>(apiName, path, myInit);
}

export async function updateEnrollmentPeriodCreationConfig(clientId: string, enrollmentPeriodCreationConfig: EnrollmentPeriodCreationConfig): Promise<EnrollmentPeriodCreationConfig> {
    console.log(`updateEnrollmentPeriodCreationConfig`);
    const apiName = "enrollmentApi";
    const path = `/${clientId}/enrollmentPeriodCreationConfig`;
    const myInit = {
        body: enrollmentPeriodCreationConfig,
    }

    return await authManager.apiPut<EnrollmentPeriodCreationConfig>(apiName, path, myInit);
}